import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { equals, is, isEmpty, isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import { nanoid } from 'nanoid'
import withRouter from '../../components/wrapperReactRouterDom'

import EventsActions from '../../redux/EventsRedux'

import reloadRoute from '../../utils/RouteHelper'
import { groupDataByTitle } from '../../utils/DataHelper'
import { fetchVisitData } from '../../utils/VisitHelper'
import { getBaselineDummyVisitText } from '../../utils/StudyHelper'

import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import VisitSelector from '../../components/inputs/VisitSelector'

import PatientTable from '../../components/tables/PatientTable'
import PatientEventTableRowItem from '../../components/tables/PatientEventTableRowItem'

class PatientTabEventData extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visitId: null,
      visitStatus: null,
      headersAreShown: 0,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // ROUTE HAS BEEN CLICKED AGAIN without switching to other routes
    const { visitId } = this.state
    const { router, fetchEvents } = this.props
    const { study_id, patient_id } = router.params // eslint-disable-line camelcase
    const thisKey = router.location.key
    const nextKey = nextProps.router.location.key

    if (!isNilOrEmpty(visitId)) {
      reloadRoute(thisKey, nextKey, () => fetchVisitData(study_id, patient_id, visitId, fetchEvents))
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { visitId } = this.state
    const previousVisitId = visitId
    const nextVisitId = nextState.visitId

    if (!equals(nextVisitId, previousVisitId)) {
      const { router, fetchEvents } = this.props
      const { study_id, patient_id } = router.params // eslint-disable-line camelcase

      fetchVisitData(study_id, patient_id, nextVisitId, fetchEvents)
    }
  }

  render() {
    const { router, events, visits, dummyVisit } = this.props
    const { fetchEventsError, busyFetchingEvents, fetchVisitsError } = this.props

    const { study_id } = router.params // eslint-disable-line camelcase
    const { visitStatus, headersAreShown } = this.state


    const hasError = !isNil(fetchEventsError) || !isNil(fetchVisitsError)

    const hasEvents = is(Array, events) && !isEmpty(events)
    const hasVisits = is(Array, visits) && !isEmpty(visits)

    return (
      <div className="c-patient">
        { !busyFetchingEvents && hasError && this._renderError([fetchEventsError, fetchVisitsError]) }
        { hasVisits && <VisitSelector dummyVisit={ getBaselineDummyVisitText(dummyVisit) } onChange={ this._visitChanged } /> }
        { !busyFetchingEvents && !hasError && hasEvents && this._renderData(events, visitStatus, study_id, headersAreShown) }
        { !busyFetchingEvents && !hasError && !hasEvents && this._renderNoDataAvailable() }
      </div>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoDataAvailable = () => (
    <HintLabel
      hintClass="u-margin--vertical"
      size={ 20 }>
      No event data available
    </HintLabel>
  )

  _renderData(events, visitStatus, studyId, headersAreShown) {
    // prepare headers to show
    const headersToShow = [{
      label: 'Event Type',
      colSpan: 2,
      id: nanoid(),
    }]

    // add extra headers if headersAreShown counter is not zero (which means the table is uncollapsed and data is shown)
    if (headersAreShown !== 0) {
      headersToShow.push(
        {
          label: 'Registered For',
          className: 'events-header__registeredfor',
          id: nanoid(),
        },
        {
          label: 'Completed On',
          className: 'events-header__registeredon',
          id: nanoid(),
        },
        {
          label: 'Details',
          id: nanoid(),
          colSpan: 2,
        },
      )
    } else {
      headersToShow[0].width = "100%"
    }

    return (
      <PatientTable headers={ headersToShow }>
        { this._renderTableContent(events, visitStatus, studyId, headersAreShown) }
      </PatientTable>
    )
  }

  _renderTableContent(events, visitStatus, studyId, headersAreShown) {
    const parsedEvents = groupDataByTitle(events)
    return parsedEvents && Object.keys(parsedEvents).map(eventTitle => (
      <PatientEventTableRowItem
        eventData={ parsedEvents[eventTitle] }
        title={ eventTitle }
        isUnlockedVisit={ visitStatus === 0 }
        toggleHeaders={ this._handleToggleHeaders(headersAreShown) }
        key={ eventTitle } />
    ))
  }

  _handleToggleHeaders = headersAreShown => (subItemsVisibilityStatus) => {
    // calculate new headers counter
    const newShowHeadersCounter = subItemsVisibilityStatus ? headersAreShown + 1 : headersAreShown - 1
    this.setState({ headersAreShown: newShowHeadersCounter })
  }

  _visitChanged = (option) => {
    this.setState({
      visitId: parseInt(option.id, 10),
      visitStatus: option.status,
    })
  }
}

PatientTabEventData.propTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // events
  events: PropTypes.array,
  fetchEvents: PropTypes.func.isRequired,
  dummyVisit: PropTypes.string,
  busyFetchingEvents: PropTypes.bool.isRequired,
  fetchEventsError: PropTypes.object,

  // visits
  visits: PropTypes.array,
  fetchVisitsError: PropTypes.object,
}

PatientTabEventData.defaultProps = {
  // events
  events: [],
  dummyVisit: null,
  fetchEventsError: null,
  // visits
  visits: [],
  fetchVisitsError: null,
}

const mapStateToProps = state => ({
  // events
  events: state.events.eventList,
  busyFetchingEvents: state.events.busyFetchingEvents,
  fetchEventsError: state.events.fetchEventsError,
  dummyVisit: state.events.dummyVisit,
  // visits
  visits: state.visits.visitList,
  fetchVisitsError: state.visits.fetchVisitsError,
})

const mapDispatchToProps = dispatch => ({ fetchEvents: (studyId, patientId, visitId) => dispatch(EventsActions.fetchEvents(studyId, patientId, visitId)) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientTabEventData))

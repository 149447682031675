import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

class MainTextArea extends Component {
  render() {
    const { label, textAreaStyle, onInputChanged, ...rest } = this.props

    return (
      <div>
        { this._renderLabel(label) }
        <textarea
          className={ cc([textAreaStyle, "main-input-textarea"]) }
          onChange={ this._inputChanged(onInputChanged) }
          { ...rest } />
      </div>
    )
  }

  _renderLabel = label => (label ? (
    <div className="main-input-title">
      { label }
    </div>
  ) : null)

  _inputChanged = cb => (e) => {
    cb(e.target.value)
  }
}

MainTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  textAreaStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  onInputChanged: PropTypes.func.isRequired,
}

MainTextArea.defaultProps = { textAreaStyle: [] }

export default MainTextArea

import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../inputs/Checkbox'


const VisitFormsTableItem = (props) => {
  const { name, duration, type, order, showOrder, canSelect, isSelected, onSelect } = props
  return (
    <tr>
      { canSelect && (
        <td>
          <Checkbox
            id={ `select-${name}` }
            isChecked={ isSelected }
            handleCheckboxChange={ onSelect } />
        </td>) }
      { showOrder && (
        <td>
          { order }
        </td>
      ) }
      <td>
        { name }
      </td>
      <td>
        { duration }
      </td>
      <td>
        { type }
      </td>
    </tr>
  )
}

VisitFormsTableItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  order: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showOrder: PropTypes.bool,
  canSelect: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
}

VisitFormsTableItem.defaultProps = {
  order: "N/A",
  showOrder: false,
  canSelect: false,
  isSelected: false,
  onSelect: () => { },
}

export default VisitFormsTableItem

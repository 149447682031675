import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { sortByProp } from '../utils/SortingHelper'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchDailyTasks: ['studyId', 'patientId'],
  fetchDailyTasksSuccess: ['dailyTasks'],
  fetchDailyTasksFailure: ['error'],
})

export const DailyTaskTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  dailyTasks: [],
  assessments: [],
  currentSiteDate: null,
  busyFetchingDailyTasks: false,
  fetchDailyTasksError: null,
})

/* ------------- Reducers ------------- */

export const fetchDailyTasks = state => state.merge({
  busyFetchingDailyTasks: true,
  fetchDailyTasksError: null,
})
export const fetchDailyTasksSuccess = (state, { dailyTasks }) => state.merge({
  busyFetchingDailyTasks: false,
  dailyTasks: sortByProp('type', sortByProp('order', dailyTasks.dailyTasks)),
  assessments: sortByProp('order', dailyTasks.assessments),
  currentSiteDate: dailyTasks.currentSiteDate,
})
export const fetchDailyTasksFailure = (state, { error }) => state.merge({
  busyFetchingDailyTasks: false,
  fetchDailyTasksError: error,
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_DAILY_TASKS]: fetchDailyTasks,
  [Types.FETCH_DAILY_TASKS_SUCCESS]: fetchDailyTasksSuccess,
  [Types.FETCH_DAILY_TASKS_FAILURE]: fetchDailyTasksFailure,
})

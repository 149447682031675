import React from 'react'
import PropTypes from 'prop-types'

import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'

function ConfirmationModal(props) {
  const { title, details, handleConfirmed, handleCanceled, loading, error } = props

  const _renderError = () => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  return (
    <div>
      { error && _renderError() }
      <div className="o-hint-flex">
        <div
          className="o-hint-text--title"
          style={ { fontSize: 20 } }
        >
          { title }
        </div>
        <div
          className="o-hint-text--details"
          style={ { fontSize: 16 } }
        >
          { details }
        </div>
      </div>
      <ConfirmationButtons
        onConfirm={ handleConfirmed }
        onCancel={ handleCanceled }
        cancelDisabled={ loading }
        confirmDisabled={ loading }
      />
    </div>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

ConfirmationModal.defaultProps = {
  loading: false,
  error: null,
}

export default ConfirmationModal

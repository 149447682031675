import React, { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import cc from 'classcat'

import Icon from '../Icon'

/* eslint-disable react/no-unused-class-component-methods */
class TabBarDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { isDropdownShown: false }
  }

  render() {
    const { title, icon, itemClass, children, id } = this.props
    const { isDropdownShown } = this.state

    return (
      <div
        className={ cc([itemClass, 'tab-bar-dropdown', 'right-tab-bar-item']) }
        onClick={ this._toggleDropdown(isDropdownShown) }
        id={ id }
        onKeyDown={ this._toggleDropdown(isDropdownShown) }
        role="button"
        tabIndex={ 0 }>
        <div className="tab-bar-dropdown-title">
          <Icon name={ icon } />
          <span>
            { title }
          </span>
        </div>
        { isDropdownShown ? this._renderDropdown(children) : null }
      </div>
    )
  }

  handleClickOutside = () => (this.state.isDropdownShown ? this.setState({ isDropdownShown: false }) : null) // eslint-disable-line react/destructuring-assignment

  _renderDropdown = content => (
    <div className="tab-bar-dropdown-menu">
      { content }
    </div>
  )

  _toggleDropdown = wasDropdownShown => () => this.setState({ isDropdownShown: !wasDropdownShown })
}

TabBarDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  itemClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

TabBarDropdown.defaultProps = {
  itemClass: [],
  icon: null,
}

export default onClickOutside(TabBarDropdown)

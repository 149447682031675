import { call, put } from 'redux-saga/effects'
import fileDownload from 'js-file-download'
import { isNilOrEmpty } from 'ramdasauce'
import PdiActions from '../redux/PdiRedux'
import queries from '../config/ApiConfig'
import addTitleToErrorObject from '../utils/ErrorHelper'
import { extractTogglePdisFromResponse, extractDataFromResponse } from '../utils/DataHelper'

export function* fetchAllPdis(api, action) {
  const { studyId, patientId } = action
  const { ok, data } = yield call(api.get, queries.PdiStatuses(studyId, patientId))
  const embeddedData = extractTogglePdisFromResponse(data)
  if (ok) {
    yield put(PdiActions.fetchAllPdisSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading events/questionnaires failed`)
    yield put(PdiActions.fetchAllPdisFailure(errorObject))
  }
}

export function* togglePdi(api, action) {
  const { studyId, patientId, reference, active, pdiType, userName, password, reason } = action
  const { ok, data } = yield call(api.put, queries.TogglePdi(studyId, patientId, reference),
    {
      active,
      userName,
      password,
      reason,
    })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(PdiActions.togglePdiSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `${active ? 'Activate' : 'Deactivate'} ${pdiType} failed`)
    yield put(PdiActions.togglePdiFailure(errorObject))
  }
}

export function* exportAllPdrs(api, action) {
  const { studyId, siteId } = action
  const { ok, data } = yield call(api.get, queries.ExportAllPdrs(studyId, siteId))
  if (ok) {
    if (isNilOrEmpty(data.failedFiles)) {
      yield put(PdiActions.exportAllPdrsSuccess())
    } else {
      const errorObject = addTitleToErrorObject({ message: `The following Patient PDRs were not downloaded: ${data.failedFiles.join(', ')}` }, 'Exporting all PDRs failed')
      yield put(PdiActions.exportAllPdrsFailure(errorObject))
    }
    const byteArray = Uint8Array.from(
      atob(data.exportBase64)
        .split('')
        .map(char => char.charCodeAt(0)),
    )

    fileDownload(new Blob([byteArray], { type: 'application/octet-stream' }), data.fileName)
  } else {
    const errorObject = addTitleToErrorObject(data, 'Exporting all PDRs failed')
    yield put(PdiActions.exportAllPdrsFailure(errorObject))
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'
import MainTextArea from '../inputs/MainTextArea'

class CancelVisitForm extends Component {
  constructor(props) {
    super(props)
    this.state = { inputReason: '' }
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { inputReason } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <MainTextArea
          label="Describe the reason for cancellation"
          maxLength={ 1024 }
          onInputChanged={ this._reasonChanged } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, inputReason) }
          confirmLabel="Cancel Visit"
          cancelDisabled={ loading }
          confirmDisabled={ !inputReason.trim() || loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _reasonChanged = (newReason) => {
    this.setState({ inputReason: newReason })
  }

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (cb, reason) => () => {
    try {
      cb(reason.trim())
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

CancelVisitForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
}

CancelVisitForm.defaultProps = { error: null }

export default CancelVisitForm

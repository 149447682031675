import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isNilOrEmpty } from 'ramdasauce'

import ErrorHandler from '../error/ErrorHandler'
import MainInput from '../inputs/MainInput'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class CancelFastTrackForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: window.sessionHandler.loggedInUser.userName,
      userPwd: '',
    }
  }

  render() {
    const { loading, error, handleCanceled, handleConfirmed, configMessage } = this.props
    const { userName, userPwd } = this.state
    return (
      <div>
        { error && this._renderError(error) }
        <div className="u-margin--top">
          { configMessage }
        </div>
        <MainInput
          value={ userName }
          inputClass="u-text--lowercased"
          name="userName"
          label="Username"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <MainInput
          value={ userPwd }
          inputClass="u-text--lowercased"
          name="userPwd"
          label="Password"
          onInputChanged={ this._handleInputChange }
          inputType="password"
          returnFullInputEvent={ true } />
        <ConfirmationButtons
          onConfirm={ this._onConfirm(handleConfirmed, userName, userPwd) }
          onCancel={ this._onCancel(handleCanceled) }
          confirmLabel="Confirm"
          cancelDisabled={ loading }
          confirmDisabled={ loading || isNilOrEmpty(userName) || isNilOrEmpty(userPwd) } />
      </div>
    )
  }

  _handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  _onConfirm = (callback, userName, userPwd) => () => {
    const credentials = { userName, userPwd }
    try {
      callback(credentials)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--vertical"
      error={ error }
    />
  )
}

CancelFastTrackForm.defaultProps = { error: null }

CancelFastTrackForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  handleConfirmed: PropTypes.func.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  configMessage: PropTypes.string.isRequired,
}

export default CancelFastTrackForm

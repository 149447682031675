import { is } from 'ramda'

function isToken(variable) {
  return variable.startsWith('#{') && variable.endsWith('}#')
}

export default function getConfigOrEnvVariable(variableFromConfig, variableFromEnv) {
  return is(String, variableFromConfig) && variableFromConfig.length > 0 && !isToken(variableFromConfig) && process.env.NODE_ENV === "production" ? variableFromConfig : is(String, variableFromEnv) && variableFromEnv.length > 0 ? variableFromEnv : ''
}

export function getTelevisitsApplicationUri(appConfig) {
  return getConfigOrEnvVariable(appConfig.televisitsWebUrl, process.env.REACT_APP_TELEVISITS_WEB_URL)
}

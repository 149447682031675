import React from 'react'
import PropTypes from 'prop-types'
import ErrorHandler from '../../components/error/ErrorHandler'

import PatientPDIStatusTable from '../../components/tables/PatientPDIStatusTable'

function PatientTabStatus(props) {
  const { pdiList, error } = props

  const _renderError = errorObject => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ errorObject } />
  )

  return (
    <div>
      { error && _renderError(error) }
      { !error && <PatientPDIStatusTable pdiList={ pdiList } /> }
    </div>
  )
}

PatientTabStatus.propTypes = {
  pdiList: PropTypes.array,
  error: PropTypes.object,
}

PatientTabStatus.defaultProps = {
  pdiList: [],
  error: null,
}

export default PatientTabStatus

import React from 'react'
import PropTypes from 'prop-types'

const TabBarItemGroup = (props) => {
  const { children } = props
  return (
    <div className="tabbar__group">
      { children }
    </div>
  )
}

TabBarItemGroup.propTypes = { children: PropTypes.node.isRequired }

export default TabBarItemGroup

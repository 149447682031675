import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { is, isEmpty } from 'ramda'
import cc from 'classcat'

import TableDateTimePicker from '../inputs/TableDateTimePicker'
import ToggleVirtualVisit from '../buttons/ToggleVirtualVisit'
import { isBeforeToday } from '../../utils/MomentHelper'

class PatientScreeningTable extends Component {
  render() {
    const {
      screeningStartDate, screeningEndDate, baselineStartDate, screeningPeriodAndBaselineAreSet, patientIsEligible, saveBaselineStartDate, saveScreeningEndDate,
      startScreeningVisitId, confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived,
      canSiteHaveVirtualVisits, showVirtualVisitLabel, tooltip, serverTime,
    } = this.props
    const containerClasses = cc([
      "o-table",
      { screening: screeningPeriodAndBaselineAreSet },
    ])
    const visitInPast = isBeforeToday(screeningStartDate, serverTime)
    return (
      <div className={ containerClasses } id="patient-screening-table">
        <table>
          <thead>
            <tr>
              <th>
                Screening
              </th>
              <th>
                Date
              </th>
              <th>
                Type
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Screening start date
              </td>
              <td>
                { this._renderDateLabel(screeningStartDate) }
              </td>
              { this._renderToggleVirtualVisitButtons(startScreeningVisitId, confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived, showVirtualVisitLabel, visitInPast, canSiteHaveVirtualVisits) }
            </tr>
            <tr>
              <td>
                Screening end date
              </td>
              <td>
                { !screeningEndDate && this._renderDateTimePicker(screeningStartDate, false, saveScreeningEndDate, false, isStudyArchived, tooltip, serverTime) }
                { screeningEndDate && this._renderDateLabel(screeningEndDate) }
              </td>
              <td />
            </tr>
            { patientIsEligible && screeningEndDate && !baselineStartDate && this._renderBaselineRow(screeningEndDate, saveBaselineStartDate, isStudyArchived, tooltip, serverTime) }
          </tbody>
        </table>
      </div>
    )
  }

  _renderToggleVirtualVisitButtons = (startScreeningVisitId, confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived, showVirtualVisitLabel, visitInPast, canSiteHaveVirtualVisits) => (
    <td>
      <ToggleVirtualVisit
        visitIsVirtual={ false }
        id={ startScreeningVisitId }
        confirmToggleVirtualVisit={ isVisitVirtual => confirmToggleVirtualVisit(startScreeningVisitId, isVisitVirtual) }
        resetToggleVirtualVisitError={ resetToggleVirtualVisitError }
        error={ toggleVirtualVisitError }
        loading={ busyTogglingVirtualVisit }
        showLabel={ showVirtualVisitLabel || visitInPast || !canSiteHaveVirtualVisits }
        isStudyArchived={ isStudyArchived } />
    </td>
  )

  _renderBaselineRow(screeningEndDate, saveBaselineStartDate, isStudyArchived, tooltip, serverTime) {
    return (
      <tr>
        <td>
          Baseline start date
        </td>
        <td>
          { this._renderDateTimePicker(screeningEndDate, true, saveBaselineStartDate, false, isStudyArchived, tooltip, serverTime) }
        </td>
        <td />
      </tr>
    )
  }

  _renderDateTimePicker = (beginDateLimit, allowBeginDateLimitAsWell, saveDate, timeFormat, isStudyArchived, tooltip, serverTime) => (
    <TableDateTimePicker
      beginDateLimit={ beginDateLimit }
      allowBeginDateLimitAsWell={ allowBeginDateLimitAsWell }
      allowDatesInPast={ true }
      timeFormat={ timeFormat }
      tooltip={ tooltip }
      disabled={ isStudyArchived }
      onChangeDate={ saveDate }
      serverTime={ serverTime }
      />
  )

  _renderDateLabel = dateTime => (is(String, dateTime) && !isEmpty(dateTime) ? moment(dateTime.replace(/Z/g, "")).format('DD-MMM-YYYY') : "N/A")
}

PatientScreeningTable.propTypes = {
  screeningStartDate: PropTypes.string.isRequired,
  startScreeningVisitId: PropTypes.string.isRequired,
  screeningEndDate: PropTypes.string,
  baselineStartDate: PropTypes.string,
  screeningPeriodAndBaselineAreSet: PropTypes.bool.isRequired,
  saveScreeningEndDate: PropTypes.func.isRequired,
  saveBaselineStartDate: PropTypes.func.isRequired,
  patientIsEligible: PropTypes.bool.isRequired,
  // toggle
  confirmToggleVirtualVisit: PropTypes.func.isRequired,
  busyTogglingVirtualVisit: PropTypes.bool,
  toggleVirtualVisitError: PropTypes.object,
  resetToggleVirtualVisitError: PropTypes.func.isRequired,
  isStudyArchived: PropTypes.bool,
  canSiteHaveVirtualVisits: PropTypes.bool,
  showVirtualVisitLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  serverTime: PropTypes.string,
}

PatientScreeningTable.defaultProps = {
  screeningEndDate: null,
  baselineStartDate: null,
  busyTogglingVirtualVisit: false,
  toggleVirtualVisitError: null,
  isStudyArchived: false,
  canSiteHaveVirtualVisits: false,
  showVirtualVisitLabel: false,
  tooltip: null,
  serverTime: null,
}

export default PatientScreeningTable

import moment from 'moment'
import { is, isEmpty, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

export const backendFormat = "YYYY-MM-DD[T]HH:mm:ss.SSS"
export const frontendDateFormat = "DD-MMM-YYYY"
export const frontendFormat = `${frontendDateFormat} HH:mm`
export const frontendTimeFormat = "HH:mm"

export function isBeforeToday(proposedDate, serverTime, accuracy = 'day') {
  const today = serverTime ? moment(serverTime) : moment()
  let result = false

  if (moment.isMoment(proposedDate)) {
    result = proposedDate.isBefore(today, accuracy)
  } else if (is(String, proposedDate) && !isEmpty(proposedDate) && moment.parseZone(proposedDate).isValid()) {
    const proposedDateAsMoment = moment.parseZone(proposedDate)
    result = isBeforeToday(proposedDateAsMoment, serverTime, accuracy)
  } else if (console) {
    console.warn('Proposed date is not a valid Moment object.') // eslint-disable-line no-console
  }
  return result
}
export function isSameOrBeforeToday(proposedDate, serverTime, accuracy = 'day') {
  const today = serverTime ? moment(serverTime) : moment()
  let result = false

  if (moment.isMoment(proposedDate)) {
    result = proposedDate.isSameOrBefore(today, accuracy)
  } else if (is(String, proposedDate) && !isEmpty(proposedDate) && moment.parseZone(proposedDate).isValid()) {
    const proposedDateAsMoment = moment.parseZone(proposedDate)
    result = isSameOrBeforeToday(proposedDateAsMoment, serverTime, accuracy)
  } else if (console) {
    console.warn('Proposed date is not a valid Moment object.') // eslint-disable-line no-console
  }
  return result
}

export function isToday(proposedDate, serverTime) {
  const today = serverTime ? moment(serverTime) : moment()
  let result = false

  if (moment.isMoment(proposedDate)) {
    result = equals(today.format('DD-MM-YYYY'), proposedDate.format('DD-MM-YYYY')) // compare current local date vs date (so called utc, but its actually local. ask backend why)
  } else if (is(String, proposedDate) && !isEmpty(proposedDate) && moment.parseZone(proposedDate).isValid()) {
    const proposedDateAsMoment = moment.parseZone(proposedDate)
    result = isToday(proposedDateAsMoment, serverTime)
  } else if (console) {
    console.warn('Proposed date is not a valid Moment object.') // eslint-disable-line no-console
  }
  return result
}

export function isSameOrAfterToday(proposedDate, serverTime, accuracy = 'day') {
  const today = serverTime ? moment(serverTime) : moment()
  let result = false

  if (moment.isMoment(proposedDate)) {
    result = proposedDate.isSameOrAfter(today, accuracy)
  } else if (is(String, proposedDate) && !isEmpty(proposedDate) && moment.parseZone(proposedDate).isValid()) {
    const proposedDateAsMoment = moment.parseZone(proposedDate)
    result = isSameOrAfterToday(proposedDateAsMoment, serverTime, accuracy)
  } else if (console) {
    console.warn('Proposed date is not a valid Moment object.') // eslint-disable-line no-console
  }
  return result
}
// "2020-12-11T10:30:36.485+00:00"
export function isAfterToday(proposedDate, serverTime, accuracy = 'day') {
  const today = serverTime ? moment(serverTime) : moment()
  let result = false

  if (moment.isMoment(proposedDate)) {
    result = proposedDate.isAfter(today, accuracy)
  } else if (is(String, proposedDate) && !isEmpty(proposedDate) && moment.parseZone(proposedDate).isValid()) {
    const proposedDateAsMoment = moment.parseZone(proposedDate)
    result = isAfterToday(proposedDateAsMoment, serverTime, accuracy)
  } else if (console) {
    console.warn('Proposed date is not a valid Moment object.') // eslint-disable-line no-console
  }
  return result
}

export function setUtcTimeForDate(newDate) {
  const localTime = moment.utc()
  const newDateAsMoment = moment.isMoment(newDate) ? newDate : moment(newDate)

  return localTime
    .year(newDateAsMoment.year())
    .month(newDateAsMoment.month())
    .date(newDateAsMoment.date())
}

export function getNowInBackendFormat(serverTime) {
  if (!isNilOrEmpty(serverTime)) {
    return moment(serverTime).format(backendFormat)
  }
  return moment().format(backendFormat)
}

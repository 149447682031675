import React from 'react'
import PropTypes from 'prop-types'

import MenuIcon from '../../assets/images/menu.svg'

const SidebarToggle = (props) => {
  const { handleClick } = props
  return (
    <button
      type="button"
      className="c-sidebar-toggle"
      onClick={ handleClick }>
      <img
        src={ MenuIcon }
        alt="menu" />
    </button>
  )
}

SidebarToggle.propTypes = { handleClick: PropTypes.func.isRequired }

export default SidebarToggle

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import cc from 'classcat'
import { isNilOrEmpty } from 'ramdasauce'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { connect } from 'react-redux'
import withRouter from '../../components/wrapperReactRouterDom'

import NotificationModal from '../../components/modals/NotificationModal'
import ApplicationConfig from '../../config/ApplicationConfig'
import getConfigOrEnvVariable, { getTelevisitsApplicationUri } from '../../utils/ConfigHelper'
import TelevisitsNotificationContent from '../../components/notifications/TelevisitNotificationContent'
import { isIE } from '../../utils/BrowserHelper'
import VisitActions from '../../redux/VisitRedux'
import TimeTravelActions from '../../redux/TimeTravelRedux'
import { isInvestigator, isSiteUser } from '../../utils/RoleHelper'

export class MainContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notificationModals: [],
      televisitsApplicationUri: '',
      connection: null,
      showTelevisitsCompatibilityError: false,
      showAnimations: true,
    }
  }

  componentDidMount() {
    const { fetchServerTime } = this.props

    Promise.resolve(ApplicationConfig)
      .then((appCfg) => {
        const televisitsApplicationUri = getTelevisitsApplicationUri(appCfg)
        const notificationHubUrl = getConfigOrEnvVariable(appCfg.notificationHubUrl, process.env.REACT_APP_NOTIFICATION_HUB_URL)
        const accessToken = window.sessionHandler.getApiTokenFromSession()
        const canTimeTravel = getConfigOrEnvVariable(appCfg.enableTimeTravel, process.env.REACT_APP_ENABLE_TIME_TRAVEL)
        
        if (canTimeTravel || canTimeTravel.toLowerCase() === "true") {
          fetchServerTime()
        }

        const connection = new HubConnectionBuilder().withUrl(`${notificationHubUrl}/televisit`, { accessTokenFactory: () => accessToken })
                                                     .withAutomaticReconnect()
                                                     .build()

        if (isInvestigator() || isSiteUser()) {
          connection.on('SubscribeToInsightNotifications.Confirmed', (response) => {
            console.log('SubscribeToInsightNotifications.Confirmed', response) // eslint-disable-line no-console
          })
          
          connection.on('InsightNotifications.PatientConnected', (response) => {
            console.log('InsightNotifications.PatientConnected', response) // eslint-disable-line no-console

            const { notificationModals } = this.state
            const { virtualVisitId } = response

            if (!notificationModals.some(item => item.virtualVisitId === virtualVisitId)) {
              this.setState({ notificationModals: [...notificationModals, { virtualVisitId, notificationPopupResponse: response }] })
            }
          })

          connection.start()
            .then(async () => {
              connection.invoke('SubscribeToInsightNotifications')
            })
            .catch(error => console.log(error)) // eslint-disable-line no-console
        }

        this.setState({
          connection,
          televisitsApplicationUri,
        })
      })
      .catch(error => console.log(error)) // eslint-disable-line no-console
  }


  componentDidUpdate(prevProps, prevState) {
    const { connection, notificationModals, showAnimations } = this.state
    const { resetShouldSubscribeToNotifications, shouldSubscribeToNotifications } = this.props
   
    if (shouldSubscribeToNotifications) {
      connection.invoke('SubscribeToInsightNotifications')
                .then(() => resetShouldSubscribeToNotifications())
                .catch(error => console.log(error)) // eslint-disable-line no-console
    }
   
    // logic to trigger animations, if a page change is done while there are notifications open, don't allow animations
    if (prevState.notificationModals === notificationModals && !isNilOrEmpty(notificationModals) && showAnimations === true) {
      this.setState({ showAnimations: false })
    }
  }

  componentWillUnmount() {
    const { connection } = this.state

    if (connection) {
      connection.stop()
    }
  }

  render() {
    const { notificationModals, televisitsApplicationUri, showTelevisitsCompatibilityError, showAnimations } = this.state
    const { children } = this.props
    
    return (
      <div className="main-container">
        { children }
        { this._renderNotificationModals(notificationModals, televisitsApplicationUri, showTelevisitsCompatibilityError, showAnimations) }
      </div>
    )
  }

  _closeNotification = (modalToBeDeleted) => {
    this.setState((crtState) => {
      const { notificationModals } = crtState
      const filteredNotificationModals = notificationModals.map(notification => (notification.virtualVisitId !== modalToBeDeleted ? notification : { ...notification, showModal: false })).filter(item => item.virtualVisitId !== modalToBeDeleted)
        
      return { notificationModals: filteredNotificationModals, showTelevisitsCompatibilityError: false, showAnimations: true }
    })
  }

  _renderNotificationModals = (notificationModals, televisitsApplicationUri, showTelevisitsCompatibilityError, showAnimations) => notificationModals.map(item => this._renderNotificationPopup(item.notificationPopupResponse, televisitsApplicationUri, showTelevisitsCompatibilityError, showAnimations, item.showModal))

  _renderNotificationPopup = (notificationPopupResponse, televisitsApplicationUri, showTelevisitsCompatibilityError, showAnimations, showModal) => (
    <NotificationModal
      key={ nanoid() }
      handleClose={ modalToBeDeleted => this._closeNotification(modalToBeDeleted) }
      virtualVisitId={ notificationPopupResponse.virtualVisitId }
      className={ cc([{ "notification-remove-animation": !showAnimations || showTelevisitsCompatibilityError }]) }
      header={ `Patient ${notificationPopupResponse.patientId}` }
      title="has just joined the call"
      icon="calendar-alt"
      forceInteraction={ false }
      showModal={ showModal }
    >
      <TelevisitsNotificationContent
        visitName={ notificationPopupResponse.visitName }
        visitPlannedOnDate={ notificationPopupResponse.visitPlannedOnDate }
        studyName={ notificationPopupResponse.studyName }
        televisitsApplicationUri={ televisitsApplicationUri }
        virtualVisitId={ notificationPopupResponse.virtualVisitId }
        handleClick={ () => this._openTelevisitsApplication(notificationPopupResponse.studyName, notificationPopupResponse.virtualVisitId, televisitsApplicationUri) }
        showError={ showTelevisitsCompatibilityError }
      />
    </NotificationModal>
  )

  _openTelevisitsApplication = (studyName, virtualVisitId, televisitsApplicationUri) => {
    if (isIE()) {
      this.setState({ showTelevisitsCompatibilityError: true })
    } else {
      const url = `${televisitsApplicationUri}/televisit?studyId=${studyName}&visitId=${virtualVisitId}`
      this._closeNotification(virtualVisitId)
    
      return window.open(url, "_blank") || window.location.replace(url)
    }

    return null
  }
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  shouldSubscribeToNotifications: PropTypes.bool,
  resetShouldSubscribeToNotifications: PropTypes.func.isRequired,
  fetchServerTime: PropTypes.func.isRequired,
}

MainContainer.defaultProps = { shouldSubscribeToNotifications: false }

export const mapStateToProps = state => ({ shouldSubscribeToNotifications: state.visits.shouldSubscribeToNotifications })

export const mapDispatchToProps = dispatch => ({
  resetShouldSubscribeToNotifications: () => dispatch(VisitActions.resetShouldSubscribeToNotifications()),
  fetchServerTime: () => dispatch(TimeTravelActions.fetchServerTime()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer))

/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

import DailyTaskActions from '../redux/DailyTasksRedux'
import { extractDailyTasksFromResponse } from '../utils/DataHelper'

export function* fetchDailyTasks(api, action) {
  const { studyId, patientId } = action
  const { ok, data } = yield call(api.get, queries.DailyTasks(studyId, patientId))
  const embeddedData = extractDailyTasksFromResponse(data)
  if (ok) {
    yield put(DailyTaskActions.fetchDailyTasksSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, 'Loading daily tasks failed')
    yield put(DailyTaskActions.fetchDailyTasksFailure(errorObject))
  }
}

import { LogLevel } from "@azure/msal-browser"
import ApplicationConfig from "./ApplicationConfig"
import getConfigOrEnvVariable from "../utils/ConfigHelper"
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf("MSIE ")
const msie11 = ua.indexOf("Trident/")
const msedge = ua.indexOf("Edg/")
const firefox = ua.indexOf("Firefox")
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito
export const additional = { timeoutInMinutes: 30 }

// Config object to be passed to Msal on creation
export default (async () => {
  const appCfg = await ApplicationConfig
  return {
    auth: {
      clientId: getConfigOrEnvVariable(appCfg.clientId, process.env.REACT_APP_CLIENT_ID),
      authority: getConfigOrEnvVariable(appCfg.authority, process.env.REACT_APP_AUTHORITY),
      knownAuthorities: [getConfigOrEnvVariable(appCfg.authority, process.env.REACT_APP_AUTHORITY)],
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      protocolMode: getConfigOrEnvVariable(appCfg.protocolMode, process.env.REACT_APP_PROTOCOL_MODE),
    },
    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    loginRequest: { scopes: [getConfigOrEnvVariable(appCfg.apiApplicationIdUri, process.env.REACT_APP_API_APPLICATION_ID_URI)] },
    // apiRequest = { scopes: ["https://my-dev.ucb.com/4b96e012-7ab4-40a9-9d7b-121aa1da9090/user_impersonation"] }
    apiRequest: { scopes: [getConfigOrEnvVariable(appCfg.apiApplicationIdUri, process.env.REACT_APP_API_APPLICATION_ID_URI)] },
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message) // eslint-disable-line no-console
              return
            case LogLevel.Info:
              console.info(message) // eslint-disable-line no-console
              return
            case LogLevel.Verbose:
              console.debug(message) // eslint-disable-line no-console
              return
            case LogLevel.Warning:
              console.warn(message) // eslint-disable-line no-console
              break
            default:
          }
        },
      },
    },
  }
})()

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = { graphMeEndpoint: "https://graph.microsoft.com/v1.0/me" }

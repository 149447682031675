import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isNilOrEmpty } from 'ramdasauce'
import { isNil } from 'ramda'

import { ICON_LIST, ICON_CIRCLE_CROSS, ICON_INFO } from '../../config/constants'

import { isVisitCanceled, isVisitEditable, isVisitCancelable, isVisitSkipped, isVisitExportable, isVirtualVisit } from '../../utils/VisitHelper'
import { frontendFormat, isBeforeToday } from '../../utils/MomentHelper'

import Icon from '../Icon'
import MainButton from '../buttons/MainButton'
import TableDateTimePicker from '../inputs/TableDateTimePicker'
import ToggleVirtualVisit from '../buttons/ToggleVirtualVisit'

class PatientVisitTableItem extends Component {
  render() {
    const {
      visit, visitIsUnscheduled, previousVisit, nextVisit, firstVisit, lastVisit, isAPureEngageStudy, visitDates, upcomingVisit,
      exportVisit, cancelVisit, changeVisitDate, busyChangingDatePickerDate,
      confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError,
      isStudyArchived, canSiteHaveVirtualVisits, showVirtualVisitLabel, tooltip, serverTime,
    } = this.props
    const visitDateTime = visit && visit.plannedOn

    const visitIsCanceled = isVisitCanceled(visit)
    const visitIsSkipped = isVisitSkipped(visit)
    const visitIsVirtual = isVirtualVisit(visit)
    const visitHasDataAttached = !!visit.hasData
    const visitInPast = isBeforeToday(visitDateTime, serverTime)
    const showLabelVirtualVisit = showVirtualVisitLabel || (visitInPast && !visitIsSkipped && !visitIsCanceled)
    const visitIsEditable = isVisitEditable(visitIsCanceled, visitIsSkipped, visitIsUnscheduled, isAPureEngageStudy, visitHasDataAttached, visitDateTime, serverTime)
    const visitIsCancelable = isVisitCancelable(visitIsCanceled, visitIsSkipped, visitIsUnscheduled, isAPureEngageStudy, visitHasDataAttached, visitDateTime, serverTime)
    const visitIsExportable = isVisitExportable(visit.id, firstVisit.id, visitIsSkipped, visitIsUnscheduled, visitDateTime, upcomingVisit, serverTime)
    const visitCanBeToggled = !visitIsCanceled && !visitIsSkipped

    return (
      <tr>
        { /* VISIT LABEL */ }
        <td className="o-table__item visit-label">
          { visitIsUnscheduled ? `Unscheduled Visit` : `${visit.visit.title}` }
        </td>
        { /* PICKER OR DATE LABEL */ }
        <td className="o-table__item datepicker-label">
          { visitIsEditable && !isNil(previousVisit) && this._renderVisitDateTimePicker(visitDateTime, previousVisit && previousVisit.plannedOn, nextVisit && nextVisit.plannedOn, changeVisitDate, visitIsUnscheduled, firstVisit && firstVisit.plannedOn, lastVisit && lastVisit.plannedOn, visitDates, busyChangingDatePickerDate, isStudyArchived, tooltip, serverTime) }
          { !visitIsEditable && !visitIsCanceled && !visitIsSkipped && !isNil(previousVisit) && this._renderVisitDateTimePicker(visitDateTime, previousVisit && previousVisit.plannedOn, nextVisit && nextVisit.plannedOn, changeVisitDate, visitIsUnscheduled, firstVisit && firstVisit.plannedOn, lastVisit && lastVisit.plannedOn, visitDates, busyChangingDatePickerDate, isStudyArchived, tooltip, serverTime)}
          { ((!visitIsEditable && (visitIsCanceled || visitIsSkipped)) || isNil(previousVisit)) && this._renderVisitDateLabel(visitDateTime) }
        </td>
        {
          this._renderVirtualVisitColumn(visitCanBeToggled, visitIsVirtual, visit.id, confirmToggleVirtualVisit, resetToggleVirtualVisitError, toggleVirtualVisitError, busyTogglingVirtualVisit, showLabelVirtualVisit, isStudyArchived, canSiteHaveVirtualVisits)
        }
        { /* CANCELED LABEL OR SKIPPED LABEL OR CANCEL BUTTON */ }
        <td className="o-table__item">
          { visitIsCanceled && this._renderVisitCanceledLabel() }
          { visitIsSkipped && this._renderVisitSkippedLabel() }
          { visitIsCancelable && this._renderCancelVisitButton(cancelVisit) }
        </td>
        { /* EXPORT BUTTON */ }
        <td className="o-table__item">
          { visitIsExportable && this._renderExportVisitButton(exportVisit) }
        </td>
      </tr>
    )
  }

  _renderVirtualVisitColumn = (visitCanBeToggled, visitIsVirtual, visitId, confirmToggleVirtualVisit, resetToggleVirtualVisitError, toggleVirtualVisitError, busyTogglingVirtualVisit, showLabelVirtualVisit, isStudyArchived, canSiteHaveVirtualVisits) => (
    <td className="o-table__item virtual-visit-label">
      { visitCanBeToggled && this._renderToggleVirtualVisit(visitIsVirtual, visitId, confirmToggleVirtualVisit, resetToggleVirtualVisitError, toggleVirtualVisitError, busyTogglingVirtualVisit, showLabelVirtualVisit, isStudyArchived, canSiteHaveVirtualVisits) }
    </td>
  )

  _renderToggleVirtualVisit = (visitIsVirtual, visitId, confirmToggleVirtualVisit, resetToggleVirtualVisitError, toggleVirtualVisitError, busyTogglingVirtualVisit, showLabelVirtualVisit, isStudyArchived, canSiteHaveVirtualVisits) => (
    <ToggleVirtualVisit
      visitIsVirtual={ visitIsVirtual }
      id={ visitId }
      confirmToggleVirtualVisit={ isVisitVirtual => confirmToggleVirtualVisit(isVisitVirtual) }
      resetToggleVirtualVisitError={ resetToggleVirtualVisitError }
      error={ toggleVirtualVisitError }
      loading={ busyTogglingVirtualVisit }
      showLabel={ showLabelVirtualVisit || !canSiteHaveVirtualVisits }
      isStudyArchived={ isStudyArchived }
  />
  )

  _renderVisitDateTimePicker = (visitDateTime, previousVisitDate, nextVisitDate, changeVisitDate, visitIsUnscheduled, firstVisit, lastVisit, visitDates, busyChangingDatePickerDate, isStudyArchived, tooltip, serverTime) => (
    <TableDateTimePicker
      initialDateTime={ visitDateTime }
      beginDateLimit={ visitIsUnscheduled ? firstVisit : previousVisitDate }
      endDateLimit={ visitIsUnscheduled ? lastVisit : nextVisitDate }
      allowDatesInPast={ true }
      disabled={ isStudyArchived }
      visitIsUnscheduled={ visitIsUnscheduled }
      visitDates={ visitDates }
      tooltip={ tooltip }
      busyChangingDatePickerDate={ busyChangingDatePickerDate }
      onChangeDate={ changeVisitDate }
      serverTime={ serverTime }
      />
  )

  _renderVisitDateLabel = visitDateTime => (
    !isNilOrEmpty(visitDateTime)
      ? moment.parseZone(visitDateTime).format(frontendFormat)
      : "N/A"
  )

  _renderVisitCanceledLabel = () => (
    <div className="o-table__label">
      <Icon name={ ICON_INFO } />
      <span>
        Visit canceled
      </span>
    </div>
  )

  _renderVisitSkippedLabel = () => (
    <div className="o-table__label">
      <Icon name={ ICON_INFO } />
      <span>
        Skipped
      </span>
    </div>
  )

  _renderExportVisitButton = exportVisit => (
    <MainButton
      buttonClass="o-table__button inverted-gray"
      icon={ { name: ICON_LIST } }
      label="Export data for this visit"
      handleClick={ exportVisit }
      id="button-export-visit-data" />
  )

  _renderCancelVisitButton = cancelVisit => (
    <MainButton
      buttonClass="o-table__button inverted-blue"
      icon={ { name: ICON_CIRCLE_CROSS } }
      label="Cancel this visit"
      handleClick={ cancelVisit }
      id="button-cancel-visit" />
  )
}

PatientVisitTableItem.propTypes = {
  visit: PropTypes.object.isRequired,
  visitIsUnscheduled: PropTypes.bool,
  visitDates: PropTypes.array,
  previousVisit: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  nextVisit: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  changeVisitDate: PropTypes.func.isRequired,
  exportVisit: PropTypes.func.isRequired,
  cancelVisit: PropTypes.func.isRequired,
  isAPureEngageStudy: PropTypes.bool.isRequired,
  firstVisit: PropTypes.object,
  lastVisit: PropTypes.object,
  upcomingVisit: PropTypes.object,
  busyChangingDatePickerDate: PropTypes.bool,
  confirmToggleVirtualVisit: PropTypes.func.isRequired,
  resetToggleVirtualVisitError: PropTypes.func.isRequired,
  toggleVirtualVisitError: PropTypes.object,
  busyTogglingVirtualVisit: PropTypes.bool,
  isStudyArchived: PropTypes.bool.isRequired,
  canSiteHaveVirtualVisits: PropTypes.bool.isRequired,
  showVirtualVisitLabel: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  serverTime: PropTypes.string,
}

PatientVisitTableItem.defaultProps = {
  previousVisit: null,
  nextVisit: null,
  firstVisit: null,
  lastVisit: null,
  upcomingVisit: null,
  busyChangingDatePickerDate: false,
  visitIsUnscheduled: false,
  visitDates: [],
  toggleVirtualVisitError: null,
  busyTogglingVirtualVisit: false,
  tooltip: null,
  serverTime: null,
}

export default PatientVisitTableItem

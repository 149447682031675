import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'

class PatientQuestionnaireTableRowSubItem extends Component {
  render() {
    const { data } = this.props

    return (
      <tr>
        <td>
          { !!data.question && Parser(this._rawHtml(data)) }
        </td>
        <td style={ { textAlign: 'right', whiteSpace: 'nowrap' } }>
          { data.answer }
        </td>
      </tr>
    )
  }

  _rawHtml = (data) => {
    // replace "\S" with bold spans
    const question = data.question.replace(/__(\S(.*?\S)?)__/gm, `<span class='u-text--bold'>$1</span>`)
    return question
  }
}

PatientQuestionnaireTableRowSubItem.propTypes = { data: PropTypes.object.isRequired }

export default PatientQuestionnaireTableRowSubItem

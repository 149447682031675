import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

import CollapsePickerSelector from './CollapsePickerSelector'

const CollapsePicker = (props) => {
  const { title, selectorTitle, onSelectTitle, headerClass, containerClass, selectorClass, isOpen, onSelectorClick, children } = props

  const containerClasses = cc([
    containerClass,
    "collapsepicker-container",
    { "collapsepicker-container-open": isOpen },
  ])

  const headerClasses = cc([headerClass, "collapsepicker-header"])
  const selectorClasses = cc([selectorClass, "collapsepicker-selector"])

  return (
    <div className={ containerClasses }>
      <span className={ headerClasses }>
        { title }
      </span>
      <CollapsePickerSelector
        className={ selectorClasses }
        onClick={ onSelectorClick }
        title={ (onSelectTitle && isOpen && onSelectTitle) || selectorTitle }
        shouldRotate={ isOpen }
        tabIndex={ 0 }
        role="button" />
      <div className="collapsepicker-items">
        { children }
      </div>
    </div>
  )
}

CollapsePicker.propTypes = {
  title: PropTypes.string.isRequired,
  selectorTitle: PropTypes.string.isRequired,
  onSelectTitle: PropTypes.string,
  headerClass: PropTypes.string,
  containerClass: PropTypes.string,
  selectorClass: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSelectorClick: PropTypes.func.isRequired,
}

CollapsePicker.defaultProps = {
  isOpen: false,
  onSelectTitle: '',
  headerClass: '',
  containerClass: '',
  selectorClass: '',
}

export default CollapsePicker

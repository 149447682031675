import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchQuestionnaires: ['studyId', 'patientId', 'visitId'],
  fetchQuestionnairesSuccess: ['questionnaires', 'dummyVisit'],
  fetchQuestionnairesFailure: ['error'],
})

export const QuestionnairesTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // questionnaires
  questionnaireList: [],
  busyFetchingQuestionnaires: false,
  fetchQuestionnairesError: null,
})

/* ------------- Reducers ------------- */

// fetchQuestionnaires
export const fetchQuestionnaires = state => state.merge({
  busyFetchingQuestionnaires: true,
  questionnaireList: [],
  fetchQuestionnairesError: null,
})
export const fetchQuestionnairesSuccess = (state, { questionnaires, dummyVisit }) => state.merge({
  busyFetchingQuestionnaires: false,
  dummyVisit,
  questionnaireList: questionnaires,
})

export const fetchQuestionnairesFailure = (state, { error }) => state.merge({
  busyFetchingQuestionnaires: false,
  fetchQuestionnairesError: error,
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_QUESTIONNAIRES]: fetchQuestionnaires,
  [Types.FETCH_QUESTIONNAIRES_SUCCESS]: fetchQuestionnairesSuccess,
  [Types.FETCH_QUESTIONNAIRES_FAILURE]: fetchQuestionnairesFailure,
})

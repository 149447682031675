import React from 'react'
import PropTypes from 'prop-types'

import { ICON_ARROW_DOWN } from '../../config/constants'

import Icon from "../Icon"

const CollapsePickerSelector = (props) => {
  const { title, shouldRotate, ...rest } = props
  const rotateDegree = shouldRotate ? 0 : 270
  return (
    <div { ...rest }>
      <span className="collapsepicker-selector-title">
        { title }
      </span>
      <Icon
        name={ ICON_ARROW_DOWN }
        rotate={ rotateDegree }
        color="white" />
    </div>
  )
}

CollapsePickerSelector.propTypes = {
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  shouldRotate: PropTypes.bool,
}

CollapsePickerSelector.defaultProps = { shouldRotate: false }

export default CollapsePickerSelector

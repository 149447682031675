import { isNilOrEmpty } from 'ramdasauce'

export function isPureFourMeStudy(study) {
  return !isNilOrEmpty(study) && study.studyType === 1
}

export function isPureEngageStudy(study) {
  return !isNilOrEmpty(study) && study.studyType === 2
}

export function isStudyArchived(workflow) {
  return workflow === 3
}

export function canSiteHaveVirtualVisits(site) {
  return !isNilOrEmpty(site) && !!site.virtualVisitConfigurationEnabled
}

export function getFastTrackEnableButtonText(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.button_enable_fasttrack_title) ? resources.button_enable_fasttrack_title : 'Enable Rescue Therapy'
}

export function getFastTrackCancelButtonText(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.button_cancel_fasttrack_title) ? resources.button_cancel_fasttrack_title : 'Cancel Rescue Therapy'
}

export function getFastTrackConfirmationText(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.confirm_fasttrack_description) ? resources.confirm_fasttrack_description : 'By enabling the Rescue Therapy option, you are agreeing that the patient will be forwarded to the Observation Period Visit per the study protocol'
}

export function getFastTrackCancellationText(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.cancel_fasttrack_description) ? resources.cancel_fasttrack_description : 'By canceling the Rescue Therapy option, you are agreeing that the patient will reset back to the original schedule per the study protocol'
}

export function getCancelFasttrackFailedTitle(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.cancel_fasttrack_failed_title) ? resources.cancel_fasttrack_failed_title : 'Canceling Rescue Therapy failed'
}

export function getBaselineDummyVisitText(dummyVisitLabel) {
  return !isNilOrEmpty(dummyVisitLabel) ? dummyVisitLabel : 'Baseline'
}

export function getDailyTasksEmptyText(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.daily_tasks_empty) ? resources.daily_tasks_empty : 'There are no Daily Tasks or Study Assessments today for this patient'
}

export function getAddPatientButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.add_patient_button_toolkit) ? resources.add_patient_button_toolkit : 'Add Patient is forbidden on DB Lock studies'
}

export function getAddUnscheduledVisitButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.add_unscheduled_visit_button_toolkit) ? resources.add_unscheduled_visit_button_toolkit : 'Add unscheduled visit is forbidden on DB Lock studies'
}

export function getChangeLanguageButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.change_language_button_toolkit) ? resources.change_language_button_toolkit : 'Change language is forbidden on DB Lock studies'
}

export function getChangeDeviceButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.change_device_button_toolkit) ? resources.change_device_button_toolkit : 'Change device is forbidden on DB Lock studies'
}

export function getChangeVisitDateButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.change_visit_date_button_toolkit) ? resources.change_visit_date_button_toolkit : 'Change visit date is forbidden on DB Lock studies'
}

export function getRescueTherapyButtonToolkit(resources) {
  return !isNilOrEmpty(resources) && !isNilOrEmpty(resources.rescue_therapy_button_toolkit) ? resources.rescue_therapy_button_toolkit : 'Enabling rescue therapy is forbidden on DB Lock studies'
}

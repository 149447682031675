import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import cc from 'classcat'

import Icon from '../Icon'
import { ICON_LOCATION_LEFT, ICON_LOCATION_RIGHT } from '../../config/constants'

class MainButton extends Component {
  render() {
    const { label, icon, buttonClass, handleClick, isDisabled, id, iconLocation, tooltip } = this.props
    return (
      <button
        type="button"
        className={ cc([buttonClass, "main-button"]) }
        onClick={ this._loseFocusAndCallback(handleClick) }
        disabled={ isDisabled }
        title={ isDisabled ? tooltip : null }
        id={ id }>
        { (iconLocation === ICON_LOCATION_LEFT && !isNil(Icon) && icon) ? this._renderIcon(icon) : null }
        <span>
          { label }
        </span>
        { (iconLocation === ICON_LOCATION_RIGHT && !isNil(Icon) && icon) ? this._renderIcon(icon) : null }
      </button>
    )
  }

  _loseFocusAndCallback = callback => (e) => {
    e.currentTarget.blur()
    callback()
  }

  _renderIcon = icon => (
    <Icon { ...icon } />
  )
}

MainButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
  }),
  buttonClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  iconLocation: PropTypes.string,
}

MainButton.defaultProps = {
  label: '',
  icon: null,
  isDisabled: false,
  buttonClass: [],
  tooltip: null,
  iconLocation: ICON_LOCATION_LEFT,
}

export default MainButton

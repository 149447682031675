import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNil, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import withRouter from '../../components/wrapperReactRouterDom'

import { ICON_DOWNLOAD, ICON_ADD_LIST } from '../../config/constants'

import reloadRoute from '../../utils/RouteHelper'
import { hasVisitToday, hasSkippedVisits, hasReachedFastTrackVisit, firstVisitCompleted, isCancelFastTrackEnabled, isVisitSkipped, isVisitUnscheduled, isScreeningVisit, isVisitCanceled } from '../../utils/VisitHelper'

import VisitActions from '../../redux/VisitRedux'
import PatientActions from '../../redux/PatientRedux'

import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'
import MainButton from '../../components/buttons/MainButton'
import MainButtonGroup from '../../components/buttons/MainButtonGroup'

import PatientVisitsTable from '../../components/tables/PatientVisitsTable'
import PatientScreeningTable from '../../components/tables/PatientScreeningTable'

import BaseModal from '../../components/modals/BaseModal'
import CancelVisitForm from '../../components/modals/CancelVisitForm'
import LoadingModal from '../../components/modals/LoadingModal'
import AddUnscheduledVisitForEngageForm from '../../components/modals/AddUnscheduledVisitForEngageForm'
import FastTrackVisitForm from '../../components/modals/FastTrackVisitForm'
import CancelFastTrackForm from '../../components/modals/CancelFastTrackForm'
import RescheduleVisitsModal from '../../components/modals/RescheduleVisitsModal'

import {
  isPureEngageStudy, getFastTrackEnableButtonText, getFastTrackCancelButtonText, getFastTrackConfirmationText, getCancelFasttrackFailedTitle,
  getFastTrackCancellationText, isStudyArchived, canSiteHaveVirtualVisits, getAddUnscheduledVisitButtonToolkit, getChangeVisitDateButtonToolkit,
  getRescueTherapyButtonToolkit,
} from '../../utils/StudyHelper'
import { isAfterToday, isSameOrAfterToday } from '../../utils/MomentHelper'

export class PatientTabVisits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCancelUnscheduledVisitModal: false,
      showExportAllVisitsModal: false,
      showExportVisitModal: false,
      showChangingVisitDateModal: false,
      showAddUnscheduledVisitModal: false,
      showAddUnscheduledVisitForEngageModal: false,
      showFastTrackVisitModal: false,
      showCancelFastTrackModal: false,
      showRescheduleVisitsModal: false,
      idOfVisitToCancelFromState: null,
      idOfVisitToExport: null,
      labelOfVisitToExport: null,
      idOfVisitToChangeDate: null,
      newDateOfVisitToChangeDate: null,
      screeningEndDateToSave: null,
      baselineStartDateToSave: null,
      upcomingVisit: { id: '' },
    }
  }

  componentDidMount() {
    // make object out of string because we need the full upcomingVisit later but this can't be done here because initially upcomingVisit is a string
    this.setState((crtState, props) => {
      const { patient } = props
      const { upcomingVisit } = patient

      return { upcomingVisit: { id: upcomingVisit } }
    })
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
    // ROUTE HAS BEEN CLICKED AGAIN without switching to other routes
    const { router, fetchVisits, serverTime } = this.props
    const { study_id, patient_id } = nextProps.router.params // eslint-disable-line camelcase
    const thisKey = router.location.key
    const nextKey = nextProps.router.location.key

    reloadRoute(thisKey, nextKey, () => fetchVisits(study_id, patient_id, true)) // true = include details
    
    const { idOfVisitToCancel, resetCancelVisitError } = this.props
    const { busyChangingVisitDate, busyExportingAllVisits, busyExportingVisit, busyAddingUnscheduledVisit, busySavingScreeningEndDate, busySavingBaselineStartDate } = this.props
    const { busyFastTrackingVisit, busyCancelingFastTrack } = this.props
    const { resetFastTrackVisitError, resetCancelFastTrackError, resetRescheduleVisitsError, busyReschedulingVisits } = this.props

    // set the upcoming visit to avoid rerendering
    const { upcomingVisit } = this.state
    const updatedUpcomingVisit = nextProps.visits.find(visit => isSameOrAfterToday(visit.plannedOn, serverTime) && !isVisitSkipped(visit) && !isVisitUnscheduled(visit))
    
    if (!equals(upcomingVisit, updatedUpcomingVisit) && !!updatedUpcomingVisit) {
      this.setState({ upcomingVisit: updatedUpcomingVisit })
    }

    // CANCEL VISIT
    const visitHasBeenCancelled = idOfVisitToCancel && !nextProps.idOfVisitToCancel && !nextProps.cancelVisitError
    if (visitHasBeenCancelled) {
      this._closeCancelUnscheduledVisitModal(resetCancelVisitError)()
    }
    // EXPORT ALL VISIT DATA
    const allVisitDataHasBeenExported = busyExportingAllVisits && !nextProps.busyExportingAllVisits && !nextProps.exportAllVisitsError
    if (allVisitDataHasBeenExported) {
      this._closeExportAllVisitsModal()
    }
    // EXPORT VISIT DATA
    const visitDataHasBeenExported = busyExportingVisit && !nextProps.busyExportingVisit && !nextProps.exportVisitError
    if (visitDataHasBeenExported) {
      this._closeExportVisitModal()
    }
    // CHANGE VISIT DATE
    const visitDateHasBeenChanged = busyChangingVisitDate && !nextProps.busyChangingVisitDate && !nextProps.changeVisitDateError
    if (visitDateHasBeenChanged) {
      this._closeChangeVisitDateModal()
    }
    const visitsHaveBeenRescheduled = busyReschedulingVisits && !nextProps.busyReschedulingVisits && !nextProps.rescheduleVisitsError
    if (visitsHaveBeenRescheduled) {
      this._closeRescheduleVisitsModal(resetRescheduleVisitsError)()
    }
    // ADD UNSCHEDULED VISIT
    const visitHasBeenAdded = busyAddingUnscheduledVisit && !nextProps.busyAddingUnscheduledVisit && !nextProps.addUnscheduledVisitError
    if (visitHasBeenAdded) {
      this._closeAddUnscheduledVisitModal()
    }
    // SAVE SCREENING END DATE
    const ScreeningEndDateHasBeenChanged = busySavingScreeningEndDate && !nextProps.busySavingScreeningEndDate && !nextProps.saveScreeningEndDateError
    if (ScreeningEndDateHasBeenChanged) {
      this._closeSaveScreeningEndDateModal()
    }
    // SAVE BASELINE START DATE
    const baselineStartDateHasBeenChanged = busySavingBaselineStartDate && !nextProps.busySavingBaselineStartDate && !nextProps.saveBaselineStartDateError
    if (baselineStartDateHasBeenChanged) {
      this._closeSaveBaselineStartDateModal()
    }
    // ADD UNSCHEDULED VISIT FOR ENGAGE
    const { busyAddingUnscheduledVisitForEngage, resetAddUnscheduledVisitForEngageError } = this.props
    const visitHasBeenAddedForEngage = busyAddingUnscheduledVisitForEngage && !nextProps.busyAddingUnscheduledVisitForEngage && !nextProps.addUnscheduledVisitForEngageError
    if (visitHasBeenAddedForEngage) {
      this._closeAddUnscheduledVisitForEngageModal(resetAddUnscheduledVisitForEngageError)()
    }
    // FAST TRACK VISIT
    const visitHasBeenFastTracked = busyFastTrackingVisit && !nextProps.busyFastTrackingVisit && !nextProps.fastTrackVisitError
    if (visitHasBeenFastTracked) {
      this._closeFastTrackVisitModal(resetFastTrackVisitError)()
    }
    // CANCEL FAST TRACK
    const fastTrackHasBeenCanceled = busyCancelingFastTrack && !nextProps.busyCancelingFastTrack && !nextProps.cancelFastTrackError
    if (fastTrackHasBeenCanceled) {
      this._closeCancelFastTrackModal(resetCancelFastTrackError)()
    }
  }

  render() {
    const {
      router,
      visits, busyFetchingVisits, fetchVisitsError,
      cancelVisit, busyCancelingVisit, cancelVisitError, resetCancelVisitError,
      exportVisit, busyExportingVisit, exportVisitError,
      exportAllVisits, busyExportingAllVisits, exportAllVisitsError,
      addUnscheduledVisit, busyAddingUnscheduledVisit, addUnscheduledVisitError,
      changeVisitDate, busyChangingVisitDate, changeVisitDateError,
      saveBaselineStartDate, busySavingBaselineStartDate, saveBaselineStartDateError,
      saveScreeningEndDate, busySavingScreeningEndDate, saveScreeningEndDateError,
      addUnscheduledVisitForEngage, addUnscheduledVisitForEngageError, busyAddingUnscheduledVisitForEngage, resetAddUnscheduledVisitForEngageError,
      fetchVisitTemplates, fetchVisitForms, visitTemplates, visitForms, busyFetchingVisitTemplates, busyFetchingVisitForms, fetchVisitTemplatesError, fetchVisitFormsError,
      fastTrackVisit, busyFastTrackingVisit, fastTrackVisitError, resetFastTrackVisitError,
      cancelFastTrack, busyCancelingFastTrack, cancelFastTrackError, resetCancelFastTrackError,
      rescheduleVisits, busyReschedulingVisits, rescheduleVisitsError, resetRescheduleVisitsError,
      toggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError,
      serverTime,
    } = this.props


    const {
      idOfVisitToCancelFromState,
      idOfVisitToExport, labelOfVisitToExport,
      idOfVisitToChangeDate, newDateOfVisitToChangeDate,
      screeningEndDateToSave, baselineStartDateToSave,
      showCancelUnscheduledVisitModal, showExportAllVisitsModal, showExportVisitModal, showChangingVisitDateModal, showAddUnscheduledVisitModal, showSavingScreeningEndDateModal,
      showSavingBaselineStartDateModal, showAddUnscheduledVisitForEngageModal, showFastTrackVisitModal, showCancelFastTrackModal, showRescheduleVisitsModal,
    } = this.state

    const { study_id, patient_id } = router.params // eslint-disable-line camelcase

    const { patient } = this.props
    const { startScreening, endScreening, baseline, status } = patient
    const { upcomingVisit } = this.state
    const patientIsEligible = status === 1

    const screeningIsEnabled = !isNilOrEmpty(startScreening) && !!startScreening
    const screeningPeriodAndBaselineAreSet = !isNilOrEmpty(startScreening) && !isNilOrEmpty(endScreening) && !isNilOrEmpty(baseline)
    const showElementsWhenScreeningIsDisabledOrIsCompleted = screeningIsEnabled ? screeningPeriodAndBaselineAreSet : true

    const hasError = !isNil(fetchVisitsError)
    const hasVisits = !isNilOrEmpty(visits)

    const { study, resources, site } = this.props
    const isAPureEngageStudy = isPureEngageStudy(study)
    const canHaveVirtualVisits = canSiteHaveVirtualVisits(site)
    const isArchivedStudy = !isNilOrEmpty(study) && isStudyArchived(study.workflow)
    const showVirtualVisitLabel = !window.sessionHandler.userRoles.includes("investigator") && !window.sessionHandler.userRoles.includes("site_user")

    // fetch study related configurable labels
    const fastTrackEnableButtonText = getFastTrackEnableButtonText(resources)
    const fastTrackCancelButtonText = getFastTrackCancelButtonText(resources)
    const fastTrackConfirmationText = getFastTrackConfirmationText(resources)
    const fastTrackCancellationText = getFastTrackCancellationText(resources)
    const cancelFastTrackFailedText = getCancelFasttrackFailedTitle(resources)
    const addUnscheduledVisitButtonTooltip = getAddUnscheduledVisitButtonToolkit(resources)
    const changeVisitDateButtonTooltip = getChangeVisitDateButtonToolkit(resources)
    const fastTrackButtonTooltip = getRescueTherapyButtonToolkit(resources)
    const hasFastTrackEnabled = !!patient.fastTrackVisit

    return (
      <div className="c-patient">
        { !busyFetchingVisits && hasError && this._renderError(fetchVisitsError) }
        { !busyFetchingVisits && hasVisits && this._renderAddExportButtons(visits, study_id, patient_id, exportAllVisits, busyExportingAllVisits, addUnscheduledVisit, busyAddingUnscheduledVisit, showElementsWhenScreeningIsDisabledOrIsCompleted, isAPureEngageStudy, fetchVisitTemplates, fetchVisitForms, patient.upcomingVisit, patient.fastTrackVisit, hasFastTrackEnabled, fastTrackEnableButtonText, fastTrackCancelButtonText, screeningIsEnabled, addUnscheduledVisitButtonTooltip, isArchivedStudy, fastTrackButtonTooltip, serverTime) }
        { screeningIsEnabled && this._renderScreeningDates(study_id, patient_id, screeningPeriodAndBaselineAreSet, startScreening, endScreening, baseline, saveScreeningEndDate, saveBaselineStartDate, patientIsEligible, toggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isArchivedStudy, canHaveVirtualVisits, upcomingVisit, showVirtualVisitLabel, changeVisitDateButtonTooltip) }
        { showElementsWhenScreeningIsDisabledOrIsCompleted && !busyFetchingVisits && hasVisits && this._renderVisitDates(visits, study_id, patient_id, exportVisit, changeVisitDate, screeningIsEnabled, isAPureEngageStudy, upcomingVisit, showChangingVisitDateModal, showRescheduleVisitsModal, toggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isArchivedStudy, canHaveVirtualVisits, showVirtualVisitLabel, upcomingVisit.id, changeVisitDateButtonTooltip, serverTime) }
        { !busyFetchingVisits && !hasError && !screeningIsEnabled && !hasVisits && this._renderNoDataAvailable() }
        { showCancelUnscheduledVisitModal && this._renderCancelUnscheduledVisitModal(study_id, patient_id, idOfVisitToCancelFromState, cancelVisit, cancelVisitError, resetCancelVisitError, busyCancelingVisit) }
        { showExportAllVisitsModal && this._renderExportAllVisitsModal(busyExportingAllVisits, exportAllVisitsError, study_id, patient_id, exportAllVisits) }
        { showExportVisitModal && this._renderExportVisitModal(busyExportingVisit, exportVisitError, study_id, patient_id, idOfVisitToExport, labelOfVisitToExport, exportVisit) }
        { showChangingVisitDateModal && this._renderChangeVisitDateModal(busyChangingVisitDate, changeVisitDateError, study_id, patient_id, idOfVisitToChangeDate, newDateOfVisitToChangeDate, changeVisitDate) }
        { showRescheduleVisitsModal && this._renderRescheduleVisitModal(study_id, patient_id, newDateOfVisitToChangeDate, changeVisitDate, idOfVisitToChangeDate, rescheduleVisits, resetRescheduleVisitsError, busyReschedulingVisits, rescheduleVisitsError) }
        { showAddUnscheduledVisitModal && this._renderAddUnscheduledVisitModal(busyAddingUnscheduledVisit, addUnscheduledVisitError, study_id, patient_id, addUnscheduledVisit) }
        { showSavingScreeningEndDateModal && this._renderSaveScreeningEndDateModal(busySavingScreeningEndDate, saveScreeningEndDateError, study_id, patient_id, screeningEndDateToSave, saveScreeningEndDate) }
        { showSavingBaselineStartDateModal && this._renderSaveBaselineStartDateModal(busySavingBaselineStartDate, saveBaselineStartDateError, study_id, patient_id, baselineStartDateToSave, saveBaselineStartDate) }
        { showAddUnscheduledVisitForEngageModal && this._renderAddUnscheduledVisitForEngageModal(busyAddingUnscheduledVisitForEngage, addUnscheduledVisitForEngageError, study_id, patient_id, addUnscheduledVisitForEngage, resetAddUnscheduledVisitForEngageError, visitTemplates, visitForms, busyFetchingVisitTemplates, busyFetchingVisitForms, fetchVisitTemplatesError, fetchVisitFormsError) }
        { showFastTrackVisitModal && this._renderFastTrackVisitModal(study_id, patient_id, fastTrackVisit, fastTrackConfirmationText, busyFastTrackingVisit, fastTrackVisitError, resetFastTrackVisitError, fastTrackEnableButtonText) }
        { showCancelFastTrackModal && this._renderCancelFastTrackModal(study_id, patient_id, cancelFastTrack, fastTrackCancellationText, busyCancelingFastTrack, cancelFastTrackError, resetCancelFastTrackError, cancelFastTrackFailedText, fastTrackCancelButtonText) }
      </div>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoDataAvailable = () => (
    <HintLabel>
      No visit data available
    </HintLabel>
  )

  _renderAddExportButtons(visits, studyId, patientId, exportAllVisits, busyExportingAllVisits, addUnscheduledVisit,
    busyAddingUnscheduledVisit, showAddUnscheduledVisitButton, isAPureEngageStudy, fetchVisitTemplates, fetchVisitForms,
    upcomingVisit, fastTrackVisitId, hasFastTrackEnabled, fastTrackEnableButtonText, fastTrackCancelButtonText,
    screeningIsEnabled, addUnscheduledVisitButtonTooltip, isArchivedStudy, fastTrackButtonTooltip, serverTime) {
    return (
      <MainButtonGroup buttonGroupClass="c-patient__buttons">
        { hasFastTrackEnabled && !hasSkippedVisits(visits) && (
        <MainButton
          isDisabled={ !firstVisitCompleted(visits, serverTime) || hasReachedFastTrackVisit(visits, upcomingVisit, fastTrackVisitId, serverTime) || hasSkippedVisits(visits) || isArchivedStudy }
          buttonClass="blue"
          tooltip={ isArchivedStudy ? fastTrackButtonTooltip : null }
          label={ fastTrackEnableButtonText }
          handleClick={ this._openFastTrackVisitModal }
          id="button-fast-track-visit" />
        ) }
        { hasSkippedVisits(visits) && (
        <MainButton
          isDisabled={ !isCancelFastTrackEnabled(visits, fastTrackVisitId, serverTime) || isArchivedStudy }
          buttonClass="blue"
          label={ fastTrackCancelButtonText }
          handleClick={ this._openCancelFastTrackModal }
          id="button-fast-track-visit-cancel" />
        ) }
        <MainButton
          isDisabled={ busyExportingAllVisits }
          buttonClass="blue"
          label="EXPORT ALL DATA"
          handleClick={ this._openExportAllVisitsModalAndExportAllVisits(studyId, patientId, exportAllVisits) }
          icon={ { name: ICON_DOWNLOAD } }
          id="button-export-all-visits-data" />
        { showAddUnscheduledVisitButton && (
          <MainButton
            isDisabled={ this._unscheduledVisitButtonDisabled(visits, screeningIsEnabled, busyAddingUnscheduledVisit, serverTime) || isArchivedStudy }
            buttonClass="blue"
            label="ADD UNSCHEDULED VISIT"
            tooltip={ isArchivedStudy ? addUnscheduledVisitButtonTooltip : null }
            icon={ { name: ICON_ADD_LIST } }
            handleClick={ isAPureEngageStudy ? this._openAddUnscheduledVisitForEngageModal(fetchVisitTemplates, fetchVisitForms, studyId) : this._openAddUnscheduledVisitModalAndAddUnscheduledVisit(studyId, patientId, addUnscheduledVisit, serverTime) }
            id="button-add-unscheduled-visit" />
        ) }
      </MainButtonGroup>
    )
  }

  _unscheduledVisitButtonDisabled = (visits, screeningIsEnabled, busyAddingUnscheduledVisit, serverTime) => {
    const visit = visits.find(firstVisit => firstVisit && firstVisit.plannedOn && (screeningIsEnabled ? !isScreeningVisit(firstVisit) : true) && !isVisitUnscheduled(firstVisit) && !isVisitCanceled(firstVisit) && !isVisitSkipped(firstVisit))
    return hasVisitToday(visits, serverTime) || busyAddingUnscheduledVisit || isAfterToday(visit && visit.plannedOn, serverTime)
  }

  _renderScreeningDates(studyId, patientId, screeningPeriodAndBaselineAreSet, screeningStartDate, screeningEndDate, baselineStartDate, saveScreeningEndDate,
    saveBaselineStartDate, patientIsEligible, toggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isArchivedStudy,
    canHaveVirtualVisits, upcomingVisit, showVirtualVisitLabel, changeVisitDateButtonTooltip, serverTime) {
    return (
      <PatientScreeningTable
        screeningStartDate={ screeningStartDate }
        screeningEndDate={ screeningEndDate }
        baselineStartDate={ baselineStartDate }
        screeningPeriodAndBaselineAreSet={ screeningPeriodAndBaselineAreSet }
        patientIsEligible={ patientIsEligible }
        startScreeningVisitId={ upcomingVisit.id }
        saveScreeningEndDate={ this._openSaveScreeningEndDateModalAndSaveScreeningEndDate(studyId, patientId, saveScreeningEndDate) }
        saveBaselineStartDate={ this._openSaveBaselineStartDateModalAndSaveBaselineStartDate(studyId, patientId, saveBaselineStartDate) }
        confirmToggleVirtualVisit={ (visitId, isVirtualVisit) => this._confirmToggleVirtualVisit(toggleVirtualVisit, studyId, patientId, visitId, isVirtualVisit) }
        busyTogglingVirtualVisit={ busyTogglingVirtualVisit }
        toggleVirtualVisitError={ toggleVirtualVisitError }
        resetToggleVirtualVisitError={ () => resetToggleVirtualVisitError() }
        isStudyArchived={ isArchivedStudy }
        tooltip={ changeVisitDateButtonTooltip }
        canSiteHaveVirtualVisits={ canHaveVirtualVisits }
        showVirtualVisitLabel={ showVirtualVisitLabel }
        serverTime={ serverTime }
        />
    )
  }

  _renderVisitDates(visits, studyId, patientId, exportVisit, changeVisitDate, patientHasScreeningPeriodEnabled, isAPureEngageStudy, upcomingVisit, showChangingVisitDateModal,
    showRescheduleVisitsModal, toggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isArchivedStudy, canHaveVirtualVisits, showVirtualVisitLabel,
    upcomingVisitId, changeVisitDateButtonTooltip, serverTime) {
    return (
      <PatientVisitsTable
        patientVisits={ visits }
        exportVisit={ this._openExportVisitModalAndExportVisit(studyId, patientId, exportVisit) }
        cancelVisit={ this._openCancelUnscheduledVisitModal }
        changeVisitDate={ this._openRescheduleVisitsModal(studyId, patientId, changeVisitDate) }
        patientHasScreeningPeriodEnabled={ patientHasScreeningPeriodEnabled }
        upcomingVisit={ upcomingVisit }
        busyChangingDatePickerDate={ showChangingVisitDateModal || showRescheduleVisitsModal }
        confirmToggleVirtualVisit={ (visitId, isVirtualVisit) => this._confirmToggleVirtualVisit(toggleVirtualVisit, studyId, patientId, visitId, isVirtualVisit, upcomingVisitId) }
        busyTogglingVirtualVisit={ busyTogglingVirtualVisit }
        toggleVirtualVisitError={ toggleVirtualVisitError }
        resetToggleVirtualVisitError={ () => resetToggleVirtualVisitError() }
        isStudyArchived={ isArchivedStudy }
        tooltip={ changeVisitDateButtonTooltip }
        canSiteHaveVirtualVisits={ canHaveVirtualVisits }
        showVirtualVisitLabel={ showVirtualVisitLabel }
        isAPureEngageStudy={ isAPureEngageStudy }
        serverTime={ serverTime }
        />
    )
  }

  _confirmToggleVirtualVisit = (toggleVirtualVisit, studyId, patientId, visitId, isVirtualVisit, upcomingVisitId) => {
    const isUpcomingVisit = upcomingVisitId === visitId
    toggleVirtualVisit(studyId, patientId, visitId, isVirtualVisit, isUpcomingVisit)
  }

  // AUTOMATIC RESCHEDULING
  _openRescheduleVisitsModal = (studyId, patientId, changeVisitDate) => (visitId, isUnscheduledVisit) => (newDate) => {
    this.setState({
      showRescheduleVisitsModal: !isUnscheduledVisit,
      showChangingVisitDateModal: isUnscheduledVisit,
      idOfVisitToChangeDate: visitId,
      newDateOfVisitToChangeDate: newDate,
    })
    if (isUnscheduledVisit) {
      this._changeVisitDate(studyId, patientId, visitId, newDate, changeVisitDate)()
    }
  }

  _closeRescheduleVisitsModalAndChangeVisitDate = (studyId, patientId, newDate, changeVisitDate, resetRescheduleVisitsError, visitId) => {
    this.setState({
      showRescheduleVisitsModal: false,
      showChangingVisitDateModal: true,
    })
    this._changeVisitDate(studyId, patientId, visitId, newDate, changeVisitDate)()
    resetRescheduleVisitsError()
  }

  _renderRescheduleVisitModal = (studyId, patientId, newDate, changeVisitDate, visitId, rescheduleVisits, resetRescheduleVisitsError, busyReschedulingVisits, rescheduleVisitsError) => (
    <BaseModal
      title="Reschedule visits"
      handleClose={ this._closeRescheduleVisitsModal() }
      forceInteraction={ busyReschedulingVisits }>
      <RescheduleVisitsModal
        handleCanceled={ this._handleCanceledRescheduleVisit(studyId, patientId, newDate, changeVisitDate, resetRescheduleVisitsError, visitId) }
        handleConfirmed={ this._confirmRescheduleVisitsModal(studyId, patientId, visitId, newDate, rescheduleVisits) }
        error={ rescheduleVisitsError }
        loading={ busyReschedulingVisits } />
    </BaseModal>
  )

  _handleCanceledRescheduleVisit = (studyId, patientId, newDate, changeVisitDate, resetRescheduleVisitsError, visitId) => (firstModal) => {
    if (firstModal) {
      this._closeRescheduleVisitsModalAndChangeVisitDate(studyId, patientId, newDate, changeVisitDate, resetRescheduleVisitsError, visitId)
    } else {
      this._closeRescheduleVisitsModal()()
    }
  }

  _closeRescheduleVisitsModal = () => () => {
    this.setState({ showRescheduleVisitsModal: false })
  }

  _confirmRescheduleVisitsModal = (studyId, patientId, visitId, newDate, rescheduleVisits) => () => {
    rescheduleVisits(studyId, patientId, visitId, newDate)
  }

  // ADD UNSCHEDULED VISIT (for 4ME studies)
  _openAddUnscheduledVisitModalAndAddUnscheduledVisit = (studyId, patientId, addUnscheduledVisit, serverTime) => () => {
    this._openAddUnscheduledVisitModal()
    this._addUnscheduledVisit(studyId, patientId, addUnscheduledVisit, serverTime)()
  }

  _addUnscheduledVisit = (studyId, patientId, addUnscheduledVisit, serverTime) => () => addUnscheduledVisit(studyId, patientId, serverTime)

  _renderAddUnscheduledVisitModal(busyAddingUnscheduledVisit, addUnscheduledVisitError, studyId, patientId, addUnscheduledVisit) {
    return (
      <BaseModal
        title="Add unscheduled visit"
        handleClose={ this._closeAddUnscheduledVisitModal }
        forceInteraction={ busyAddingUnscheduledVisit }>
        <LoadingModal
          loading={ busyAddingUnscheduledVisit }
          loadingHint="Adding unscheduled visit"
          error={ addUnscheduledVisitError }
          closeLoadingModal={ this._closeAddUnscheduledVisitModal }
          retryAction={ this._addUnscheduledVisit(studyId, patientId, addUnscheduledVisit) } />
      </BaseModal>
    )
  }


  // ENABLE FAST TRACK VISIT
  _renderFastTrackVisitModal = (studyId, patientId, fastTrackVisit, fastTrackConfirmationText, busyFastTrackingVisit, fastTrackVisitError, resetFastTrackVisitError, fastTrackEnableButtonText) => (
    <BaseModal
      title={ fastTrackEnableButtonText }
      handleClose={ this._closeFastTrackVisitModal(resetFastTrackVisitError) }
      forceInteraction={ busyFastTrackingVisit }>
      <FastTrackVisitForm
        configMessage={ fastTrackConfirmationText }
        handleCanceled={ this._closeFastTrackVisitModal(resetFastTrackVisitError) }
        handleConfirmed={ this._fastTrackVisitConfirmed(studyId, patientId, fastTrackVisit) }
        loading={ busyFastTrackingVisit }
        error={ fastTrackVisitError }
      />
    </BaseModal>
  )

  _openFastTrackVisitModal = () => {
    this.setState({ showFastTrackVisitModal: true })
  }

  _closeFastTrackVisitModal = resetFastTrackVisitError => () => {
    this.setState({ showFastTrackVisitModal: false })
    resetFastTrackVisitError()
  }

  _fastTrackVisitConfirmed = (studyId, patientId, fastTrackVisit) => (credentials, reason) => {
    fastTrackVisit(studyId, patientId, credentials, reason)
  }

  // CANCEL FAST TRACK VISIT
  _renderCancelFastTrackModal = (studyId, patientId, cancelFastTrack, fastTrackCancellationText, busyCancelingFastTrack, cancelFastTrackError, resetCancelFastTrackError, cancelFastTrackFailedText, fastTrackCancelButtonText) => (
    <BaseModal
      title={ fastTrackCancelButtonText }
      handleClose={ this._closeCancelFastTrackModal(resetCancelFastTrackError) }
      forceInteraction={ busyCancelingFastTrack }>
      <CancelFastTrackForm
        configMessage={ fastTrackCancellationText }
        handleCanceled={ this._closeCancelFastTrackModal(resetCancelFastTrackError) }
        handleConfirmed={ this._cancelFastTrackConfirmed(studyId, patientId, cancelFastTrack, cancelFastTrackFailedText) }
        loading={ busyCancelingFastTrack }
        error={ cancelFastTrackError } />
    </BaseModal>
  )

  _cancelFastTrackConfirmed = (studyId, patientId, cancelFastTrack, cancelFastTrackFailedText) => (credentials) => {
    cancelFastTrack(studyId, patientId, credentials, cancelFastTrackFailedText)
  }

  _openCancelFastTrackModal = () => {
    this.setState({ showCancelFastTrackModal: true })
  }

  _closeCancelFastTrackModal = resetCancelFastTrackError => () => {
    this.setState({ showCancelFastTrackModal: false })
    resetCancelFastTrackError()
  }

  _openAddUnscheduledVisitModal = () => {
    this.setState({ showAddUnscheduledVisitModal: true })
  }

  _closeAddUnscheduledVisitModal = () => {
    this.setState({ showAddUnscheduledVisitModal: false })
  }

  // ADD UNSCHEDULED VISIT (for Engage studies)
  _renderAddUnscheduledVisitForEngageModal(busyAddingUnscheduledVisitForEngage, addUnscheduledVisitForEngageError, studyId, patientId, addUnscheduledVisitForEngage, resetAddUnscheduledVisitForEngageError, visitTemplates, visitForms, busyFetchingVisitTemplates, busyFetchingVisitForms, fetchVisitTemplatesError, fetchVisitFormsError) {
    return (
      <BaseModal
        title="Add unscheduled visit"
        handleClose={ this._closeAddUnscheduledVisitForEngageModal(resetAddUnscheduledVisitForEngageError) }
        forceInteraction={ busyAddingUnscheduledVisitForEngage }>
        <AddUnscheduledVisitForEngageForm
          availableTemplates={ visitTemplates }
          availableForms={ visitForms }
          error={ [addUnscheduledVisitForEngageError, fetchVisitTemplatesError, fetchVisitFormsError] }
          loading={ busyAddingUnscheduledVisitForEngage }
          busyFetchingVisitTemplates={ busyFetchingVisitTemplates }
          busyFetchingVisitForms={ busyFetchingVisitForms }
          handleCanceled={ this._closeAddUnscheduledVisitForEngageModal(resetAddUnscheduledVisitForEngageError) }
          handleConfirmed={ this._addUnscheduledVisitForEngageConfirmed(addUnscheduledVisitForEngage, studyId, patientId) } />
      </BaseModal>
    )
  }

  _addUnscheduledVisitForEngageConfirmed = (addUnscheduledVisitForEngage, studyId, patientId) => (visitDetails, isPlannedVisit) => {
    addUnscheduledVisitForEngage(studyId, patientId, visitDetails, isPlannedVisit)
  }

  _openAddUnscheduledVisitForEngageModal = (fetchVisitTemplates, fetchVisitForms, studyId) => () => {
    fetchVisitTemplates(studyId)
    fetchVisitForms(studyId)
    this.setState({ showAddUnscheduledVisitForEngageModal: true })
  }

  _closeAddUnscheduledVisitForEngageModal = resetAddUnscheduledVisitForEngageError => () => {
    this.setState({ showAddUnscheduledVisitForEngageModal: false })
    resetAddUnscheduledVisitForEngageError()
  }

  // EXPORT ALL VISIT DATA
  _openExportAllVisitsModalAndExportAllVisits = (studyId, patientId, exportAllVisits) => () => {
    this._openExportAllVisitsModal()
    this._exportAllVisits(studyId, patientId, exportAllVisits)()
  }

  _exportAllVisits = (studyId, patientId, exportAllVisits) => () => exportAllVisits(studyId, patientId)

  _renderExportAllVisitsModal(busyExportingAllVisits, exportAllVisitsError, studyId, patientId, exportAllVisits) {
    return (
      <BaseModal
        title="Export all visit data"
        handleClose={ this._closeExportAllVisitsModal }
        forceInteraction={ busyExportingAllVisits }>
        <LoadingModal
          loading={ busyExportingAllVisits }
          loadingHint="Exporting all visit data, this might take a few minutes"
          error={ exportAllVisitsError }
          closeLoadingModal={ this._closeExportAllVisitsModal }
          retryAction={ this._exportAllVisits(studyId, patientId, exportAllVisits) } />
      </BaseModal>
    )
  }

  _openExportAllVisitsModal = () => {
    this.setState({ showExportAllVisitsModal: true })
  }

  _closeExportAllVisitsModal = () => {
    this.setState({ showExportAllVisitsModal: false })
  }

  // EXPORT VISIT DATA
  _openExportVisitModalAndExportVisit = (studyId, patientId, exportVisit) => (visitId, visitLabel) => () => {
    this._openExportVisitModal(visitId, visitLabel)
    this._exportVisit(studyId, patientId, visitId, visitLabel, exportVisit)()
  }

  _exportVisit = (studyId, patientId, visitId, visitLabel, exportVisit) => () => exportVisit(studyId, patientId, visitId, visitLabel)

  _renderExportVisitModal(busyExportingVisit, exportVisitError, studyId, patientId, visitId, visitLabel, exportVisit) {
    return (
      <BaseModal
        title="Export visit data"
        handleClose={ this._closeExportVisitModal }
        forceInteraction={ busyExportingVisit }>
        <LoadingModal
          loading={ busyExportingVisit }
          loadingHint="Exporting visit data"
          error={ exportVisitError }
          closeLoadingModal={ this._closeExportVisitModal }
          retryAction={ this._exportVisit(studyId, patientId, visitId, visitLabel, exportVisit) } />
      </BaseModal>
    )
  }

  _openExportVisitModal = (visitId, visitLabel) => {
    this.setState({
      showExportVisitModal: true,
      idOfVisitToExport: visitId,
      labelOfVisitToExport: visitLabel,
    })
  }

  _closeExportVisitModal = () => {
    this.setState({
      showExportVisitModal: false,
      idOfVisitToExport: null,
      labelOfVisitToExport: null,
    })
  }

  // CHANGE VISIT DATE
  // _openChangeVisitDateModalAndChangeVisitDate = (studyId, patientId, changeVisitDate) => visitId => (newDate) => {
  //   this._openChangeVisitDateModal(visitId, newDate)
  //   this._changeVisitDate(studyId, patientId, visitId, newDate, changeVisitDate)()
  // }

  _changeVisitDate = (studyId, patientId, visitId, newDate, changeVisitDate) => () => changeVisitDate(studyId, patientId, visitId, newDate)

  _renderChangeVisitDateModal(busyChangingVisitDate, changeVisitDateError, studyId, patientId, visitId, newDate, changeVisitDate) {
    return (
      <BaseModal
        title="Change visit date"
        handleClose={ this._closeChangeVisitDateModal }
        forceInteraction={ busyChangingVisitDate }>
        <LoadingModal
          loading={ busyChangingVisitDate }
          loadingHint="Changing visit date"
          error={ changeVisitDateError }
          closeLoadingModal={ this._closeChangeVisitDateModal }
          retryAction={ this._changeVisitDate(studyId, patientId, visitId, newDate, changeVisitDate) } />
      </BaseModal>
    )
  }

  // _openChangeVisitDateModal = (visitId, newDate) => {
  //   this.setState({
  //     showChangingVisitDateModal: true,
  //     idOfVisitToChangeDate: visitId,
  //     newDateOfVisitToChangeDate: newDate,
  //   })
  // }

  _closeChangeVisitDateModal = () => {
    this.setState({
      showChangingVisitDateModal: false,
      idOfVisitToChangeDate: null,
      newDateOfVisitToChangeDate: null,
    })
  }

  // SAVE SCREENING END DATE
  _openSaveScreeningEndDateModalAndSaveScreeningEndDate = (studyId, patientId, saveScreeningEndDate) => (screeningEndDate) => {
    this._openSaveScreeningEndDateModal(screeningEndDate)
    this._saveScreeningEndDate(studyId, patientId, screeningEndDate, saveScreeningEndDate)()
  }

  _saveScreeningEndDate = (studyId, patientId, screeningEndDate, saveScreeningEndDate) => () => saveScreeningEndDate(studyId, patientId, screeningEndDate)

  _renderSaveScreeningEndDateModal(busySavingScreeningEndDate, saveScreeningEndDateError, studyId, patientId, screeningEndDate, saveScreeningEndDate) {
    return (
      <BaseModal
        title="Save screening end date"
        handleClose={ this._closeSaveScreeningEndDateModal }
        forceInteraction={ busySavingScreeningEndDate }>
        <LoadingModal
          loading={ busySavingScreeningEndDate }
          loadingHint="Saving screening end date"
          error={ saveScreeningEndDateError }
          closeLoadingModal={ this._closeSaveScreeningEndDateModal }
          retryAction={ this._saveScreeningEndDate(studyId, patientId, screeningEndDate, saveScreeningEndDate) } />
      </BaseModal>
    )
  }

  _openSaveScreeningEndDateModal = (screeningEndDate) => {
    this.setState({
      showSavingScreeningEndDateModal: true,
      screeningEndDateToSave: screeningEndDate,
    })
  }

  _closeSaveScreeningEndDateModal = () => {
    this.setState({
      showSavingScreeningEndDateModal: false,
      screeningEndDateToSave: null,
    })
  }

  // SAVE BASELINE START DATE
  _openSaveBaselineStartDateModalAndSaveBaselineStartDate = (studyId, patientId, saveBaselineStartDate) => (baselineStartDate) => {
    this._openSaveBaselineStartDateModal(baselineStartDate)
    this._saveBaselineStartDate(studyId, patientId, baselineStartDate, saveBaselineStartDate)()
  }

  _saveBaselineStartDate = (studyId, patientId, baselineStartDate, saveBaselineStartDate) => () => saveBaselineStartDate(studyId, patientId, baselineStartDate)

  _renderSaveBaselineStartDateModal(busySavingBaselineStartDate, saveBaselineStartDateError, studyId, patientId, baselineStartDate, saveBaselineStartDate) {
    return (
      <BaseModal
        title="Save baseline start date"
        handleClose={ this._closeSaveBaselineStartDateModal }
        forceInteraction={ busySavingBaselineStartDate }>
        <LoadingModal
          loading={ busySavingBaselineStartDate }
          loadingHint="Saving baseline start date"
          error={ saveBaselineStartDateError }
          closeLoadingModal={ this._closeSaveBaselineStartDateModal }
          retryAction={ this._saveBaselineStartDate(studyId, patientId, baselineStartDate, saveBaselineStartDate) } />
      </BaseModal>
    )
  }

  _openSaveBaselineStartDateModal = (baselineStartDate) => {
    this.setState({
      showSavingBaselineStartDateModal: true,
      baselineStartDateToSave: baselineStartDate,
    })
  }

  _closeSaveBaselineStartDateModal = () => {
    this.setState({
      showSavingBaselineStartDateModal: false,
      baselineStartDateToSave: null,
    })
  }

  // CANCEL UNSCHEDULED MODAL
  _cancelVisitConfirmed = (cancelVisit, studyId, patientId, visitId) => reason => cancelVisit(studyId, patientId, visitId, reason)

  _renderCancelUnscheduledVisitModal(studyId, patientId, visitId, cancelVisit, cancelVisitError, resetCancelVisitError, busyCancelingVisit) {
    return (
      <BaseModal
        title="Cancel unscheduled visit"
        handleClose={ this._closeCancelUnscheduledVisitModal(resetCancelVisitError) }
        forceInteraction={ busyCancelingVisit }>
        <CancelVisitForm
          handleCanceled={ this._closeCancelUnscheduledVisitModal(resetCancelVisitError) }
          handleConfirmed={ this._cancelVisitConfirmed(cancelVisit, studyId, patientId, visitId) }
          loading={ busyCancelingVisit }
          error={ cancelVisitError } />
      </BaseModal>
    )
  }

  _openCancelUnscheduledVisitModal = idOfVisitToCancelFromState => () => {
    this.setState({
      showCancelUnscheduledVisitModal: true,
      idOfVisitToCancelFromState,
    })
  }

  _closeCancelUnscheduledVisitModal = resetCancelVisitError => () => {
    this.setState({
      showCancelUnscheduledVisitModal: false,
      idOfVisitToCancelFromState: null,
    })
    resetCancelVisitError()
  }
}

PatientTabVisits.propTypes = {
  router: PropTypes.object.isRequired,
  // study
  study: PropTypes.object.isRequired,
  // site
  site: PropTypes.object.isRequired,
  // patient
  patient: PropTypes.object.isRequired,
  // visits
  visits: PropTypes.array,
  busyFetchingVisits: PropTypes.bool.isRequired,
  fetchVisitsError: PropTypes.object,
  fetchVisits: PropTypes.func.isRequired,
  // visit templates & forms
  visitTemplates: PropTypes.array,
  fetchVisitTemplates: PropTypes.func.isRequired,
  visitForms: PropTypes.array,
  fetchVisitForms: PropTypes.func.isRequired,
  // cancel visit
  cancelVisit: PropTypes.func.isRequired,
  idOfVisitToCancel: PropTypes.string,
  cancelVisitError: PropTypes.object,
  resetCancelVisitError: PropTypes.func.isRequired,
  busyCancelingVisit: PropTypes.bool.isRequired,
  // export visit data
  exportVisit: PropTypes.func.isRequired,
  exportVisitError: PropTypes.object,
  busyExportingVisit: PropTypes.bool.isRequired,
  // export all visit data
  exportAllVisits: PropTypes.func.isRequired,
  exportAllVisitsError: PropTypes.object,
  busyExportingAllVisits: PropTypes.bool.isRequired,
  // change visit date
  changeVisitDate: PropTypes.func.isRequired,
  changeVisitDateError: PropTypes.object,
  busyChangingVisitDate: PropTypes.bool.isRequired,
  // automatic rescheduling
  rescheduleVisits: PropTypes.func.isRequired,
  rescheduleVisitsError: PropTypes.object,
  busyReschedulingVisits: PropTypes.bool.isRequired,
  resetRescheduleVisitsError: PropTypes.func.isRequired,
  // add unscheduled visits
  addUnscheduledVisit: PropTypes.func.isRequired,
  addUnscheduledVisitError: PropTypes.object,
  busyAddingUnscheduledVisit: PropTypes.bool.isRequired,
  // add unscheduled visit for engage
  addUnscheduledVisitForEngage: PropTypes.func.isRequired,
  addUnscheduledVisitForEngageError: PropTypes.object,
  busyAddingUnscheduledVisitForEngage: PropTypes.bool.isRequired,
  resetAddUnscheduledVisitForEngageError: PropTypes.func.isRequired,
  busyFetchingVisitTemplates: PropTypes.bool.isRequired,
  busyFetchingVisitForms: PropTypes.bool.isRequired,
  fetchVisitTemplatesError: PropTypes.object,
  fetchVisitFormsError: PropTypes.object,
  // save screening end date
  saveScreeningEndDate: PropTypes.func.isRequired,
  saveScreeningEndDateError: PropTypes.object,
  busySavingScreeningEndDate: PropTypes.bool.isRequired,
  // save baseline start date
  saveBaselineStartDate: PropTypes.func.isRequired,
  saveBaselineStartDateError: PropTypes.object,
  busySavingBaselineStartDate: PropTypes.bool.isRequired,
  // fast track visit
  fastTrackVisit: PropTypes.func.isRequired,
  busyFastTrackingVisit: PropTypes.bool.isRequired,
  resetFastTrackVisitError: PropTypes.func.isRequired,
  fastTrackVisitError: PropTypes.object,
  // cancel fast track
  cancelFastTrack: PropTypes.func.isRequired,
  busyCancelingFastTrack: PropTypes.bool.isRequired,
  resetCancelFastTrackError: PropTypes.func.isRequired,
  cancelFastTrackError: PropTypes.object,
  // toggle virtual visit
  toggleVirtualVisit: PropTypes.func.isRequired,
  busyTogglingVirtualVisit: PropTypes.bool,
  toggleVirtualVisitError: PropTypes.object,
  resetToggleVirtualVisitError: PropTypes.func.isRequired,
  // resources
  resources: PropTypes.object,
  // time travel
  serverTime: PropTypes.string,
}

PatientTabVisits.defaultProps = {
  // visits
  visits: [],
  fetchVisitsError: null,
  // cancel visit
  idOfVisitToCancel: '',
  cancelVisitError: null,
  // export visit data
  exportVisitError: null,
  // export all visit data
  exportAllVisitsError: null,
  // change visit date
  changeVisitDateError: null,
  // automatic rescheduling
  rescheduleVisitsError: null,
  // add unscheduled visits
  addUnscheduledVisitError: null,
  // add unscheduled visits for engage
  addUnscheduledVisitForEngageError: null,
  fetchVisitTemplatesError: null,
  fetchVisitFormsError: null,
  visitTemplates: [],
  visitForms: [],
  // save screening end date
  saveScreeningEndDateError: null,
  // save baseline start date
  saveBaselineStartDateError: null,
  // fast track visit
  fastTrackVisitError: null,
  // cancel fast track
  cancelFastTrackError: null,
  // toggle virtual visit
  busyTogglingVirtualVisit: false,
  toggleVirtualVisitError: null,
  // resources
  resources: null,
  serverTime: null,
}

const mapStateToProps = state => ({
  // study
  study: state.set.study,
  // site
  site: state.set.site,
  // patient
  patient: state.patients.patient,
  // visits
  visits: state.visits.visitList,
  busyFetchingVisits: state.visits.busyFetchingVisits,
  fetchVisitsError: state.visits.fetchVisitsError,
  // cancel visit
  idOfVisitToCancel: state.visits.idOfVisitToCancel,
  cancelVisitError: state.visits.cancelVisitError,
  busyCancelingVisit: state.visits.busyCancelingVisit,
  // export visit data
  exportVisitError: state.visits.exportVisitError,
  busyExportingVisit: state.visits.busyExportingVisit,
  // export all visit data
  exportAllVisitsError: state.visits.exportAllVisitsError,
  busyExportingAllVisits: state.visits.busyExportingAllVisits,
  // change visit date
  changeVisitDateError: state.visits.changeVisitDateError,
  busyChangingVisitDate: state.visits.busyChangingVisitDate,
  // automatic rescheduling
  rescheduleVisitsError: state.visits.rescheduleVisitsError,
  busyReschedulingVisits: state.visits.busyReschedulingVisits,
  // add unscheduled visit
  addUnscheduledVisitError: state.visits.addUnscheduledVisitError,
  busyAddingUnscheduledVisit: state.visits.busyAddingUnscheduledVisit,
  // add unscheduled visit for engage
  addUnscheduledVisitForEngageError: state.visits.addUnscheduledVisitForEngageError,
  busyAddingUnscheduledVisitForEngage: state.visits.busyAddingUnscheduledVisitForEngage,
  visitTemplates: state.visits.visitTemplates,
  visitForms: state.visits.visitForms,
  busyFetchingVisitTemplates: state.visits.busyFetchingVisitTemplates,
  busyFetchingVisitForms: state.visits.busyFetchingVisitForms,
  fetchVisitTemplatesError: state.visits.fetchVisitTemplatesError,
  fetchVisitFormsError: state.visits.fetchVisitFormsError,
  // save screening end date
  saveScreeningEndDateError: state.patients.saveScreeningEndDateError,
  busySavingScreeningEndDate: state.patients.busySavingScreeningEndDate,
  // save baseline start date
  saveBaselineStartDateError: state.patients.saveBaselineStartDateError,
  busySavingBaselineStartDate: state.patients.busySavingBaselineStartDate,
  // fast track visit
  fastTrackVisitError: state.visits.fastTrackVisitError,
  busyFastTrackingVisit: state.visits.busyFastTrackingVisit,
  // cancel fast track visit
  cancelFastTrackError: state.visits.cancelFastTrackError,
  busyCancelingFastTrack: state.visits.busyCancelingFastTrack,
  // toggle virtual visit
  busyTogglingVirtualVisit: state.visits.busyTogglingVirtualVisit,
  toggleVirtualVisitError: state.visits.toggleVirtualVisitError,
  // resources
  resources: state.set.study.resources,
  // server time
  serverTime: state.timeTravel.serverTime,
})

const mapDispatchToProps = dispatch => ({
  exportVisit: (studyId, patientId, visitId, visitLabel) => dispatch(VisitActions.exportVisit(studyId, patientId, visitId, visitLabel)),
  exportAllVisits: (studyId, patientId) => dispatch(VisitActions.exportAllVisits(studyId, patientId)),
  cancelVisit: (studyId, patientId, visitId, reason) => dispatch(VisitActions.cancelVisit(studyId, patientId, visitId, reason)),
  changeVisitDate: (studyId, patientId, visitId, newDate) => dispatch(VisitActions.changeVisitDate(studyId, patientId, visitId, newDate)),
  rescheduleVisits: (studyId, patientId, visitId, newDate) => dispatch(VisitActions.rescheduleVisits(studyId, patientId, visitId, newDate)),
  resetRescheduleVisitsError: () => dispatch(VisitActions.resetRescheduleVisitsError()),
  addUnscheduledVisit: (studyId, patientId, serverTime) => dispatch(VisitActions.addUnscheduledVisit(studyId, patientId, serverTime)),
  saveScreeningEndDate: (studyId, patientId, screeningEndDate) => dispatch(PatientActions.saveScreeningEndDate(studyId, patientId, screeningEndDate)),
  saveBaselineStartDate: (studyId, patientId, baselineStartDate) => dispatch(PatientActions.saveBaselineStartDate(studyId, patientId, baselineStartDate)),
  resetCancelVisitError: () => dispatch(VisitActions.resetCancelVisitError()),
  fetchVisits: (studyId, patientId, includeDetails) => dispatch(VisitActions.fetchVisits(studyId, patientId, includeDetails)),
  addUnscheduledVisitForEngage: (studyId, patientId, visitDetails, isPlannedVisit) => dispatch(VisitActions.addUnscheduledVisitForEngage(studyId, patientId, visitDetails, isPlannedVisit)),
  resetAddUnscheduledVisitForEngageError: () => dispatch(VisitActions.resetAddUnscheduledVisitForEngageError()),
  fetchVisitTemplates: studyId => dispatch(VisitActions.fetchVisitTemplates(studyId)),
  fetchVisitForms: studyId => dispatch(VisitActions.fetchVisitForms(studyId)),
  fastTrackVisit: (studyId, patientId, credentials, reason) => dispatch(VisitActions.fastTrackVisit(studyId, patientId, credentials, reason)),
  cancelFastTrack: (studyId, patientId, credentials, cancelFastTrackFailedText) => dispatch(VisitActions.cancelFastTrack(studyId, patientId, credentials, cancelFastTrackFailedText)),
  resetFastTrackVisitError: () => dispatch(VisitActions.resetFastTrackVisitError()),
  resetCancelFastTrackError: () => dispatch(VisitActions.resetCancelFastTrackError()),
  toggleVirtualVisit: (studyId, patientId, visitId, isVirtualVisit, isUpcomingVisit) => dispatch(VisitActions.toggleVirtualVisit(studyId, patientId, visitId, isVirtualVisit, isUpcomingVisit)),
  resetToggleVirtualVisitError: () => dispatch(VisitActions.resetToggleVirtualVisitError()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientTabVisits))

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HintLabel from '../HintLabel'
import MainInput from '../inputs/MainInput'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class RequestResetCodeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      userPwd: '',
    }
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { userName, userPwd } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <HintLabel
          size={ 16 }
          hintClass="u-margin--vertical">
          Please enter your username and password to request a reset code to reset the patient’s pin
        </HintLabel>
        <MainInput
          value={ userName }
          label="Username"
          onInputChanged={ this._userNameChanged }
          maxLength={ 255 } />
        <MainInput
          value={ userPwd }
          label="Password"
          inputType="password"
          onInputChanged={ this._userPwdChanged }
          maxLength={ 255 } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, userName, userPwd) }
          confirmLabel="Request Reset Code"
          cancelDisabled={ loading }
          confirmDisabled={ userName.length === 0 || userPwd.length === 0 || loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _userNameChanged = (userName) => {
    this.setState({ userName })
  }

  _userPwdChanged = (userPwd) => {
    this.setState({ userPwd })
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, userName, userPwd) => () => {
    try {
      callback({
        userName,
        userPwd,
      })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

RequestResetCodeForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

RequestResetCodeForm.defaultProps = { error: null }

export default RequestResetCodeForm

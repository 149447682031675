import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const MainButtonGroup = (props) => {
  const { children, buttonGroupClass } = props

  return (
    <div className={ cc([buttonGroupClass, "main-button-group"]) }>
      { children }
    </div>
  )
}

MainButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  buttonGroupClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

MainButtonGroup.defaultProps = { buttonGroupClass: [] }

export default MainButtonGroup

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // region fetch visits
  fetchVisits: ['studyId', 'patientId', 'includeDetails'],
  fetchVisitsSuccess: ['visits'],
  fetchVisitsFailure: ['error'],
  // endregion
  // region add unscheduled visit
  addUnscheduledVisit: ['studyId', 'patientId', 'serverTime'],
  addUnscheduledVisitSuccess: ['visit'],
  addUnscheduledVisitFailure: ['error'],
  // endregion
  // region add unscheduled visit for engage
  addUnscheduledVisitForEngage: ['studyId', 'patientId', 'visitDetails', 'isPlannedVisit'],
  addUnscheduledVisitForEngageSuccess: ['visit'],
  addUnscheduledVisitForEngageFailure: ['error'],
  resetAddUnscheduledVisitForEngageError: null,
  // endregion
  // region change visit
  changeVisitDate: ['studyId', 'patientId', 'visitId', 'newDate'],
  changeVisitDateSuccess: ['visit'],
  changeVisitDateFailure: ['error'],
  // endregion
  // region automatic rescheduling
  rescheduleVisits: ['studyId', 'patientId', 'visitId', 'newDate'],
  rescheduleVisitsSuccess: ['response'],
  rescheduleVisitsFailure: ['error'],
  resetRescheduleVisitsError: null,
  // endregion
  // region cancel visit
  cancelVisit: ['studyId', 'patientId', 'visitId', 'reason'],
  cancelVisitSuccess: null,
  cancelVisitFailure: ['error'],
  resetCancelVisitError: null,
  // endregion
  // region export visit
  exportVisit: ['studyId', 'patientId', 'visitId', 'visitLabel'],
  exportVisitSuccess: ['visit'],
  exportVisitFailure: ['error'],
  // endregion
  // region export all visits
  exportAllVisits: ['studyId', 'patientId'],
  exportAllVisitsSuccess: ['visits'],
  exportAllVisitsFailure: ['error'],
  // endregion
  // region fetch visit templates
  fetchVisitTemplates: ['studyId'],
  fetchVisitTemplatesSuccess: ['visitTemplates'],
  fetchVisitTemplatesFailure: ['error'],
  resetFetchVisitTemplatesError: null,
  // endregion
  // region fetch visit forms
  fetchVisitForms: ['studyId'],
  fetchVisitFormsSuccess: ['visitForms'],
  fetchVisitFormsFailure: ['error'],
  resetFetchVisitFormsError: null,
  // endregion
  // region fast track visit
  fastTrackVisit: ['studyId', 'patientId', 'credentials', 'reason'],
  fastTrackVisitSuccess: ['response'],
  fastTrackVisitFailure: ['error'],
  resetFastTrackVisitError: null,
  // endregion
  // region cancel fast track
  cancelFastTrack: ['studyId', 'patientId', 'credentials', 'cancelFastTrackFailedText'],
  cancelFastTrackSuccess: ['response'],
  cancelFastTrackFailure: ['error'],
  resetCancelFastTrackError: null,
  // endregion
  // toggle virtual visit
  toggleVirtualVisit: ['studyId', 'patientId', 'visitId', 'isVirtualVisit', 'isUpcomingVisit'],
  toggleVirtualVisitSuccess: ['visit'],
  toggleVirtualVisitFailure: ['error'],
  resetToggleVirtualVisitError: null,
  resetShouldSubscribeToNotifications: null,
})

export const VisitTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // region unsorted
  visitId: null,
  // endregion
  // region fetch visits
  visitList: [],
  busyFetchingVisits: false,
  fetchVisitsError: null,
  // endregion
  // region cancel visit
  idOfVisitToCancel: null,
  cancelVisitError: null,
  busyCancelingVisit: false,
  // endregion
  // region add unscheduled visit (4me)
  addUnscheduledVisitError: null,
  busyAddingUnscheduledVisit: false,
  // endregion
  // region add unscheduled visit for engage
  addUnscheduledVisitForEngageError: null,
  busyAddingUnscheduledVisitForEngage: false,
  // region change visit
  busyChangingVisitDate: false,
  changeVisitDateError: null,
  // endregion
  // region automatic rescheduling
  busyReschedulingVisits: false,
  rescheduleVisitsError: null,
  // endregion
  // region export visit
  busyExportingVisit: false,
  exportVisitError: null,
  // endregion
  // region export visits
  busyExportingAllVisits: false,
  exportAllVisitsError: null,
  // endregion
  // region fetch visit templates
  visitTemplates: [],
  busyFetchingVisitTemplates: false,
  fetchVisitTemplatesError: null,
  // endregion
  // region fetch visit forms
  visitForms: [],
  busyFetchingVisitForms: false,
  fetchVisitFormsError: null,
  // endregion
  // region fast track visit
  busyFastTrackingVisit: false,
  fastTrackVisitError: null,
  // endregion
  // region cancel fast track
  busyCancelingFastTrack: false,
  cancelFastTrackError: null,
  // endregion
  // toggle virtual visit
  busyTogglingVirtualVisit: false,
  toggleVirtualVisitError: null,
  shouldSubscribeToNotifications: false,
})

/* ------------- Reducers ------------- */

// region fetch visits
export const fetchVisits = state => state.merge({
  visitList: [],
  busyFetchingVisits: true,
  fetchVisitsError: null,
})
export const fetchVisitsSuccess = (state, { visits }) => state.merge({
  busyFetchingVisits: false,
  visitList: visits,
})
export const fetchVisitsFailure = (state, { error }) => state.merge({
  busyFetchingVisits: false,
  fetchVisitsError: error,
})
// endregion
// region add unscheduled visits
export const addUnscheduledVisit = state => state.merge({
  busyAddingUnscheduledVisit: true,
  addUnscheduledVisitError: null,
})
export const addUnscheduledVisitSuccess = state => state.merge({ busyAddingUnscheduledVisit: false })
export const addUnscheduledVisitFailure = (state, { error }) => state.merge({
  busyAddingUnscheduledVisit: false,
  addUnscheduledVisitError: error,
})
// endregion
// region add unscheduled visits
export const addUnscheduledVisitForEngage = state => state.merge({
  busyAddingUnscheduledVisitForEngage: true,
  addUnscheduledVisitForEngageError: null,
})
export const addUnscheduledVisitForEngageSuccess = state => state.merge({ busyAddingUnscheduledVisitForEngage: false })
export const addUnscheduledVisitForEngageFailure = (state, { error }) => state.merge({
  busyAddingUnscheduledVisitForEngage: false,
  addUnscheduledVisitForEngageError: error,
})
export const resetAddUnscheduledVisitForEngageError = state => state.merge({ addUnscheduledVisitForEngageError: null })
// endregion
// region change visit date
export const changeVisitDate = (state, { visitId }) => state.merge({
  busyChangingVisitDate: true,
  visitId,
  changeVisitDateError: null,
})
export const changeVisitDateSuccess = state => state.merge({
  busyChangingVisitDate: false,
  visitId: null,
})
export const changeVisitDateFailure = (state, { error }) => state.merge({
  busyChangingVisitDate: false,
  changeVisitDateError: error,
})
// endregion
// region automatic rescheduling
export const rescheduleVisits = state => state.merge({
  busyReschedulingVisits: true,
  rescheduleVisitsError: null,
})
export const rescheduleVisitsSuccess = state => state.merge({ busyReschedulingVisits: false })
export const rescheduleVisitsFailure = (state, { error }) => state.merge({
  busyReschedulingVisits: false,
  rescheduleVisitsError: error,
})
export const resetRescheduleVisitsError = state => state.merge({ rescheduleVisitsError: null })
// endregion
// region cancel visit
export const cancelVisit = (state, { visitId }) => state.merge({
  busyCancelingVisit: true,
  idOfVisitToCancel: visitId,
  cancelVisitError: null,
})
export const cancelVisitSuccess = state => state.merge({
  busyCancelingVisit: false,
  idOfVisitToCancel: null,
})
export const cancelVisitFailure = (state, { error }) => state.merge({
  busyCancelingVisit: false,
  cancelVisitError: error,
})
export const resetCancelVisitError = state => state.merge({ cancelVisitError: null })
// endregion
// region export visit
export const exportVisit = (state, { visitId }) => state.merge({
  busyExportingVisit: true,
  visitId,
  exportVisitError: null,
})
export const exportVisitSuccess = state => state.merge({
  busyExportingVisit: false,
  visitId: null,
})
export const exportVisitFailure = (state, { error }) => state.merge({
  busyExportingVisit: false,
  exportVisitError: error,
})
// endregion
// region export all visits
export const exportAllVisits = state => state.merge({
  busyExportingAllVisits: true,
  exportAllVisitsError: null,
})
export const exportAllVisitsSuccess = state => state.merge({ busyExportingAllVisits: false })
export const exportAllVisitsFailure = (state, { error }) => state.merge({
  busyExportingAllVisits: false,
  exportAllVisitsError: error,
})
// endregion
// region fetch visit templates
export const fetchVisitTemplates = state => state.merge({
  visitTemplates: [],
  busyFetchingVisitTemplates: true,
  fetchVisitTemplatesError: null,
})
export const fetchVisitTemplatesSuccess = (state, { visitTemplates }) => state.merge({
  visitTemplates,
  busyFetchingVisitTemplates: false,
})
export const fetchVisitTemplatesFailure = (state, { error }) => state.merge({
  busyFetchingVisitTemplates: false,
  fetchVisitTemplatesError: error,
})
export const resetFetchVisitTemplatesError = state => state.merge({ fetchVisitTemplatesError: null })
// endregion
// region fetch visit forms
export const fetchVisitForms = state => state.merge({
  visitForms: [],
  busyFetchingVisitForms: true,
  fetchVisitFormsError: null,
})
export const fetchVisitFormsSuccess = (state, { visitForms }) => state.merge({
  visitForms,
  busyFetchingVisitForms: false,
})
export const fetchVisitFormsFailure = (state, { error }) => state.merge({
  busyFetchingVisitForms: false,
  fetchVisitFormsError: error,
})
export const resetFetchVisitFormsError = state => state.merge({ fetchVisitFormsError: null })
// endregion
// region fast track visit
export const fastTrackVisit = state => state.merge({
  busyFastTrackingVisit: true,
  fastTrackVisitError: null,
})
export const fastTrackVisitSuccess = state => state.merge({ busyFastTrackingVisit: false })
export const fastTrackVisitFailure = (state, { error }) => state.merge({
  busyFastTrackingVisit: false,
  fastTrackVisitError: error,
})
export const resetFastTrackVisitError = state => state.merge({ fastTrackVisitError: null })
// endregion
// region cancel fast track
export const cancelFastTrack = state => state.merge({
  busyCancelingFastTrack: true,
  cancelFastTrackError: null,
})
export const cancelFastTrackSuccess = state => state.merge({ busyCancelingFastTrack: false })
export const cancelFastTrackFailure = (state, { error }) => state.merge({
  busyCancelingFastTrack: false,
  cancelFastTrackError: error,
})
export const resetCancelFastTrackError = state => state.merge({ cancelFastTrackError: null })
// endregion
// toggle virtual visit
export const toggleVirtualVisit = state => state.merge({
  busyTogglingVirtualVisit: true,
  toggleVirtualVisitError: null,
})
export const toggleVirtualVisitSuccess = (state, { visit }) => state.merge({
  visitList: [...state.visitList.map(item => (item.id === visit.id ? visit : item))],
  shouldSubscribeToNotifications: visit.isVirtualVisit,
  busyTogglingVirtualVisit: false,
})
export const toggleVirtualVisitFailure = (state, { error }) => state.merge({
  busyTogglingVirtualVisit: false,
  toggleVirtualVisitError: error,
})
export const resetToggleVirtualVisitError = state => state.merge({ toggleVirtualVisitError: null })
export const resetShouldSubscribeToNotifications = state => state.merge({ shouldSubscribeToNotifications: false })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // region fetch visits
  [Types.FETCH_VISITS]: fetchVisits,
  [Types.FETCH_VISITS_SUCCESS]: fetchVisitsSuccess,
  [Types.FETCH_VISITS_FAILURE]: fetchVisitsFailure,
  // endregion
  // region add unscheduled visit (4me)
  [Types.ADD_UNSCHEDULED_VISIT]: addUnscheduledVisit,
  [Types.ADD_UNSCHEDULED_VISIT_SUCCESS]: addUnscheduledVisitSuccess,
  [Types.ADD_UNSCHEDULED_VISIT_FAILURE]: addUnscheduledVisitFailure,
  // endregion
  // region add unscheduled visit (engage)
  [Types.ADD_UNSCHEDULED_VISIT_FOR_ENGAGE]: addUnscheduledVisitForEngage,
  [Types.ADD_UNSCHEDULED_VISIT_FOR_ENGAGE_SUCCESS]: addUnscheduledVisitForEngageSuccess,
  [Types.ADD_UNSCHEDULED_VISIT_FOR_ENGAGE_FAILURE]: addUnscheduledVisitForEngageFailure,
  [Types.RESET_ADD_UNSCHEDULED_VISIT_FOR_ENGAGE_ERROR]: resetAddUnscheduledVisitForEngageError,
  // endregion
  // region change visit date
  [Types.CHANGE_VISIT_DATE]: changeVisitDate,
  [Types.CHANGE_VISIT_DATE_SUCCESS]: changeVisitDateSuccess,
  [Types.CHANGE_VISIT_DATE_FAILURE]: changeVisitDateFailure,
  // endregion
  // region automatic rescheduling
  [Types.RESCHEDULE_VISITS]: rescheduleVisits,
  [Types.RESCHEDULE_VISITS_SUCCESS]: rescheduleVisitsSuccess,
  [Types.RESCHEDULE_VISITS_FAILURE]: rescheduleVisitsFailure,
  [Types.RESET_RESCHEDULE_VISITS_ERROR]: resetRescheduleVisitsError,
  // endregion
  // region cancel visit
  [Types.CANCEL_VISIT]: cancelVisit,
  [Types.CANCEL_VISIT_SUCCESS]: cancelVisitSuccess,
  [Types.CANCEL_VISIT_FAILURE]: cancelVisitFailure,
  [Types.RESET_CANCEL_VISIT_ERROR]: resetCancelVisitError,
  // endregion
  // region export visit
  [Types.EXPORT_VISIT]: exportVisit,
  [Types.EXPORT_VISIT_SUCCESS]: exportVisitSuccess,
  [Types.EXPORT_VISIT_FAILURE]: exportVisitFailure,
  // endregion
  // region export all visits
  [Types.EXPORT_ALL_VISITS]: exportAllVisits,
  [Types.EXPORT_ALL_VISITS_SUCCESS]: exportAllVisitsSuccess,
  [Types.EXPORT_ALL_VISITS_FAILURE]: exportAllVisitsFailure,
  // endregion
  // region fetch visit templates
  [Types.FETCH_VISIT_TEMPLATES]: fetchVisitTemplates,
  [Types.FETCH_VISIT_TEMPLATES_SUCCESS]: fetchVisitTemplatesSuccess,
  [Types.FETCH_VISIT_TEMPLATES_FAILURE]: fetchVisitTemplatesFailure,
  [Types.RESET_FETCH_VISIT_TEMPLATES_ERROR]: resetFetchVisitTemplatesError,
  // endregion
  // region fetch visit forms
  [Types.FETCH_VISIT_FORMS]: fetchVisitForms,
  [Types.FETCH_VISIT_FORMS_SUCCESS]: fetchVisitFormsSuccess,
  [Types.FETCH_VISIT_FORMS_FAILURE]: fetchVisitFormsFailure,
  [Types.RESET_FETCH_VISIT_FORMS_ERROR]: resetFetchVisitFormsError,
  // endregion
  // region fast track visit
  [Types.FAST_TRACK_VISIT]: fastTrackVisit,
  [Types.FAST_TRACK_VISIT_SUCCESS]: fastTrackVisitSuccess,
  [Types.FAST_TRACK_VISIT_FAILURE]: fastTrackVisitFailure,
  [Types.RESET_FAST_TRACK_VISIT_ERROR]: resetFastTrackVisitError,
  // endregion
  // region cancel fast track
  [Types.CANCEL_FAST_TRACK]: cancelFastTrack,
  [Types.CANCEL_FAST_TRACK_SUCCESS]: cancelFastTrackSuccess,
  [Types.CANCEL_FAST_TRACK_FAILURE]: cancelFastTrackFailure,
  [Types.RESET_CANCEL_FAST_TRACK_ERROR]: resetCancelFastTrackError,
  // toggle virtual visit
  [Types.TOGGLE_VIRTUAL_VISIT]: toggleVirtualVisit,
  [Types.TOGGLE_VIRTUAL_VISIT_SUCCESS]: toggleVirtualVisitSuccess,
  [Types.TOGGLE_VIRTUAL_VISIT_FAILURE]: toggleVirtualVisitFailure,
  [Types.RESET_TOGGLE_VIRTUAL_VISIT_ERROR]: resetToggleVirtualVisitError,
  [Types.RESET_SHOULD_SUBSCRIBE_TO_NOTIFICATIONS]: resetShouldSubscribeToNotifications,
})

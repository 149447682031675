import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { is, isEmpty, isNil } from 'ramda'

import { ICON_ARROW_DOWN, ICON_ARROW_RIGHT } from '../../config/constants'

import { frontendFormat, frontendDateFormat } from '../../utils/MomentHelper'

import Icon from '../Icon'

class PatientEventTableRowItem extends Component {
  constructor(props) {
    super(props)

    this.state = { showSubItems: false }
  }

  render() {
    const { title, eventData, toggleHeaders } = this.props
    const { showSubItems } = this.state
    return (
      <tbody>
        <tr
          className="o-table__item--collapse"
          onClick={ this._toggleShowSubItems(showSubItems, toggleHeaders) }>
          <td colSpan={ 2 }>
            <Icon
              name={ showSubItems ? ICON_ARROW_DOWN : ICON_ARROW_RIGHT }
              size={ 20 } />
            <span className="o-table__title">
              All
              { ' ' }
              <strong>
                { title }
              </strong>
              { ' ' }
              Data
            </span>
          </td>
          <td colSpan={ 3 } />
        </tr>
        { showSubItems && this._renderSubItems(eventData) }
      </tbody>
    )
  }

  _toggleShowSubItems = (subItemsAreBeingShown, toggleHeaders) => () => {
    this.setState({ showSubItems: !subItemsAreBeingShown })
    toggleHeaders(!subItemsAreBeingShown)
  }

  _renderSubItems = (eventData) => {
    if (eventData) {
      // loop through items
      return eventData.map(event => event.registrations.map(registration => (
        <tr
          key={ registration.id }
          className="o-table__event">
          <td />
          <td />
          <td className="events-cell__registeredfor">
            { event.isScheduled
              ? moment.parseZone(registration.registeredFor).format(frontendDateFormat)
              : moment.parseZone(registration.registeredFor).format(frontendFormat)
            }
          </td>
          <td className="events-cell__registeredon">
            { moment.parseZone(registration.registeredOn).format(frontendFormat) }
          </td>
          <td>
            {
          registration.dataSets.map((dataSet, dataSetindex) => {
            // get labels for the static points of a registration, we only show this on the first line if there are multiple datasets under one registration
            let staticPoints = null
            if (dataSetindex === 0) {
              staticPoints = registration.staticPoints.map((staticPoint) => {
                const dateValueWithFallback = this._getDateOrValue(staticPoint)
                return { title: `${!isNil(staticPoint.title) ? staticPoint.title : ''}: ${!isNil(dateValueWithFallback) ? dateValueWithFallback : ''}`, id: staticPoint.id }
              })
            }

            // get labels for the points of a dataset
            const points = dataSet.dataPoints.map((point) => {
              const dateValueWithFallback = this._getDateOrValue(point)
              return { title: `${!isNil(point.title) ? point.title : ''}: ${!isNil(dateValueWithFallback) ? dateValueWithFallback : ''}`, id: point.id }
            })

            // get the static points and points of a dataset
            return (
              <div key={ dataSet.id } className="container-events__details">
                { is(Array, staticPoints) && !isEmpty(staticPoints) && this._renderType(staticPoints) }
                { is(Array, points) && !isEmpty(points) && this._renderDetails(points) }
              </div>
            )
          })
          }
          </td>
        </tr>
      )))
    }
    return null
  }

  _getDateOrValue = (point) => {
    const valueAsDate = moment.parseZone(point.value, moment.ISO_8601, true)
    const valueIsDate = valueAsDate.isValid()
    if (valueIsDate && point.type === 1) {
      return valueAsDate.format(frontendFormat)
    }
    if (valueIsDate && point.type === 4) {
      return valueAsDate.format(frontendDateFormat)
    }
    return point.value
  }

  _renderType = staticPoints => (
    <div className="wrapper-events-cell__type">
      { staticPoints.map(staticPoint => (
        <span key={ staticPoint.id } className="events-cell__type">
          { staticPoint.title }
          <br />
        </span>
      )) }
    </div>
  )

  _renderDetails = points => (
    <div className="wrapper-events-cell__details">
      { points.map(point => (
        <span key={ point.id } className="events-cell__details">
          { point.title }
          <br />
        </span>))}
    </div>
  )
}

PatientEventTableRowItem.propTypes = {
  title: PropTypes.string.isRequired,
  eventData: PropTypes.array,
  toggleHeaders: PropTypes.func.isRequired,
}

PatientEventTableRowItem.defaultProps = { eventData: [] }

export default PatientEventTableRowItem

import { call, put } from 'redux-saga/effects'
import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'
import TimeTravelActions from '../redux/TimeTravelRedux'

// eslint-disable-next-line import/prefer-default-export
export function* fetchServerTime(api) {
  const { ok, data } = yield call(api.get, queries.Clock())
  if (ok) {
    yield put(TimeTravelActions.fetchServerTimeSuccess(data.currentDateTime))
  } else {
    const errorObject = addTitleToErrorObject(data, `Fetching server time failed`)
    yield put(TimeTravelActions.fetchServerTimeFailure(errorObject))
  }
}

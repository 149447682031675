export default function reloadRoute(previousKey, nextKey, callback) {
  if (previousKey !== nextKey) {
    try {
      callback()
    } catch (error) {
      if (console) {
        console.warn('Could not execute callback provided to ReloadRoute', JSON.stringify(callback)) // eslint-disable-line no-console
      }
    }
  }
}

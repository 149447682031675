import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const TabBarItem = (props) => {
  const { children, itemClass } = props
  return (
    <div className={ cc([itemClass, 'tabbar__item']) }>
      { children }
    </div>
  )
}

TabBarItem.propTypes = {
  children: PropTypes.node.isRequired,
  itemClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

TabBarItem.defaultProps = { itemClass: [] }

export default TabBarItem

import React from 'react'
import PropTypes from 'prop-types'

const PatientTable = (props) => {
  const { headers, children } = props

  const columnTitles = headers && headers.length > 0 && headers.map(({ label, id, ...rest }) => (
    <th
      key={ id }
      { ...rest }>
      { label }
    </th>
  ))

  return (
    <div className="o-table">
      <table>
        <thead>
          <tr>
            { columnTitles }
          </tr>
        </thead>
        { children }
      </table>
    </div>
  )
}

PatientTable.propTypes = {
  headers: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default PatientTable

import React, { useState, useEffect } from 'react'
import Rodal from 'rodal'
import { isNilOrEmpty } from 'ramdasauce'
import PropTypes from 'prop-types'
import cc from 'classcat'

import Icon from '../Icon'
import { ICON_CALENDAR_ALT } from '../../config/constants'

function NotificationModal(props) {
  const { title, children, forceInteraction, header, icon, handleClose, virtualVisitId, className, showModal } = props
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!showModal) {
      setVisible(false)
    }
  }, [showModal])

  const _renderIcon = () => (
    <div className="notification-icon">
      <div className="notification-icon--background">
        <Icon
          name={ ICON_CALENDAR_ALT }
          size={ 20 }
        />
      </div>
    </div>
  )

  return (
    <Rodal
      visible={ visible }
      onClose={ () => handleClose(virtualVisitId) }
      closeMaskOnClick={ !forceInteraction }
      showCloseButton={ !forceInteraction }
      duration={ !showModal ? 0 : 300 }
      className={ cc(["notification-modal-container", className]) }>
      { !isNilOrEmpty(icon) && _renderIcon() }
      <div className="notification-modal-title">
        <div>
          <strong>
            { header }
          </strong>
        </div>
        { title }
      </div>
      <div className="notification-modal-content">
        { children }
      </div>
    </Rodal>
  )
}

NotificationModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  forceInteraction: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  virtualVisitId: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  showModal: PropTypes.bool,
}
NotificationModal.defaultProps = {
  title: '',
  icon: null,
  className: "",
  virtualVisitId: "",
  header: '',
  forceInteraction: false,
  showModal: true,
}

export default NotificationModal

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchServerTime: null,
  fetchServerTimeSuccess: ['serverTime'],
  fetchServerTimeFailure: ['error'],
})

export const TimeTravelTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  serverTime: null,
  fetchServerTimerError: null,
})

/* ------------- Reducers ------------- */

export const fetchServerTime = state => state.merge({
  serverTime: null,
  fetchServerTimerError: null,
})
export const fetchServerTimeSuccess = (state, { serverTime }) => state.merge({ serverTime })

export const fetchServerTimeFailure = (state, { error }) => state.merge({ fetchServerTimerError: error })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_SERVER_TIME]: fetchServerTime,
  [Types.FETCH_SERVER_TIME_SUCCESS]: fetchServerTimeSuccess,
  [Types.FETCH_SERVER_TIME_FAILURE]: fetchServerTimeFailure,
})

import { call, put } from 'redux-saga/effects'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

import EventActions from '../redux/EventsRedux'
import { extractEventsFromResponse } from '../utils/DataHelper'

// eslint-disable-next-line import/prefer-default-export
export function* fetchEvents(api, action) {
  const { studyId, patientId, visitId } = action
  const { ok, data } = yield call(api.get, queries.Events(studyId, patientId, visitId))
  const embeddedData = extractEventsFromResponse(data)
  const dummyVisit = data && data.dummyVisit
  if (ok) {
    yield put(EventActions.fetchEventsSuccess(embeddedData, dummyVisit))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading events failed`)
    yield put(EventActions.fetchEventsFailure(errorObject))
  }
}

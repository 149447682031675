import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchContext: ['isStudyAgnostic', 'navigateHome'],
  fetchContextSuccess: ['context'],
  fetchContextFailure: ['error'],
})

export const ContextTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  fetchingContext: false,
  context: null,
  fetchContextError: null,
})

/* ------------- Reducers ------------- */

export const fetchContext = state => state.merge({
  fetchingContext: true,
  fetchContextError: null,
})
export const fetchContextSuccess = (state, { context }) => state.merge({
  fetchingContext: false,
  context,
})
export const fetchContextFailure = (state, { error }) => state.merge({
  fetchingContext: false,
  fetchContextError: error,
})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CONTEXT]: fetchContext,
  [Types.FETCH_CONTEXT_SUCCESS]: fetchContextSuccess,
  [Types.FETCH_CONTEXT_FAILURE]: fetchContextFailure,
})

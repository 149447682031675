import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { isNilOrEmpty } from 'ramdasauce'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'

import ConfirmationButtons from '../buttons/ConfirmationButtons'
import MainButton from '../buttons/MainButton'
import MainButtonGroup from '../buttons/MainButtonGroup'

class ChangeLanguageForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      languageOptions: null,
      selectedPrimaryLanguage: null,
      previousPrimaryLanguage: null,
      showSecondConfirmationForm: false,
    }
  }

  componentDidMount() {
    const { primaryLanguage, possibleLanguages } = this.props
    const languageOptions = possibleLanguages.map(language => ({
      value: language.isoCode,
      label: language.name,
    }))
    // match primaryLanguage of patient to possible options of dropdown select to remove from the possible options and to make a label for the current language available
    const matchedPrimaryLanguage = languageOptions.find(lang => lang.value === primaryLanguage)
    // remove language from possible options
    const languageOptionsWithoutCurrent = languageOptions.filter(lang => lang.value !== primaryLanguage)
    this.setState(() => ({
      previousPrimaryLanguage: matchedPrimaryLanguage,
      languageOptions: languageOptionsWithoutCurrent,
    }))
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { selectedPrimaryLanguage, showSecondConfirmationForm, languageOptions, previousPrimaryLanguage } = this.state

    const canSwitchLanguage = languageOptions && languageOptions.length > 0

    return (
      <div>
        { error && this._renderError(error) }
        { !canSwitchLanguage && this._renderCantSwitchLanguageForm(handleCanceled) }
        { canSwitchLanguage && !showSecondConfirmationForm && this._renderLanguageInputForm(previousPrimaryLanguage, languageOptions, selectedPrimaryLanguage, handleCanceled) }
        { canSwitchLanguage && showSecondConfirmationForm && this._renderSecondConfirmationForm(previousPrimaryLanguage, selectedPrimaryLanguage, handleCanceled, handleConfirmed, loading) }
      </div>
    )
  }

  _renderCantSwitchLanguageForm(handleCanceled) {
    return (
      <div>
        <HintLabel
          size={ 16 }
          hintClass="u-margin--top">
          No other languages to switch to.
        </HintLabel>
        <MainButtonGroup>
          <MainButton
            label="CLOSE"
            buttonClass="blue"
            handleClick={ this._onCancel(handleCanceled) }
            id="button-close" />
        </MainButtonGroup>
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _renderLanguageInputForm(previousPrimaryLanguage, possibleLanguages, selectedPrimaryLanguage, handleCanceled) {
    return (
      <div>
        { this._renderPreviousLanguage(previousPrimaryLanguage) }
        <Select
          className="portal-select-input"
          name="primary-language-select"
          options={ possibleLanguages }
          placeholder="Select primary language"
          value={ selectedPrimaryLanguage }
          onChange={ this._selectedPrimaryLanguageChanged } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onFirstConfirm }
          confirmDisabled={ isNilOrEmpty(selectedPrimaryLanguage) } />
      </div>
    )
  }

  _renderPreviousLanguage = previousPrimaryLanguage => (
    <HintLabel size={ 16 }>
      Current primary language:
      { ' ' }
      <strong>
        { previousPrimaryLanguage && previousPrimaryLanguage.label }
      </strong>
    </HintLabel>
  )

  _renderSecondConfirmationForm(matchedPrimaryLanguage, selectedPrimaryLanguage, handleCanceled, handleConfirmed, loading) {
    return (
      <div>
        <HintLabel size={ 16 }>
          Are you sure you want to change from
          { ' ' }
          <strong>
            { matchedPrimaryLanguage.label }
          </strong>
          { ' ' }
          to
          { ' ' }
          <strong>
            { selectedPrimaryLanguage.label }
          </strong>
          .
        </HintLabel>
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onFinalConfirm(handleConfirmed, selectedPrimaryLanguage) }
          cancelDisabled={ loading }
          confirmDisabled={ isNilOrEmpty(selectedPrimaryLanguage) || loading } />
      </div>
    )
  }

  _selectedPrimaryLanguageChanged = (selectedPrimaryLanguage) => {
    this.setState({ selectedPrimaryLanguage })
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onFirstConfirm = () => {
    this.setState({ showSecondConfirmationForm: true })
  }

  _onFinalConfirm = (callback, selectedPrimaryLanguage) => () => {
    try {
      callback(selectedPrimaryLanguage.value)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

ChangeLanguageForm.propTypes = {
  primaryLanguage: PropTypes.string.isRequired,
  possibleLanguages: PropTypes.array.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

ChangeLanguageForm.defaultProps = { error: null }

export default ChangeLanguageForm

import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import ApiService from '../services/ApiService'

/* ------------- Types ------------- */

import { ContextTypes } from '../redux/ContextRedux'
import { PatientTypes } from '../redux/PatientRedux'
import { StartupTypes } from '../redux/StartupRedux'
import { VisitTypes } from '../redux/VisitRedux'
import { EventsTypes } from '../redux/EventsRedux'
import { PdiTypes } from '../redux/PdiRedux'
import { DailyTaskTypes } from '../redux/DailyTasksRedux'
import { QuestionnairesTypes } from '../redux/QuestionnairesRedux'
import { SetTypes } from '../redux/SetRedux'


/* ------------- Sagas ------------- */

import { startup } from './StartupSaga'
import {
  fetchPatient, fetchPatients, addPatient, changePatientId, changeDevice, changeLanguage,
  fetchAvailablePatientIds, saveScreeningEndDate, saveBaselineStartDate, requestResetCode,
} from './PatientSaga'
import { fetchContext } from './ContextSaga'
import {
  fetchVisits, addUnscheduledVisit, addUnscheduledVisitForEngage, changeVisitDate,
  rescheduleVisits, cancelVisit, exportVisit, exportAllVisits, fetchVisitTemplates,
  fetchVisitForms, fastTrackVisit, cancelFastTrack, toggleVirtualVisit,
} from './VisitSaga'
import { fetchDailyTasks } from './DailyTasksSaga'
import { fetchEvents } from './EventsSaga'
import { fetchQuestionnaires } from './QuestionnairesSaga'
import { fetchAllPdis, togglePdi, exportAllPdrs } from './PdiSaga'
import { extraLogicToSetStudy, extraLogicToSetSite } from './SetSaga'
import { fetchServerTime } from './TimeTravelSaga'
import { TimeTravelTypes } from '../redux/TimeTravelRedux'

/* ------------- Connect Types To Sagas ------------- */

export default (async () => {
  const apiService = await ApiService
  return function* root() {
    yield all([
      takeEvery(StartupTypes.STARTUP, startup),

      /* Context */
      takeLatest(ContextTypes.FETCH_CONTEXT, fetchContext, apiService),

      /* Patients */
      takeLatest(PatientTypes.FETCH_PATIENTS, fetchPatients, apiService),
      takeLatest(PatientTypes.FETCH_PATIENT, fetchPatient, apiService),
      takeLatest(PatientTypes.FETCH_AVAILABLE_PATIENT_IDS, fetchAvailablePatientIds, apiService),
      takeLatest(PatientTypes.ADD_PATIENT, addPatient, apiService),
      takeLatest(PatientTypes.CHANGE_PATIENT_ID, changePatientId, apiService),
      takeLatest(PatientTypes.CHANGE_DEVICE, changeDevice, apiService),
      takeLatest(PatientTypes.CHANGE_LANGUAGE, changeLanguage, apiService),
      takeLatest(PatientTypes.SAVE_SCREENING_END_DATE, saveScreeningEndDate, apiService),
      takeLatest(PatientTypes.SAVE_BASELINE_START_DATE, saveBaselineStartDate, apiService),
      takeLatest(PatientTypes.REQUEST_RESET_CODE, requestResetCode, apiService),

      /* Visits */
      takeLatest(VisitTypes.FETCH_VISITS, fetchVisits, apiService),
      takeLatest(VisitTypes.ADD_UNSCHEDULED_VISIT, addUnscheduledVisit, apiService),
      takeLatest(VisitTypes.CHANGE_VISIT_DATE, changeVisitDate, apiService),
      takeLatest(VisitTypes.RESCHEDULE_VISITS, rescheduleVisits, apiService),
      takeLatest(VisitTypes.CANCEL_VISIT, cancelVisit, apiService),
      takeLatest(VisitTypes.EXPORT_VISIT, exportVisit, apiService),
      takeLatest(VisitTypes.EXPORT_ALL_VISITS, exportAllVisits, apiService),
      takeLatest(VisitTypes.ADD_UNSCHEDULED_VISIT_FOR_ENGAGE, addUnscheduledVisitForEngage, apiService),
      takeLatest(VisitTypes.FETCH_VISIT_TEMPLATES, fetchVisitTemplates, apiService),
      takeLatest(VisitTypes.FETCH_VISIT_FORMS, fetchVisitForms, apiService),
      takeLatest(VisitTypes.FAST_TRACK_VISIT, fastTrackVisit, apiService),
      takeLatest(VisitTypes.CANCEL_FAST_TRACK, cancelFastTrack, apiService),
      takeLatest(VisitTypes.TOGGLE_VIRTUAL_VISIT, toggleVirtualVisit, apiService),

      /* Events */
      takeLatest(EventsTypes.FETCH_EVENTS, fetchEvents, apiService),

      /* Questionnaires */
      takeLatest(QuestionnairesTypes.FETCH_QUESTIONNAIRES, fetchQuestionnaires, apiService),

      // All PDI's
      takeLatest(PdiTypes.FETCH_ALL_PDIS, fetchAllPdis, apiService),
      takeLatest(PdiTypes.TOGGLE_PDI, togglePdi, apiService),
      takeLatest(PdiTypes.EXPORT_ALL_PDRS, exportAllPdrs, apiService),

      // Daily Tasks
      takeLatest(DailyTaskTypes.FETCH_DAILY_TASKS, fetchDailyTasks, apiService),

      /* Set */
      takeLatest(SetTypes.SET_STUDY, extraLogicToSetStudy),
      takeLatest(SetTypes.SET_SITE, extraLogicToSetSite),

      // Time Travel
      takeLatest(TimeTravelTypes.FETCH_SERVER_TIME, fetchServerTime, apiService),

    ])
  }
})()

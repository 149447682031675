import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => {
  const { handleCheckboxChange, label, isChecked, id } = props

  return (
    <div className="main-checkbox">
      <label htmlFor={ id }>
        <input
          id={ id }
          type="checkbox"
          value={ label }
          checked={ isChecked }
          onChange={ handleCheckboxChange } />
        { label }
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}

Checkbox.defaultProps = { label: '' }

export default Checkbox

/* eslint-disable react/destructuring-assignment */
import React, { useState, Fragment, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import ReactSwitch from 'react-switch'
import { connect } from 'react-redux'
import { isNilOrEmpty } from 'ramdasauce'

import { isStudyArchived } from '../../utils/StudyHelper'
import BaseModal from '../modals/BaseModal'
import SignOffModal from '../modals/SignOffModal'
import PdiActions from '../../redux/PdiRedux'
import { TOGGLE_ON_COLOR, TOGGLE_OFF_COLOR } from '../../config/constants'

function SwitchPdi(props) {
  const {
    checked, reference, description, type, togglePdi, patientId, studyId,
    busyTogglingPdi, togglePdiError, resetTogglePdiError, study,
  } = props
  const [isChecked, setIsChecked] = useState(checked)
  const [showModal, setShowModal] = useState(false)

  const isArchived = !isNilOrEmpty(study) && isStudyArchived(study.workflow)

  useLayoutEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const _openModal = () => {
    setIsChecked(!isChecked)
    setShowModal(true)
  }

  const _closeModal = () => {
    setIsChecked(!isChecked)
    setShowModal(false)
    resetTogglePdiError()
  }

  const _confirmToggle = (values) => {
    const { userName, password, reason } = values
    togglePdi(studyId, patientId, reference, isChecked, type, userName, password, reason)
  }

  const _renderSignOffModal = () => (
    <BaseModal
      title={ `Do you confirm to ${isChecked ? 'activate' : "de-activate"} ${description}` }
      handleClose={ _closeModal }
      forceInteraction={ busyTogglingPdi }
    >
      <SignOffModal
        handleCanceled={ () => _closeModal() }
        handleConfirmed={ values => _confirmToggle(values) }
        loading={ busyTogglingPdi }
        error={ togglePdiError }
      />
    </BaseModal>
  )

  return (
    <>
      { showModal && _renderSignOffModal() }
      <ReactSwitch
        className="react-switch-pdi"
        checked={ isChecked }
        onChange={ () => _openModal() }
        checkedIcon={ false }
        uncheckedIcon={ false }
        offColor={ TOGGLE_OFF_COLOR }
        onColor={ TOGGLE_ON_COLOR }
        disabled={ isArchived }
        height={ 20 }
        width={ 40 }
      />
    </>
  )
} 

const mapDispatchToProps = dispatch => ({
  togglePdi: (studyId, patientId, reference, active, pdiType, userName, password, reason) => dispatch(PdiActions.togglePdi(studyId, patientId, reference, active, pdiType, userName, password, reason)),
  resetTogglePdiError: () => dispatch(PdiActions.resetTogglePdiError()),
})

const mapStateToProps = state => ({
  study: state.set.study,
  togglePdi: state.pdis.togglePdi,
  busyTogglingPdi: state.pdis.busyTogglingPdi,
  togglePdiError: state.pdis.togglePdiError,
  resetTogglePdiError: state.pdis.resetTogglePdiError,
})

SwitchPdi.propTypes = {
  study: PropTypes.object,
  checked: PropTypes.bool.isRequired,
  studyId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  togglePdi: PropTypes.func.isRequired,
  resetTogglePdiError: PropTypes.func.isRequired,
  busyTogglingPdi: PropTypes.bool,
  togglePdiError: PropTypes.object,
}

SwitchPdi.defaultProps = {
  togglePdiError: null,
  study: null,
  busyTogglingPdi: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchPdi)

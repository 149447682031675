import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { isNilOrEmpty } from 'ramdasauce'
import MainInput from '../inputs/MainInput'
import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'
import MainTextArea from '../inputs/MainTextArea'

class FastTrackVisitForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: window.sessionHandler.loggedInUser.userName,
      userPwd: '',
      reasonDescription: '',
    }
  }

  render() {
    const { userName, userPwd, reasonDescription } = this.state
    const { handleCanceled, handleConfirmed, loading, error, configMessage } = this.props
    return (
      <div>
        { error && this._renderError(error) }
        { this._renderContent(handleCanceled, handleConfirmed, loading, configMessage, userName, userPwd, reasonDescription) }
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error }
    />
  )

  _renderContent(handleCanceled, handleConfirmed, loading, configMessage, userName, userPwd, reasonDescription) {
    return (
      <div>
        <div className="u-margin--top">
          { configMessage }
        </div>
        <MainTextArea
          value={ reasonDescription }
          name="reasonDescription"
          maxLength={ 1024 }
          label="Describe the reason for change"
          textAreaStyle="data-clarification-form_textarea"
          onInputChanged={ this._handleReasonChange } />
        <MainInput
          value={ userName }
          inputClass="u-text--lowercased"
          label="Username"
          name="userName"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <MainInput
          value={ userPwd }
          inputClass="u-text--lowercased"
          label="Password"
          name="userPwd"
          inputType="password"
          onInputChanged={ this._handleInputChange }
          returnFullInputEvent={ true } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, userName, userPwd, reasonDescription) }
          confirmLabel="Confirm"
          cancelDisabled={ loading }
          confirmDisabled={ isNilOrEmpty(reasonDescription) || isNilOrEmpty(userName) || isNilOrEmpty(userPwd) || loading }
        />
      </div>
    )
  }

  _handleReasonChange = (newReason) => {
    this.setState({ reasonDescription: newReason })
  }

  _handleInputChange = (event) => {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, userName, userPwd, reasonDescription) => () => {
    const credentials = { userName, userPwd }
    try {
      callback(credentials, reasonDescription)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

FastTrackVisitForm.defaultProps = { error: null }

FastTrackVisitForm.propTypes = {
  handleConfirmed: PropTypes.func.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  configMessage: PropTypes.string.isRequired,
  error: PropTypes.object,
}

export default FastTrackVisitForm

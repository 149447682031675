import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

import { ICON_CHECK_MARK } from '../../config/constants'
import Icon from '../Icon'
import { frontendDateFormat } from '../../utils/MomentHelper'

function PatientDailyTasksTable(props) {
  const { dailyTasks, currentSiteDate } = props

  return (
    <div className="o-table daily-tasks-table">
      <table>
        <thead>
          <tr>
            <th>
              Daily Tasks (
              { moment(currentSiteDate).format(frontendDateFormat) }
              )
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              Type
            </th>
            <th>
              Description
            </th>
            <th />
          </tr>
          { dailyTasks.map(dailyTask => (
            <tr key={ nanoid() }>
              <td>
                { dailyTask.type }
              </td>
              <td>
                { dailyTask.description }
              </td>
              <td>
                { dailyTask.isSynced
                && <Icon
                  name={ ICON_CHECK_MARK }
                  color="green" />
              }
              </td>
            </tr>))
          }
        </tbody>
      </table>
    </div>
  )
}

PatientDailyTasksTable.propTypes = {
  dailyTasks: PropTypes.array,
  currentSiteDate: PropTypes.string.isRequired,
}

PatientDailyTasksTable.defaultProps = { dailyTasks: [] }

export default PatientDailyTasksTable

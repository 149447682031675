import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'react-router-redux'
import { not, includes } from 'ramda'

import history from '../utils/HistoryHelper'


// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */
  // the logger master switch
  const USE_LOGGING = process.env.NODE_ENV === 'development'
  const middleware = []
  const enhancers = []

  /* ------------- Saga Middleware ------------- */
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  /* ------------- Router Middleware ------------- */
  const routersMiddleware = routerMiddleware(history)
  middleware.push(routersMiddleware)

  /* ------------- Logger Middleware ------------- */
  const SAGA_LOGGING_BLACKLIST = ['EFFECT_TRIGGERED', 'EFFECT_RESOLVED', 'EFFECT_REJECTED']
  // create the logger
  const logger = createLogger({ predicate: (getState, { type }) => USE_LOGGING && not(includes(type, SAGA_LOGGING_BLACKLIST)) })

  middleware.push(logger)

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(applyMiddleware(...middleware))

  const store = createStore(rootReducer, compose(...enhancers))

  // kick off the root saga
  sagaMiddleware.run(rootSaga)

  return store
}

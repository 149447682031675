import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes, useParams } from 'react-router-dom'

import ProfileBadge from './ProfileBadge'

import SidebarToggle from '../buttons/SidebarToggle'

export const StudyIdTitle = () => (` - ${useParams().study_id}`)

export const SiteIdTitle = () => {
  const params = useParams()

  return ` - ${params.study_id} (Site ${params.site_id})`
}

export const PatientIdTitle = () => {
  const params = useParams()

  return ` - ${params.study_id} (Site ${params.site_id}) (Patient ${params.patient_id})`
}

const NavbarHeader = (props) => {
  const { handleSidebarClick } = props

  return (
    <div className="navbar-header">
      <SidebarToggle handleClick={ handleSidebarClick } />
      <h1 className="navbar-header__title">
        <span>
          myUCB Insights
        </span>

        <Routes>
          <Route
            path="/study/:study_id/*"
            element={ <StudyIdTitle /> } />
          <Route
            path="/study/:study_id/site/:site_id/*"
            element={ <SiteIdTitle /> } />
          <Route
            path="/study/:study_id/site/:site_id/patient/:patient_id/*"
            element={ <PatientIdTitle /> } />
        </Routes>
      </h1>
      <ProfileBadge />
    </div>
  )
}

NavbarHeader.propTypes = { handleSidebarClick: PropTypes.func.isRequired }

export default NavbarHeader

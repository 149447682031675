import { put } from 'redux-saga/effects'
import ContextActions from '../redux/ContextRedux'

// process STARTUP actions
// eslint-disable-next-line import/prefer-default-export
export function* startup() {
  // put your startup actions here
  if (window.sessionHandler.loggedInUser) {
    // if user is definitely logged in, fetch the context
    yield put(ContextActions.fetchContext())
  }
}

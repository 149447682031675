import { call, put } from 'redux-saga/effects'
import fileDownload from 'js-file-download'

import { getNowInBackendFormat } from '../utils/MomentHelper'
import addTitleToErrorObject from '../utils/ErrorHelper'
import { extractDataFromResponse, extractFormsFromResponse, extractTemplatesFromResponse } from '../utils/DataHelper'

import queries from '../config/ApiConfig'

import VisitActions from '../redux/VisitRedux'
import SetActions from '../redux/SetRedux'

// exported for tests
export const selectVisits = state => state.visits.visitList
export const selectPatient = state => state.patients.patient

export function* fetchVisits(api, action) {
  const { studyId, patientId, includeDetails } = action
  const { ok, data } = yield call(api.get, queries.Visits(studyId, patientId), { includeDetails })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.fetchVisitsSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading visits for patient ${patientId} failed`)
    yield put(VisitActions.fetchVisitsFailure(errorObject))
  }
}

export function* addUnscheduledVisit(api, action) {
  const { studyId, patientId, serverTime } = action

  const { ok, data } = yield call(api.post, queries.Visits(studyId, patientId), {
    plannedOn: getNowInBackendFormat(serverTime),
    info: "", // TODO: Add unscheduled info?
  })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.addUnscheduledVisitSuccess(embeddedData))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding unscheduled visit failed`)
    yield put(VisitActions.addUnscheduledVisitFailure(errorObject))
  }
}

export function* changeVisitDate(api, action) {
  const { studyId, patientId, visitId, newDate } = action
  const { ok, data } = yield call(api.put, queries.Visit(studyId, patientId, visitId), { plannedOn: newDate })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.changeVisitDateSuccess(embeddedData))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Changing visit date failed`)
    yield put(VisitActions.changeVisitDateFailure(errorObject))
  }
}

export function* rescheduleVisits(api, action) {
  const { studyId, patientId, visitId, newDate } = action
  const { ok, data } = yield call(api.put, queries.Visit(studyId, patientId, visitId), {
    plannedOn: newDate,
    AutomaticReschedule: true,
  })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.rescheduleVisitsSuccess(embeddedData))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, "Rescheduling visits failed")
    yield put(VisitActions.rescheduleVisitsFailure(errorObject))
  }
}

export function* cancelVisit(api, action) {
  const { studyId, patientId, visitId, reason } = action
  const { ok, data } = yield call(api.post, queries.CancelVisit(studyId, patientId, visitId), { reason })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.cancelVisitSuccess(embeddedData))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Canceling visit failed`)
    yield put(VisitActions.cancelVisitFailure(errorObject))
  }
}

export function* exportVisit(api, action) {
  const { studyId, patientId, visitId, visitLabel } = action
  const { ok, data } = yield call(api.get, queries.ExportVisit(studyId, patientId), { visit: visitId }, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob',
  })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.exportVisitSuccess(embeddedData))
    fileDownload(embeddedData, `${studyId}_patient-${patientId}_${visitLabel}.pdf`)
  } else {
    const errorObject = addTitleToErrorObject(data, `Exporting visit data failed`)
    yield put(VisitActions.exportVisitFailure(errorObject))
  }
}

export function* exportAllVisits(api, action) {
  const { studyId, patientId } = action
  const { ok, data } = yield call(api.get, queries.ExportAllVisits(studyId, patientId), {}, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob',
  })
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.exportAllVisitsSuccess(embeddedData))
    fileDownload(embeddedData, `${studyId}_patient-${patientId}_visits.pdf`)
  } else {
    const errorObject = addTitleToErrorObject(data, `Exporting all visit data failed`)
    yield put(VisitActions.exportAllVisitsFailure(errorObject))
  }
}

export function* fetchVisitTemplates(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.VisitTemplates(studyId))
  const embeddedData = extractTemplatesFromResponse(data)
  if (ok) {
    yield put(VisitActions.fetchVisitTemplatesSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading visit templates for study ${studyId} failed`)
    yield put(VisitActions.fetchVisitTemplatesFailure(errorObject))
  }
}

export function* fetchVisitForms(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.VisitForms(studyId))
  const embeddedData = extractFormsFromResponse(data)
  if (ok) {
    yield put(VisitActions.fetchVisitFormsSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading visit forms for study ${studyId} failed`)
    yield put(VisitActions.fetchVisitFormsFailure(errorObject))
  }
}

export function* addUnscheduledVisitForEngage(api, action) {
  const { studyId, patientId, visitDetails, isPlannedVisit } = action
  const queryForAddingUnscheduledVisit = isPlannedVisit ? queries.AddUnscheduledVisitWithTemplate(studyId, patientId) : queries.AddUnscheduledVisitWithForms(studyId, patientId)
  let bodyForRequest = { plannedOn: getNowInBackendFormat() }
  if (isPlannedVisit) {
    bodyForRequest = { ...bodyForRequest, ...{ visitTemplate: visitDetails.reference } }
  } else {
    bodyForRequest = { ...bodyForRequest, ...{ forms: visitDetails.map(form => ({ id: form.reference })) } }
  }
  const { ok, data } = yield call(api.post, queryForAddingUnscheduledVisit, bodyForRequest)
  const embeddedData = extractDataFromResponse(data)
  if (ok) {
    yield put(VisitActions.addUnscheduledVisitForEngageSuccess(embeddedData))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding unscheduled visit failed`)
    yield put(VisitActions.addUnscheduledVisitForEngageFailure(errorObject))
  }
}

export function* fastTrackVisit(api, action) {
  const { studyId, patientId, credentials, reason } = action
  const { ok, data } = yield call(api.post, queries.FastTrackVisit(studyId, patientId), {
    userName: credentials.userName,
    password: credentials.userPwd,
    reason,
  })
  if (ok) {
    yield put(VisitActions.fastTrackVisitSuccess(data))
    yield put(VisitActions.fetchVisits(studyId, patientId, true))
  } else {
    // TODO: currently 'operation failed' to be a generic error title due to MG study and retro-compatibility on the fast track US. To be replaced by configurable label
    const errorObject = addTitleToErrorObject(data, 'Operation failed')
    yield put(VisitActions.fastTrackVisitFailure(errorObject))
  }
}

export function* cancelFastTrack(api, action) {
  const { studyId, patientId, credentials, cancelFastTrackFailedText } = action
  const { ok, data } = yield call(api.post, queries.CancelFastTrack(studyId, patientId), {
    userName: credentials.userName,
    password: credentials.userPwd,
  })
  if (ok) {
    yield put(VisitActions.cancelFastTrackSuccess(data))
    yield put((VisitActions.fetchVisits(studyId, patientId, true)))
  } else {
    const errorObject = addTitleToErrorObject(data, cancelFastTrackFailedText)
    yield put(VisitActions.cancelFastTrackFailure(errorObject))
  }
}

export function* toggleVirtualVisit(api, action) {
  const { studyId, patientId, visitId, isVirtualVisit, isUpcomingVisit } = action
  const { ok, data } = yield call(api.put, queries.ToggleVirtualVisit(studyId, patientId, visitId), { isVirtualVisit })
  if (ok) {
    yield put(VisitActions.toggleVirtualVisitSuccess(data))
    // update state site.patients
    if (isUpcomingVisit) {
      yield put(SetActions.toggleVirtualVisitForSitePatientsSuccess(patientId, isVirtualVisit, data.virtualVisitId))
    }
  } else {
    const errorObject = addTitleToErrorObject(data, `Configuring ${isVirtualVisit ? 'virtual' : 'on site'} visit failed`)
    yield put(VisitActions.toggleVirtualVisitFailure(errorObject))
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

function PatientPDIHeaderTableRow(props) {
  const { tableHeaders } = props
  return (
    <tr>
      { tableHeaders.map(header => (
        <th key={ nanoid() }>
          { header }
        </th>
      )) }
    </tr>
  )
}

PatientPDIHeaderTableRow.propTypes = { tableHeaders: PropTypes.array }

PatientPDIHeaderTableRow.defaultProps = { tableHeaders: [] }

export default PatientPDIHeaderTableRow

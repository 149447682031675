import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import StartupActions from '../redux/StartupRedux'

import RouterContainer from './RouterContainer'

export class RootContainer extends Component {
  componentDidMount() {
    const { startup } = this.props
    startup()
  }

  render() {
    return (
      <div>
        <Helmet
          defaultTitle="myUCB Insights"
          titleTemplate="%s - myUCB Insights">
          <meta charSet="utf-8" />
          <meta name="description" content="myUCB Insights" />
        </Helmet>
        <RouterContainer />
      </div>
    )
  }
}

RootContainer.propTypes = { startup: PropTypes.func.isRequired }

export const mapDispatchToProps = dispatch => ({ startup: () => dispatch(StartupActions.startup()) })

export default connect(null, mapDispatchToProps)(RootContainer)

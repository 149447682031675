import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const VisitFormsTable = (props) => {
  const { children, showOrder, hasCheckbox, negativeColor } = props

  const tableClasses = cc([
    "o-table",
    "visit-forms",
    { white: negativeColor },
    "u-margin--vertical",
  ])

  return (
    <div className={ tableClasses }>
      <table>
        <thead>
          <tr>
            { hasCheckbox && (
              <th className="small" />
            ) }
            { showOrder && (
              <th>
                #
              </th>
            ) }
            <th>
              Name
            </th>
            <th>
              Duration
            </th>
            <th>
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          { children }
        </tbody>
      </table>
    </div>
  )
}

VisitFormsTable.propTypes = {
  children: PropTypes.node.isRequired,
  showOrder: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  negativeColor: PropTypes.bool,
}

VisitFormsTable.defaultProps = {
  showOrder: false,
  hasCheckbox: false,
  negativeColor: false,
}

export default VisitFormsTable

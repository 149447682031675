import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import ConfirmationButtons from '../buttons/ConfirmationButtons'
import LoadingModal from './LoadingModal'

class RescheduleVisitsModal extends Component {
  constructor(props) {
    super(props)
    this.state = { showSecondModal: false }
  }

  render() {
    const { showSecondModal } = this.state
    const { loading, error, handleConfirmed, handleCanceled } = this.props
    return (
      <div>
        { !showSecondModal && this._renderRescheduleVisitForm(handleCanceled, handleConfirmed, !showSecondModal) }
        { showSecondModal && this._renderLoadingModal(loading, error, handleConfirmed, handleCanceled) }
      </div>
    )
  }

  _renderRescheduleVisitForm = (handleCanceled, handleConfirmed, firstModal) => (
    <div>
      Do you want to automatically recalculate all subsequent visits?
      <ConfirmationButtons
        onConfirm={ this._handleConfirmed(handleConfirmed) }
        onCancel={ this._handleCanceled(handleCanceled, firstModal) }
        cancelLabel="NO"
        confirmLabel="YES"
      />
    </div>
  )

  _handleCanceled = (callback, firstModal) => () => {
    try {
      callback(firstModal)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  _handleConfirmed = callback => () => {
    this.setState({ showSecondModal: true })
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  _renderLoadingModal = (loading, error, handleConfirmed, handleCanceled) => (
    <LoadingModal
      loading={ loading }
      loadingHint="Recalculating visits"
      error={ error }
      retryAction={ this._handleConfirmed(handleConfirmed) }
      closeLoadingModal={ this._handleCanceled(handleCanceled) }
    />
  )
}

RescheduleVisitsModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  handleConfirmed: PropTypes.func.isRequired,
  handleCanceled: PropTypes.func.isRequired,
}

RescheduleVisitsModal.defaultProps = { error: null }

export default RescheduleVisitsModal

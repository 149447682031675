import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import RootSaga from '../sagas'
import { reducer as SetReducer } from './SetRedux'
import { reducer as PatientReducer } from './PatientRedux'
import { reducer as ContextReducer } from './ContextRedux'
import { reducer as VisitReducer } from './VisitRedux'
import { reducer as EventsReducer } from './EventsRedux'
import { reducer as PdiReducer } from './PdiRedux'
import { reducer as QuestionnairesReducer } from './QuestionnairesRedux'
import { reducer as DailyTasksReducer } from './DailyTasksRedux'
import { reducer as TimeTravelReducer } from './TimeTravelRedux'

import configureStore from './CreateStore'

export default (async () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    set: SetReducer,
    patients: PatientReducer,
    context: ContextReducer,
    visits: VisitReducer,
    events: EventsReducer,
    pdis: PdiReducer,
    questionnaires: QuestionnairesReducer,
    dailyTasks: DailyTasksReducer,
    router: routerReducer,
    timeTravel: TimeTravelReducer,
  })
  /* ------------- Await root saga setup ------------- */
  const rootSaga = await RootSaga
  /* ------------- Configure Store ------------- */
  return configureStore(rootReducer, rootSaga)
})()

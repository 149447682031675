import React from 'react'
import logoWithText from "../../assets/images/ucb-logo-transparent.png"

const NavbarBrand = () => (
  <div className="navbar-brand">
    <img
      src={ logoWithText }
      className="navbar-brand__img"
      height="39"
      width="210"
      alt="UCB" />
  </div>
)

export default NavbarBrand

import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const HintLabel = (props) => {
  const { children, size, hintClass } = props
  return (
    <span
      className={ cc([hintClass, "o-hint-text"]) }
      style={ { fontSize: size } }>
      { children }
    </span>
  )
}

HintLabel.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node.isRequired,
  hintClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}
HintLabel.defaultProps = {
  size: 24,
  hintClass: [],
}

export default HintLabel

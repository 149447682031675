/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

import PatientStudyAssessmentTableRowItem from './PatientStudyAssessmentTableRowItem'

function PatientStudyAssessmentsTable(props) {
  const { studyAssessments } = props
  return (
    <div className="o-table">
      <table>
        <thead>
          <tr>
            <th>
              Study Assessments
            </th>
            <th />
          </tr>
        </thead>
        <PatientStudyAssessmentTableRowItem assessments={ studyAssessments } key={ nanoid() } />
      </table>
    </div>
  )
}

PatientStudyAssessmentsTable.propTypes = { studyAssessments: PropTypes.array }
PatientStudyAssessmentsTable.defaultProps = { studyAssessments: [] }

export default PatientStudyAssessmentsTable

import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const RadioButton = (props) => {
  const { children, name, value, checked, radioButtonClass, radioButtonContainerClass, handleRadioSelected } = props

  const radioButtonContainerClasses = cc([
    radioButtonContainerClass,
    "radio-button-container",
    "u-margin--all",
    {
      "inverted-blue": !checked,
      blue: checked,
    },
  ])

  return (
    <label
      htmlFor={ value }
      className={ radioButtonContainerClasses }>
      <input
        className={ cc([radioButtonClass, "radio-button"]) }
        id={ value }
        type="radio"
        name={ name }
        value={ value }
        checked={ checked }
        onChange={ handleRadioSelected } />
      { children }
    </label>
  )
}

RadioButton.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  radioButtonClass: PropTypes.string,
  radioButtonContainerClass: PropTypes.string,
  handleRadioSelected: PropTypes.func.isRequired,
}

RadioButton.defaultProps = {
  children: null,
  radioButtonClass: '',
  radioButtonContainerClass: '',
}

export default RadioButton

import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

import HintLabel from '../HintLabel'

const LoadingBox = (props) => {
  const { message, size, loadingClass } = props
  return (
    <HintLabel
      hintClass={ cc([loadingClass, 'loading-box-animated']) }
      size={ size }>
      { message }
    </HintLabel>
  )
}

LoadingBox.propTypes = {
  message: PropTypes.string.isRequired,
  size: PropTypes.number,
  loadingClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

LoadingBox.defaultProps = {
  loadingClass: [],
  size: 20,
}

export default LoadingBox

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { is, isNil, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'
import LoadingBox from '../loading/LoadingBox'

import MainInput from '../inputs/MainInput'

import ConfirmationButtons from '../buttons/ConfirmationButtons'
import MainButton from '../buttons/MainButton'
import MainButtonGroup from '../buttons/MainButtonGroup'

class CreatePatientForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputApplicationId: '',
      selectedPatientId: null,
      selectedPrimaryLanguage: null,
      patientIdOptions: null,
    }
  }

  componentDidMount() {
    const { availablePatientIds, possibleLanguages } = this.props
    // Create 'Select'-components options.
    this._setAvailablePatientIds(availablePatientIds)
    this._setLanguageOptions(possibleLanguages)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // AVAILABLE PATIENT IDS
    const { availablePatientIds, possibleLanguages } = this.props
    const nextAvailablePatientIds = nextProps.availablePatientIds
    const receivedNewAvailablePatientIds = availablePatientIds !== nextAvailablePatientIds
    if (receivedNewAvailablePatientIds) {
      this._setAvailablePatientIds(nextAvailablePatientIds)
    }
    // LANGUAGE OPTIONS
    const nextPossibleLanguages = nextProps.possibleLanguages
    const receivedNewLanguageOptions = possibleLanguages !== nextPossibleLanguages
    if (receivedNewLanguageOptions) {
      this._setLanguageOptions(nextPossibleLanguages)
    }
  }

  // eslint-disable-next-line react/sort-comp
  _setAvailablePatientIds = (patientIds) => {
    const patientIdOptions = patientIds.map(patient => ({
      value: patient.id,
      label: patient.id,
    }))

    const selectedPatientId = is(Array, patientIds) && equals(patientIds.length, 1) ? patientIdOptions[0] : null

    this.setState({
      patientIdOptions,
      selectedPatientId,
    })
  }

  // eslint-disable-next-line react/sort-comp
  _setLanguageOptions = (languages) => {
    const languageOptions = languages.map(language => ({
      value: language.isoCode,
      label: language.name,
    }))

    const preSelectedLanguage = is(Array, languages) && equals(languages.length, 1) ? languageOptions[0] : null

    this.setState({
      languageOptions,
      selectedPrimaryLanguage: preSelectedLanguage,
    })
  }

  render() {
    const { handleCanceled, handleConfirmed, errors, loading, isAPureEngageStudy } = this.props
    const { patientIdOptions, languageOptions } = this.state
    const { inputApplicationId, selectedPatientId, selectedPrimaryLanguage } = this.state

    const canCreateNewPatient = patientIdOptions && patientIdOptions.length > 0


    return (
      <div>
        { !isNil(errors.fetchAvailablePatientIdsError) && this._renderError(errors.fetchAvailablePatientIdsError) }
        { !isNil(errors.addPatientError) && this._renderError(errors.addPatientError) }
        { loading.busyFetchingAvailablePatientIds && this._renderLoadingAvailablePatientIds() }
        { !loading.busyFetchingAvailablePatientIds && isNil(errors.fetchAvailablePatientIdsError) && !canCreateNewPatient && this._renderCantCreateForm(handleCanceled) }
        { !loading.busyFetchingAvailablePatientIds && isNil(errors.fetchAvailablePatientIdsError) && canCreateNewPatient && this._renderCreateNewPatientForm(patientIdOptions, selectedPatientId, inputApplicationId, languageOptions, selectedPrimaryLanguage, handleCanceled, handleConfirmed, loading, isAPureEngageStudy) }
      </div>
    )
  }

  _renderLoadingAvailablePatientIds = () => (
    <LoadingBox
      size={ 16 }
      message="Loading available patient IDs" />
  )

  _renderCreateNewPatientForm(patientIdOptions, selectedPatientId, inputApplicationId, languageOptions, selectedPrimaryLanguage, handleCanceled, handleConfirmed, loading, isAPureEngageStudy) {
    return (
      <div>
        <Select
          className="portal-select-input u-margin--top"
          name="patient-select"
          options={ patientIdOptions }
          placeholder="Select patient ID"
          value={ selectedPatientId }
          onChange={ this._selectedPatientIdChanged }
          id="dropdown-select-patient" />
        { !isAPureEngageStudy && (
          <MainInput
            value={ inputApplicationId }
            inputClass="u-text--uppercased"
            label="Application ID"
            onInputChanged={ this._applicationIdChanged }
            minLength={ 6 }
            maxLength={ 6 }
            inputType="id"
            id="input-application-id" />
        ) }
        <Select
          className="portal-select-input u-margin--top"
          name="primary-language-select"
          options={ languageOptions }
          placeholder="Select primary language"
          value={ selectedPrimaryLanguage }
          onChange={ this._selectedPrimaryLanguageChanged }
          id="dropdown-languages" />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, {
            inputApplicationId,
            selectedPatientId,
            selectedPrimaryLanguage,
          }, isAPureEngageStudy) }
          confirmLabel={ isAPureEngageStudy ? "Add Patient" : "Generate Access Key" }
          cancelDisabled={ loading.busyAddingPatient }
          confirmDisabled={ (!isAPureEngageStudy && inputApplicationId.length !== 6)
            || isNilOrEmpty(selectedPatientId)
            || isNilOrEmpty(selectedPrimaryLanguage)
            || loading.busyAddingPatient } />
      </div>
    )
  }

  _renderCantCreateForm(handleCanceled) {
    return (
      <div>
        <HintLabel
          size={ 16 }
          hintClass="u-margin--top">
          No patient IDs available to create a patient.
        </HintLabel>
        <MainButtonGroup>
          <MainButton
            label="CLOSE"
            buttonClass="blue"
            handleClick={ this._onCancel(handleCanceled) }
            id="button-close" />
        </MainButtonGroup>
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _selectedPatientIdChanged = (selectedPatientId) => {
    this.setState({ selectedPatientId })
  }

  _selectedPrimaryLanguageChanged = (selectedPrimaryLanguage) => {
    this.setState({ selectedPrimaryLanguage })
  }

  _applicationIdChanged = (inputApplicationId) => {
    this.setState({ inputApplicationId })
  }

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (cb, patient, isAPureEngageStudy) => () => {
    const createdPatient = {
      patientId: patient.selectedPatientId && patient.selectedPatientId.value,
      primaryLanguage: patient.selectedPrimaryLanguage && patient.selectedPrimaryLanguage.value,
    }
    if (!isAPureEngageStudy) {
      createdPatient.applicationId = patient.inputApplicationId
    }
    try {
      cb(createdPatient)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

CreatePatientForm.propTypes = {
  availablePatientIds: PropTypes.array.isRequired,
  possibleLanguages: PropTypes.array.isRequired,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  errors: PropTypes.object,
  loading: PropTypes.object.isRequired,
  isAPureEngageStudy: PropTypes.bool,
}

CreatePatientForm.defaultProps = {
  errors: null,
  isAPureEngageStudy: false,
}

export default CreatePatientForm

import React from 'react'
import PropTypes from 'prop-types'

function PatientStudyAssessmentTableRowSubItem(props) {
  const { content } = props
  return (
    <tr>
      <td />
      <td>
        { content }
      </td>
    </tr>
  )
}

PatientStudyAssessmentTableRowSubItem.propTypes = { content: PropTypes.string.isRequired }

export default PatientStudyAssessmentTableRowSubItem

import React from 'react'
import Icon from '../Icon'
import { ICON_VIDEO } from '../../config/constants'

function VirtualLabel() {
  return (
    <div className="toggle-virtual-visit-label">
      <Icon
        name={ ICON_VIDEO }
        size={ 20 }
      />
      <div>
        Virtual
      </div>
    </div>
  )
}

export default VirtualLabel

import React from 'react'
import PropTypes from 'prop-types'

const SubContainer = (props) => {
  const { children } = props
  return (
    <div className="sub-container">
      { children }
    </div>
  )
}

SubContainer.propTypes = { children: PropTypes.node.isRequired }

export default SubContainer

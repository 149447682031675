import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { frontendFormat, frontendDateFormat } from '../../utils/MomentHelper'

import Icon from '../Icon'
import PatientQuestionnaireTableRowSubItem from './PatientQuestionnaireTableRowSubItem'

class PatientQuestionnaireTableRowItem extends Component {
  constructor(props) {
    super(props)

    this.state = { showSubItems: false }
  }

  render() {
    const { data } = this.props
    const { showSubItems } = this.state

    return (
      <tr>
        <td
          colSpan={ 4 }
          className="o-table__item--reset">
          <table>
            <tbody>
              <tr
                className="o-table__item--collapse"
                onClick={ this._toggleShowSubItems(showSubItems) }>
                <td
                  colSpan={ 2 }
                  width="25%">
                  <Icon
                    name={ (showSubItems) ? "arrow-down" : "arrow-right" }
                    size={ 20 } />
                  { `Data for ${moment.parseZone(data.timeStamp).format(frontendDateFormat)}` }
                </td>
                <td width="50%">
                  { /* { (data.status) ? <Icon name={(data.status === 'complete') ? 'check-circle' : 'danger'} color={(data.status === 'complete') ? 'limegreen' : 'red'} size={20} /> : null } */ }
                  { `Completed on ${moment.parseZone(data.registeredOn).format(frontendFormat)}` }
                </td>
                <td width="20%">
                  Score = N/A
                </td>
              </tr>
              { showSubItems && this._renderSubItems(data.questionResponses) }
            </tbody>
          </table>
        </td>
      </tr>
    )
  }

  _toggleShowSubItems = subItemsAreBeingShown => () => {
    this.setState({ showSubItems: !subItemsAreBeingShown })
  }

  _renderSubItems = (data) => {
    if (data) {
      return (
        <tr>
          <td
            colSpan={ 4 }
            className="o-table__item--indent">
            <table className="o-table--minify">
              <tbody>
                { data.map(subItem => (
                  <PatientQuestionnaireTableRowSubItem
                    data={ subItem }
                    key={ subItem.id } />
                )) }
              </tbody>
            </table>
          </td>
        </tr>
      )
    }
    return null
  }
}

// TODO Check prop type of object, sure it's not an array?
PatientQuestionnaireTableRowItem.propTypes = { data: PropTypes.object }
PatientQuestionnaireTableRowItem.defaultProps = { data: {} }

export default PatientQuestionnaireTableRowItem

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // setSite: ['studyId', 'site'],
  // setStudy: ['study'],
  setSite: ['studyId', 'site', 'isStudyAgnostic', 'navigateHome'],
  setStudy: ['study', 'isStudyAgnostic', 'navigateHome'],
  setStudies: ['studies'],
  addPatientToSitePatientsSuccess: ['activatedPatient'],
  changePatientFromSitePatientsSuccess: ['newPatientId', 'oldPatientId'],
  toggleVirtualVisitForSitePatientsSuccess: ['patientId', 'isVirtualVisit', 'virtualVisitId'],
  resetSite: null,
})

export const SetTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  site: null,
  study: null,
  studies: null,
})

/* ------------- Reducers ------------- */

export const setStudy = (state, { study }) => state.merge({
  study,
  site: null,
})
export const setStudies = (state, { studies }) => state.merge({ studies })
export const setSite = (state, { site }) => state.merge({ site })
export const addPatientToSitePatientsSuccess = (state, { activatedPatient }) => {
  const newSite = { ...state.site, patients: [...state.site.patients, { ...activatedPatient }] }
  const newStudy = { ...state.study, sites: state.study.sites.map(site => (site.id === state.site.id ? newSite : site)) }
  return state.merge({
    site: newSite,
    study: newStudy,
    studies: [...state.studies.map(study => (study.id === state.study.id ? newStudy : study))],
  })
}
export const changePatientFromSitePatientsSuccess = (state, { newPatientId, oldPatientId }) => {
  const newSite = { ...state.site, patients: state.site.patients.map(patient => (patient.id === oldPatientId ? { ...patient, id: newPatientId } : patient)) }
  const newStudy = { ...state.study, sites: state.study.sites.map(site => (site.id === state.site.id ? newSite : site)) }
  return state.merge({
    site: newSite,
    study: newStudy,
    studies: [...state.studies.map(study => (study.id === state.study.id ? newStudy : study))],
  })
}
export const toggleVirtualVisitForSitePatientsSuccess = (state, { patientId, isVirtualVisit, virtualVisitId }) => {
  const newSite = { ...state.site, patients: state.site.patients.map(patient => (patient.id === patientId ? { ...patient, upcomingVisitIsVirtual: isVirtualVisit, upcomingVisitVirtualId: virtualVisitId } : patient)) }
  const newStudy = { ...state.study, sites: state.study.sites.map(site => (site.id === state.site.id ? newSite : site)) }
  return state.merge({
    site: newSite,
    study: newStudy,
    studies: [...state.studies.map(study => (study.id === state.study.id ? newStudy : study))],
  })
}
export const resetSite = state => state.merge({ site: null })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SITE]: setSite,
  [Types.SET_STUDY]: setStudy,
  [Types.SET_STUDIES]: setStudies,
  [Types.ADD_PATIENT_TO_SITE_PATIENTS_SUCCESS]: addPatientToSitePatientsSuccess,
  [Types.CHANGE_PATIENT_FROM_SITE_PATIENTS_SUCCESS]: changePatientFromSitePatientsSuccess,
  [Types.TOGGLE_VIRTUAL_VISIT_FOR_SITE_PATIENTS_SUCCESS]: toggleVirtualVisitForSitePatientsSuccess,
  [Types.RESET_SITE]: resetSite,
})

import React from 'react'
import PropTypes from 'prop-types'

const NavbarContainer = (props) => {
  const { children } = props
  return (
    <div className="navbar-container">
      { children }
    </div>
  )
}

NavbarContainer.propTypes = { children: PropTypes.node.isRequired }

export default NavbarContainer

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LoadingHandler from '../../components/loading/LoadingHandler'

const LoadingContainer = (props) => {
  const { fetchingContext, busyFetchingPatient, busyFetchingPatients } = props
  if (fetchingContext || busyFetchingPatient || busyFetchingPatients) {
    const loadingArray = [
      {
        isLoading: fetchingContext,
        message: "Loading myUCB Insights",
      },
      {
        isLoading: busyFetchingPatients,
        message: "Loading patients",
      },
      {
        isLoading: busyFetchingPatient,
        message: "Loading patient",
      },
    ]
    return (
      <div className="loading-container">
        <LoadingHandler loading={ loadingArray } />
      </div>
    )
  }
  return null
}

LoadingContainer.propTypes = {
  fetchingContext: PropTypes.bool.isRequired,
  busyFetchingPatient: PropTypes.bool.isRequired,
  busyFetchingPatients: PropTypes.bool.isRequired,
}


const mapStateToProps = state => ({
  fetchingContext: state.context.fetchingContext,
  busyFetchingPatient: state.patients.busyFetchingPatient,
  busyFetchingPatients: state.patients.busyFetchingPatients,
})

export default connect(mapStateToProps, null)(LoadingContainer)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MainButton from '../buttons/MainButton'
import MainButtonGroup from '../buttons/MainButtonGroup'

class NewResetCodeModal extends Component {
  render() {
    const { handleConfirmed, resetCode } = this.props

    return (
      <div>
        <div className="reactivation-container">
          <div className="reactivation-row">
            <span className="reactivation-value pin">
              { resetCode }
            </span>
          </div>
        </div>
        <MainButtonGroup>
          <MainButton
            label="CLOSE"
            buttonClass="blue"
            handleClick={ this._onConfirm(handleConfirmed) }
            id="button-confirm" />
        </MainButtonGroup>
      </div>
    )
  }

  _onConfirm = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

NewResetCodeModal.propTypes = {
  resetCode: PropTypes.string.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
}

export default NewResetCodeModal

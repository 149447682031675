import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // PDI's
  fetchAllPdis: ['studyId', 'patientId'],
  fetchAllPdisSuccess: ['pdiList'],
  fetchAllPdisFailure: ['error'],

  togglePdi: ['studyId', 'patientId', 'reference', 'active', 'pdiType', 'userName', 'password', 'reason'],
  togglePdiSuccess: ['newPdi'],
  togglePdiFailure: ['error'],
  resetTogglePdiError: null,

  exportAllPdrs: ['studyId', 'siteId'],
  exportAllPdrsSuccess: null,
  exportAllPdrsFailure: ['error'],
})

export const PdiTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // PDI's
  pdiList: [],
  busyFetchingAllPdis: false,
  fetchAllPdisError: null,

  busyTogglingPdi: false,
  togglePdiError: null,

  busyExportingAllPdrs: false,
  exportAllPdrsError: null,
})

/* ------------- Reducers ------------- */

// fetchAllPdis
export const fetchAllPdis = state => state.merge({
  busyFetchingAllPdis: true,
  pdiList: [],
  fetchAllPdisError: null,
})
export const fetchAllPdisSuccess = (state, { pdiList }) => state.merge({
  busyFetchingAllPdis: false,
  pdiList,
})
export const fetchAllPdisFailure = (state, { error }) => state.merge({
  busyFetchingAllPdis: false,
  fetchAllPdisError: error,
})

// togglePdi
export const togglePdi = state => state.merge({
  busyTogglingPdi: true,
  togglePdiError: null,
})
export const togglePdiSuccess = (state, { newPdi }) => state.merge({
  pdiList: state.pdiList.map(pdi => (pdi.id === newPdi.id ? newPdi : pdi)),
  busyTogglingPdi: false,
})
export const togglePdiFailure = (state, { error }) => state.merge({
  busyTogglingPdi: false,
  togglePdiError: error,
})
export const resetTogglePdiError = state => state.merge({ togglePdiError: null })

// export all pdrs
export const exportAllPdrs = state => state.merge({
  busyExportingAllPdrs: true,
  exportAllPdrsError: null,
})
export const exportAllPdrsSuccess = state => state.merge({ busyExportingAllPdrs: false })
export const exportAllPdrsFailure = (state, { error }) => state.merge({
  busyExportingAllPdrs: false,
  exportAllPdrsError: error,
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ALL_PDIS]: fetchAllPdis,
  [Types.FETCH_ALL_PDIS_SUCCESS]: fetchAllPdisSuccess,
  [Types.FETCH_ALL_PDIS_FAILURE]: fetchAllPdisFailure,
  [Types.TOGGLE_PDI]: togglePdi,
  [Types.TOGGLE_PDI_SUCCESS]: togglePdiSuccess,
  [Types.TOGGLE_PDI_FAILURE]: togglePdiFailure,
  [Types.RESET_TOGGLE_PDI_ERROR]: resetTogglePdiError,
  [Types.EXPORT_ALL_PDRS]: exportAllPdrs,
  [Types.EXPORT_ALL_PDRS_SUCCESS]: exportAllPdrsSuccess,
  [Types.EXPORT_ALL_PDRS_FAILURE]: exportAllPdrsFailure,
})

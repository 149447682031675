import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'

import { isScreeningVisit, isVisitUnscheduled, isVisitSkipped, isVisitCanceled } from '../../utils/VisitHelper'
import PatientVisitTableItem from './PatientVisitTableItem'
import { isBeforeToday } from '../../utils/MomentHelper'

class PatientVisitsTable extends Component {
  render() {
    const {
      patientVisits, exportVisit, cancelVisit, changeVisitDate, patientHasScreeningPeriodEnabled, isAPureEngageStudy, upcomingVisit, busyChangingDatePickerDate,
      confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived, canSiteHaveVirtualVisits,
      showVirtualVisitLabel, tooltip, serverTime,
    } = this.props
    return (
      <div className="o-table visits" id="patient-visits-table">
        <table>
          <thead>
            <tr>
              <th>
                Visit
              </th>
              <th>
                Planned Visit Date
              </th>
              <th>
                Type
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            { this._renderVisitItems(patientVisits, exportVisit, cancelVisit, changeVisitDate, patientHasScreeningPeriodEnabled, isAPureEngageStudy, upcomingVisit,
              busyChangingDatePickerDate, confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived, canSiteHaveVirtualVisits,
              showVirtualVisitLabel, tooltip, serverTime) }
          </tbody>
        </table>
      </div>
    )
  }

  _renderVisitItems(visits, exportVisit, cancelVisit, changeVisitDate, patientHasScreeningPeriodEnabled, isAPureEngageStudy, upcomingVisit, busyChangingDatePickerDate,
    confirmToggleVirtualVisit, busyTogglingVirtualVisit, toggleVirtualVisitError, resetToggleVirtualVisitError, isStudyArchived, canSiteHaveVirtualVisits, showVirtualVisitLabel,
    tooltip, serverTime) {
    let numberOfUnscheduledVisits = 0
    const [firstVisit] = visits
    const lastVisit = !!visits && visits.length > 0 && visits[visits.length - 1]
    const visitDates = []
    let newUpcomingVisit = upcomingVisit && upcomingVisit.plannedOn ? upcomingVisit : visits.find(visit => equals(visit.id, upcomingVisit.id))
    return visits.map((visit, index) => {
      const visitIsUnscheduled = isVisitUnscheduled(visit)
      if (!isVisitSkipped(visit) && !isVisitCanceled(visit)) {
        visitDates.push(visit.plannedOn)
      }
      while ((equals(visit.id, newUpcomingVisit && newUpcomingVisit.id) && (visitIsUnscheduled || isBeforeToday(newUpcomingVisit && newUpcomingVisit.plannedOn, serverTime)))) {
        newUpcomingVisit = this._getNextVisit(visits, index)
      }
      if (visitIsUnscheduled) {
        numberOfUnscheduledVisits++
      }
      if (patientHasScreeningPeriodEnabled && isScreeningVisit(visit)) {
        return null
      }
      return (
        <PatientVisitTableItem
          key={ visit.id }
          visit={ visit }
          visitIsUnscheduled={ visitIsUnscheduled }
          visitDates={ visitDates }
          tooltip={ tooltip }
          previousVisit={ this._getPreviousVisit(visits, index) }
          nextVisit={ this._getNextVisit(visits, index) }
          changeVisitDate={ changeVisitDate(visit.id, visitIsUnscheduled) }
          exportVisit={ exportVisit(visit.id, visitIsUnscheduled ? `Unscheduled Visit ${numberOfUnscheduledVisits}` : `${visit.visit.title}`) }
          cancelVisit={ cancelVisit(visit.id) }
          firstVisit={ patientHasScreeningPeriodEnabled ? visits.find(baselineVisit => !isScreeningVisit(baselineVisit)) : firstVisit }
          lastVisit={ lastVisit }
          upcomingVisit={ newUpcomingVisit }
          busyChangingDatePickerDate={ busyChangingDatePickerDate }
          isAPureEngageStudy={ isAPureEngageStudy }
          confirmToggleVirtualVisit={ isVirtualVisit => confirmToggleVirtualVisit(visit.id, isVirtualVisit) }
          resetToggleVirtualVisitError={ resetToggleVirtualVisitError }
          busyTogglingVirtualVisit={ busyTogglingVirtualVisit }
          toggleVirtualVisitError={ toggleVirtualVisitError }
          isStudyArchived={ isStudyArchived }
          canSiteHaveVirtualVisits={ canSiteHaveVirtualVisits }
          showVirtualVisitLabel={ showVirtualVisitLabel }
          serverTime={ serverTime }
          />
      )
    })
  }

  _getPreviousVisit = (visits, index) => {
    let previousScheduledVisit = null
    let counter = index - 1
    while (counter >= 0) {
      if (this._isVisitCanceled(visits[counter].status) || this._isVisitSkipped(visits[counter].status)) {
        counter--
      } else {
        previousScheduledVisit = visits[counter]
        counter = -1 // reset counter to -1 to leave while loop
      }
    }
    return previousScheduledVisit
  }

  _getNextVisit = (visits, index) => {
    let nextScheduledVisit = null
    let counter = index + 1
    while (counter < visits.length) {
      if (this._isVisitCanceled(visits[counter].status) || this._isVisitSkipped(visits[counter].status)) {
        counter++
      } else {
        nextScheduledVisit = visits[counter]
        counter = visits.length
      }
    }
    return nextScheduledVisit
  }

  _isVisitCanceled = visitStatus => visitStatus === 2

  _isVisitSkipped = visitStatus => visitStatus === 1
}

PatientVisitsTable.propTypes = {
  patientVisits: PropTypes.array.isRequired,
  exportVisit: PropTypes.func.isRequired,
  cancelVisit: PropTypes.func.isRequired,
  changeVisitDate: PropTypes.func.isRequired,
  patientHasScreeningPeriodEnabled: PropTypes.bool,
  isAPureEngageStudy: PropTypes.bool.isRequired,
  upcomingVisit: PropTypes.object.isRequired,
  busyChangingDatePickerDate: PropTypes.bool.isRequired,
  confirmToggleVirtualVisit: PropTypes.func.isRequired,
  resetToggleVirtualVisitError: PropTypes.func.isRequired,
  busyTogglingVirtualVisit: PropTypes.bool,
  toggleVirtualVisitError: PropTypes.object,
  isStudyArchived: PropTypes.bool,
  canSiteHaveVirtualVisits: PropTypes.bool,
  showVirtualVisitLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  serverTime: PropTypes.string,
}

PatientVisitsTable.defaultProps = {
  patientHasScreeningPeriodEnabled: false,
  busyTogglingVirtualVisit: false,
  toggleVirtualVisitError: null,
  isStudyArchived: false,
  canSiteHaveVirtualVisits: false,
  showVirtualVisitLabel: false,
  tooltip: null,
  serverTime: null,
}


export default PatientVisitsTable

import { is, sort, prop } from 'ramda'

export default function sortById(arrayToSort) {
  return is(Array, arrayToSort) ? sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10), arrayToSort) : arrayToSort
}

export function sortByProp(propName, arrayToSort) {
  return is(Array, arrayToSort) ? sort((a, b) => {
    if (is(String, prop(propName, a))) {
      if (prop(propName, a) < prop(propName, b)) {
        return -1
      } if (a.type > b.type) {
        return 1
      }
      return 0
    }
    return prop(propName, a) - prop(propName, b)
  }, arrayToSort) : arrayToSort
}

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Route, Link, Routes, useMatch } from 'react-router-dom'
import { isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import { nanoid } from 'nanoid'
import withRouter from '../../components/wrapperReactRouterDom'

import { ICON_SETTINGS } from '../../config/constants'

import { isPureEngageStudy, isStudyArchived, getChangeLanguageButtonToolkit, getChangeDeviceButtonToolkit } from '../../utils/StudyHelper'

import PatientActions from '../../redux/PatientRedux'
import PdiActions from '../../redux/PdiRedux'

import visibleForRole from '../../hocs/visibleForRole'

import TabBar from '../../components/tabbar/TabBar'
import TabBarItem from '../../components/tabbar/TabBarItem'
import TabBarDropdown from '../../components/tabbar/TabBarDropdown'
import TabBarDropdownItem from '../../components/tabbar/TabBarDropdownItem'
import TabBarItemGroup from '../../components/tabbar/TabBarItemGroup'

import BaseModal from '../../components/modals/BaseModal'
import ActivatedDeviceModal from '../../components/modals/ActivatedDeviceModal'
import ChangeLanguageForm from '../../components/modals/ChangeLanguageForm'
import ChangePatientIdForm from '../../components/modals/ChangePatientIdForm'
import ChangeDeviceForm from '../../components/modals/ChangeDeviceForm'
import RequestResetCodeForm from '../../components/modals/RequestResetCodeForm'
import NewResetCodeModal from '../../components/modals/NewResetCodeModal'

import LoadingHandler from '../../components/loading/LoadingHandler'
import ErrorHandler from '../../components/error/ErrorHandler'
import HintLabel from '../../components/HintLabel'

import PatientTabVisits from './PatientTabVisits'
import PatientTabEventData from './PatientTabEventData'
import PatientTabQuestionnaires from './PatientTabQuestionnaires'
import PatientTabDailyTasks from './PatientTabDailyTasks'
import PatientTabStatus from './PatientTabStatus'

import SubContainer from '../layout/SubContainer'
import TabContainer from '../layout/TabContainer'

const patientRoutes = [
  {
    path: '/patient/:patient_id',
    exact: true,
    label: 'Home',
    id: "button-tab-home",
    to: (studyId, siteId) => `/study/${studyId}/site/${siteId}`,
    key: nanoid(),
  },
  {
    path: '/',
    exact: true,
    main: <PatientTabVisits />,
    label: 'Visit Overview',
    id: "button-tab-visits",
    to: (studyId, siteId, patientId) => `/study/${studyId}/site/${siteId}/patient/${patientId}`,
    key: nanoid(),
  },
  {
    path: '/eventdata',
    exact: true,
    main: <PatientTabEventData />,
    label: 'Event Data',
    id: "button-tab-eventdata",
    to: (studyId, siteId, patientId) => `/study/${studyId}/site/${siteId}/patient/${patientId}/eventdata`,
    key: nanoid(),
  },
  {
    path: '/pro',
    exact: true,
    main: <PatientTabQuestionnaires />,
    label: 'PRO/Questionnaires',
    id: "button-tab-questionnaires",
    to: (studyId, siteId, patientId) => `/study/${studyId}/site/${siteId}/patient/${patientId}/pro`,
    key: nanoid(),
  },
  {
    path: '/dailytasks',
    exact: true,
    main: <PatientTabDailyTasks />,
    label: "Daily Tasks",
    id: "button-tab-dailytasks",
    to: (studyId, siteId, patientId) => `/study/${studyId}/site/${siteId}/patient/${patientId}/dailytasks`,
    key: nanoid(),
  },
]

function TabBarItemChild({ patientRoute, studyId, siteId, patientId }) {
  const match = useMatch(patientRoute.to(studyId, siteId, patientId))

  return <TabBarItem itemClass={ match !== null ? 'tabbar__item--active' : '' }>
            <Link
              className="tabbar__item-link"
              to={ patientRoute.to(studyId, siteId, patientId) }
              id={ patientRoute.id }>
              { patientRoute.label }
            </Link>
         </TabBarItem>
}

export class PatientContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showActivatedDeviceModal: false,
      showChangeDeviceModal: false,
      showChangeLanguageModal: false,
      showModalChangePatientId: false,
      showRequestResetCodeForm: false,
      showNewResetCodeModal: false,
      patientRoutesState: [...patientRoutes],
    }
  }

  componentDidMount() {
    const { router, fetchAllPdis, fetchAllPdisError } = this.props
    // eslint-disable-next-line camelcase
    const { study_id, patient_id } = router.params
   
    if (!fetchAllPdisError) {
      fetchAllPdis(study_id, patient_id)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // CHANGE PRIMARY LANGUAGE
    const { newPrimaryLanguage, resetChangeLanguageError, router } = this.props
    const languageHasBeenChanged = newPrimaryLanguage && !nextProps.newPrimaryLanguage && !nextProps.changeLanguageError
    // eslint-disable-next-line camelcase
    const { site_id, study_id } = router.params
    
    if (languageHasBeenChanged) {
      this._closeChangeLanguageModal(resetChangeLanguageError)()
    }

    // CHANGE DEVICE
    const { patientWithChangedDevice, resetChangeDeviceError } = this.props
    const deviceHasBeenChanged = patientWithChangedDevice && !nextProps.patientWithChangedDevice && !nextProps.changeDeviceError
    
    if (deviceHasBeenChanged) {
      this._closeChangeDeviceModal(resetChangeDeviceError)()
      this._openActivatedPatientModal()
    }

    // CHANGE PATIENT ID
    const { newPatientId, resetChangePatientIdError } = this.props
    const patientIdHasBeenChanged = newPatientId && !nextProps.newPatientId && !nextProps.changePatientIdError
   
    if (patientIdHasBeenChanged) {
      this._closeChangePatientIdModal(resetChangePatientIdError)()

      // eslint-disable-next-line camelcase
      router.navigate(`/study/${study_id}/site/${site_id}/patient/${newPatientId}`)
    }

    // REQUEST RESET CODE
    const { newResetCode, resetRequestResetCodeError } = this.props
    const resetCodeHasBeenRequested = !newResetCode && nextProps.newResetCode && !nextProps.requestResetCodeError
   
    if (resetCodeHasBeenRequested) {
      this._closeRequestResetCodeForm(resetRequestResetCodeError)()
      this._openNewResetCodeModal()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pdiList, busyFetchingAllPdis, fetchAllPdisError } = this.props
    const { patientRoutesState: prevPatientRoutes } = prevState

    if ((!isNilOrEmpty(pdiList) || !isNilOrEmpty(fetchAllPdisError)) && !busyFetchingAllPdis && isNil(prevPatientRoutes.find(element => element.id === "button-tab-status"))) {
      const routeToAdd = {
        path: '/status',
        exact: true,
        main: <PatientTabStatus pdiList={ pdiList } error={ fetchAllPdisError } />,
        label: "Events/Questionnaires Status",
        id: "button-tab-status",
        to: (studyId, siteId, patientId) => `/study/${studyId}/site/${siteId}/patient/${patientId}/status`,
        key: nanoid(),
      }

      // ensure the route is only added one time
      this.setState({ patientRoutesState: [...prevPatientRoutes, routeToAdd] })
    }
  
    if (isNilOrEmpty(pdiList) && !busyFetchingAllPdis && !isNil(prevPatientRoutes.find(element => element.id === "button-tab-status")) && isNilOrEmpty(fetchAllPdisError)) {
      this.setState({ patientRoutesState: prevPatientRoutes.filter(element => element.id !== "button-tab-status") })
    }
  }

  render() {
    const {
      router, study, site, activatedPatient,
      patient, busyFetchingPatient, fetchPatientError,
      changeDevice, changeDeviceError, busyChangingDevice, resetChangeDeviceError,
      changeLanguage, changeLanguageError, busyChangingLanguage, resetChangeLanguageError,
      changePatientId, changePatientIdError, busyChangingPatientId, resetChangePatientIdError, availablePatientIds,
      fetchAvailablePatientIds, fetchAvailablePatientIdsError,
      busyFetchingVisits, busyFetchingEvents, busyFetchingQuestionnaires, busyFetchingAvailablePatientIds,
      requestResetCode, requestResetCodeError, busyRequestingResetCode, resetRequestResetCodeError, newResetCode,
      busyFetchingAllPdis,
    } = this.props

    const {
      showActivatedDeviceModal, showChangeDeviceModal, showChangeLanguageModal, showModalChangePatientId,
      showRequestResetCodeForm, showNewResetCodeModal, patientRoutesState,
    } = this.state

    const { study_id, site_id, patient_id } = router.params // eslint-disable-line camelcase

    const hasError = !isNilOrEmpty(fetchPatientError)
    const hasPatient = !isNilOrEmpty(patient)

    const possibleLanguages = site && site.availableLanguages

    const loadingArray = [
      {
        isLoading: busyFetchingVisits,
        message: "Loading visits",
      }, {
        isLoading: busyFetchingEvents,
        message: "Loading event data",
      }, {
        isLoading: busyFetchingQuestionnaires,
        message: "Loading PRO/Questionnaire data",
      },
      {
        isLoading: busyFetchingAllPdis,
        message: "Loading events/questionnaires",
      }, {
        isLoading: false, // set to true to test rendering loading messages
        message: "Test loading message",
      },
    ]

    const isPatientPinResetable = isPureEngageStudy(study)
    const isArchivedStudy = !isNilOrEmpty(study) && isStudyArchived(study.workflow)
    const changeLanguageButtonTooltip = !isNilOrEmpty(study) && getChangeLanguageButtonToolkit(study.resources)
    const changeDeviceButtonTooltip = !isNilOrEmpty(study) && getChangeDeviceButtonToolkit(study.resources)

    return (
      <SubContainer>
        <Helmet>
          <title>
            {
              // eslint-disable-next-line camelcase
              `Patient ${patient_id}`
            }
          </title>
        </Helmet>
        { !busyFetchingPatient && !busyFetchingAllPdis && !hasError && hasPatient && this._renderTabBar(study_id, site_id, site.country, patient_id, isPatientPinResetable, fetchAvailablePatientIds, isArchivedStudy, patientRoutesState, changeLanguageButtonTooltip, changeDeviceButtonTooltip) }
        <TabContainer>
          { !busyFetchingPatient && hasError && this._renderError(fetchPatientError) }
          { !busyFetchingPatient && hasPatient && patient.status === 2 && this._renderWithdrawnLabel() }
          { !busyFetchingPatient && this._renderLoadingMessages(loadingArray) }
          { !busyFetchingPatient && !busyFetchingAllPdis && !hasError && hasPatient && this._renderPatientScreens(patientRoutesState) }
          { hasPatient && showChangeDeviceModal && this._renderChangeDeviceModal(changeDevice, resetChangeDeviceError, changeDeviceError, busyChangingDevice, study_id, patient_id) }
          { hasPatient && showChangeLanguageModal && this._renderChangeLanguageModal(changeLanguage, resetChangeLanguageError, changeLanguageError, busyChangingLanguage, study_id, possibleLanguages, patient_id, patient.primaryLanguage) }
          { hasPatient && showActivatedDeviceModal && this._renderActivatedPatientModal(activatedPatient, patient_id) }
          { hasPatient && showModalChangePatientId && this._renderChangePatientIdModal(changePatientId, study_id, site_id, site.country,
            resetChangePatientIdError, changePatientIdError, availablePatientIds, busyChangingPatientId, patient_id, busyFetchingAvailablePatientIds,
            fetchAvailablePatientIdsError) }
          { hasPatient && showRequestResetCodeForm && this._renderRequestResetCodeForm(requestResetCode, resetRequestResetCodeError, requestResetCodeError, study_id, patient_id, busyRequestingResetCode) }
          { hasPatient && showNewResetCodeModal && this._renderNewResetCodeModal(newResetCode, patient_id) }
        </TabContainer>
      </SubContainer>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderLoadingMessages = loadingArray => <LoadingHandler loading={ loadingArray } />

  _renderWithdrawnLabel = () => (
    <HintLabel
      hintClass="u-margin--vertical u-text--centered u-text--underlined u-text--uppercased u-text--bold black"
      size={ 20 }>
      Withdrawn
    </HintLabel>
  )

  _renderPatientScreens = patientRoutesState => (
    <Routes>
      { patientRoutesState.map(route => (
        <Route
          key={ route.key }
          path={ route.path }
          element={ route.main } />
      )) }
    </Routes>
  )

  _renderTabBar(studyId, siteId, countryId, patientId, isPatientPinResetable, fetchAvailablePatientIds, isArchivedStudy, patientRoutesState, changeLanguageButtonTooltip, changeDeviceButtonTooltip) {
    const TabBarDropDownItemChangePatientId = visibleForRole('study_configurator',
      <TabBarDropdownItem
        title="Change Patient Id"
        handleClick={ this._openChangePatientIdModal(studyId, countryId, siteId, fetchAvailablePatientIds) }
        id="button-change-patient-id" />)
  
    return (
      <TabBar>
        <TabBarItemGroup>
          { patientRoutesState.map(patientRoute => (
            <TabBarItemChild key={ patientRoute.key } patientRoute={ patientRoute } studyId={ studyId } patientId={ patientId } siteId={ siteId } />
          )) }
        </TabBarItemGroup>
        <TabBarItemGroup>
          <TabBarDropdown
            title="Settings"
            icon={ ICON_SETTINGS }
            id="button-patient-settings">
              <TabBarDropdownItem
                title="Language"
                handleClick={ this._openChangeLanguageModal }
                disabled={ isArchivedStudy }
                tooltip={ changeLanguageButtonTooltip }
                id="button-change-language" />
              <TabBarDropdownItem
                title="Change Device"
                disabled={ isArchivedStudy }
                tooltip={ changeDeviceButtonTooltip }
                handleClick={ this._openChangeDeviceModal }
                id="button-change-device" />
              { !isArchivedStudy && <TabBarDropDownItemChangePatientId /> }
              { isPatientPinResetable && (
              <TabBarDropdownItem
                title="Request Reset Code"
                handleClick={ this._openRequestResetCodeForm }
                id="button-reset-patient-pin" />
              ) }
          </TabBarDropdown>
        </TabBarItemGroup>
      </TabBar>      
    )
  }

  // CHANGE PRIMARY LANGUAGE
  _renderChangeLanguageModal(changeLanguage, resetChangeLanguageError, changeLanguageError, busyChangingLanguage, studyId, possibleLanguages, patientId, primaryLanguage) {
    return (
      <BaseModal
        title={ `Change Language (PATIENT ${patientId})` }
        handleClose={ this._closeChangeLanguageModal(resetChangeLanguageError) }
        forceInteraction={ busyChangingLanguage }>
          <ChangeLanguageForm
            primaryLanguage={ primaryLanguage }
            possibleLanguages={ possibleLanguages }
            handleCanceled={ this._closeChangeLanguageModal(resetChangeLanguageError) }
            handleConfirmed={ this._changeLanguageConfirmed(changeLanguage, studyId, patientId) }
            error={ changeLanguageError }
            loading={ busyChangingLanguage } />
      </BaseModal>
    )
  }

  _openChangeLanguageModal = () => {
    this.setState({ showChangeLanguageModal: true })
  }

  _closeChangeLanguageModal = resetChangeLanguageError => () => {
    this.setState({ showChangeLanguageModal: false })
    resetChangeLanguageError()
  }

  _changeLanguageConfirmed = (changeLanguage, studyId, patientId) => (newPrimaryLanguage) => {
    changeLanguage(studyId, patientId, newPrimaryLanguage)
  }

  // CHANGE DEVICE
  _renderChangeDeviceModal(changeDevice, resetChangeDeviceError, changeDeviceError, busyChangingDevice, studyId, patientId) {
    return (
      <BaseModal
        title={ `Change Device (PATIENT ${patientId})` }
        handleClose={ this._closeChangeDeviceModal(resetChangeDeviceError) }
        forceInteraction={ busyChangingDevice }>
          <ChangeDeviceForm
            handleCanceled={ this._closeChangeDeviceModal(resetChangeDeviceError) }
            handleConfirmed={ this._changeDeviceConfirmed(changeDevice, studyId, patientId) }
            error={ changeDeviceError }
            loading={ busyChangingDevice } />
      </BaseModal>
    )
  }

  _openChangeDeviceModal = () => {
    this.setState(() => ({ showChangeDeviceModal: true }))
  }

  _closeChangeDeviceModal = resetChangeDeviceError => () => {
    this.setState(() => ({ showChangeDeviceModal: false }))
    resetChangeDeviceError()
  }

  _changeDeviceConfirmed = (changeDevice, studyId, patientId) => (patientWithChangedDevice) => {
    const mergeData = {
      ...{
      studyId,
      patientId,
    },
    ...patientWithChangedDevice,
   }

   changeDevice(mergeData)
  }

  _renderChangePatientIdModal(changePatientId, studyId, siteId, countryId, resetChangePatientIdError, changePatientIdError, availablePatientIds, busyChangingPatientId, patientId, busyFetchingAvailablePatientIds, fetchAvailablePatientIdsError) {
    const loaders = {
      busyChangingPatientId,
      busyFetchingAvailablePatientIds,
    }
    const errors = {
      changePatientIdError,
      fetchAvailablePatientIdsError,
    }
    return (
      <BaseModal
        title={ `Change Patient ID (PATIENT ${patientId})` }
        handleClose={ this._closeChangePatientIdModal(resetChangePatientIdError) }
        forceInteraction={ busyChangingPatientId }>
          <ChangePatientIdForm
            availablePatientIds={ availablePatientIds }
            handleCanceled={ this._closeChangePatientIdModal(resetChangePatientIdError) }
            handleConfirmed={ this._changePatientIdConfirmed(studyId, siteId, countryId, changePatientId, patientId) }
            loading={ loaders }
            errors={ errors }
        />
      </BaseModal>
    )
  }

  _openChangePatientIdModal = (studyId, countryId, siteId, fetchAvailablePatientIds) => () => {
    this.setState(() => ({ showModalChangePatientId: true }))
    fetchAvailablePatientIds(studyId, countryId, siteId)
  }

  _closeChangePatientIdModal = resetChangePatientIdError => () => {
    this.setState(() => ({ showModalChangePatientId: false }))
    resetChangePatientIdError()
  }

  _changePatientIdConfirmed = (studyId, siteId, countryId, changePatientId, activatedPatientId) => (data) => {
    const { userName, userPwd, newPatientId, reason } = data

    changePatientId(studyId, siteId, countryId, newPatientId, activatedPatientId, { userName, userPwd }, reason)
  }


  // ACTIVATED PATIENT MODAL (shown after changing device (returning the application id) or after simple re-activation)
  _renderActivatedPatientModal = (activatedPatient, patientId) => {
    const result = activatedPatient && activatedPatient._embedded && activatedPatient._embedded.result
    const { pin, applicationId } = result
    return (
      <BaseModal
        title={ `Device Activated (PATIENT ${patientId})` }
        handleClose={ this._closeActivatedPatientModal }
        forceInteraction={ true }>
          <ActivatedDeviceModal
            pin={ pin }
            applicationId={ applicationId }
            handleConfirmed={ this._closeActivatedPatientModal } />
      </BaseModal>
    )
  }

  _openActivatedPatientModal = () => {
    this.setState(() => ({ showActivatedDeviceModal: true }))
  }

  _closeActivatedPatientModal = () => {
    this.setState(() => ({ showActivatedDeviceModal: false }))
  }

  // REQUEST RESET CODE
  _renderRequestResetCodeForm(requestResetCode, resetRequestResetCodeError, requestResetCodeError, studyId, patientId, busyRequestingResetCode) {
    return (
      <BaseModal
        title={ `REQUEST RESET CODE (PATIENT ${patientId})` }
        handleClose={ this._closeRequestResetCodeForm(resetRequestResetCodeError) }
        forceInteraction={ busyRequestingResetCode }>
          <RequestResetCodeForm
            handleCanceled={ this._closeRequestResetCodeForm(resetRequestResetCodeError) }
            handleConfirmed={ this._requestResetCodeConfirmed(requestResetCode, studyId, patientId) }
            error={ requestResetCodeError }
            loading={ busyRequestingResetCode } />
      </BaseModal>
    )
  }

  _openRequestResetCodeForm = () => {
    this.setState({ showRequestResetCodeForm: true })
  }

  _closeRequestResetCodeForm = resetRequestResetCodeError => () => {
    this.setState({ showRequestResetCodeForm: false })
    resetRequestResetCodeError()
  }

  _requestResetCodeConfirmed = (requestResetCode, studyId, patientId) => (credentials) => {
    requestResetCode(studyId, patientId, credentials)
  }

  // NEW RESET CODE MODAL
  _renderNewResetCodeModal = (newResetCode, patientId) => (
    <BaseModal
      title={ `New Reset Code (PATIENT ${patientId})` }
      handleClose={ this._closeNewResetCodeModal }
      forceInteraction={ true }>
        <NewResetCodeModal
          resetCode={ newResetCode }
          handleConfirmed={ this._closeNewResetCodeModal } />
    </BaseModal>
  )

  _openNewResetCodeModal = () => {
    this.setState(() => ({ showNewResetCodeModal: true }))
  }

  _closeNewResetCodeModal = () => {
    this.setState(() => ({ showNewResetCodeModal: false }))
  }
}

TabBarItemChild.propTypes = {
  patientRoute: PropTypes.object.isRequired, 
  studyId: PropTypes.string.isRequired, 
  siteId: PropTypes.string.isRequired, 
  patientId: PropTypes.string.isRequired,
}

PatientContainer.propTypes = {
  router: PropTypes.object.isRequired,
  study: PropTypes.object,
  site: PropTypes.object,
  activatedPatient: PropTypes.object,
  // fetch visits
  busyFetchingVisits: PropTypes.bool.isRequired,
  busyFetchingEvents: PropTypes.bool.isRequired,
  busyFetchingQuestionnaires: PropTypes.bool.isRequired,
  // fetch patient
  patient: PropTypes.object,
  fetchPatientError: PropTypes.object,
  busyFetchingPatient: PropTypes.bool.isRequired,
  // change device
  patientWithChangedDevice: PropTypes.object,
  changeDevice: PropTypes.func.isRequired,
  changeDeviceError: PropTypes.object,
  resetChangeDeviceError: PropTypes.func.isRequired,
  busyChangingDevice: PropTypes.bool.isRequired,
  // change language
  newPrimaryLanguage: PropTypes.string,
  changeLanguage: PropTypes.func.isRequired,
  changeLanguageError: PropTypes.object,
  resetChangeLanguageError: PropTypes.func.isRequired,
  busyChangingLanguage: PropTypes.bool.isRequired,
  // change patient id
  newPatientId: PropTypes.string,
  changePatientIdError: PropTypes.object,
  resetChangePatientIdError: PropTypes.func.isRequired,
  busyChangingPatientId: PropTypes.bool.isRequired,
  changePatientId: PropTypes.func.isRequired,
  // available patient ids
  availablePatientIds: PropTypes.array,
  fetchAvailablePatientIds: PropTypes.func.isRequired,
  fetchAvailablePatientIdsError: PropTypes.object,
  busyFetchingAvailablePatientIds: PropTypes.bool.isRequired,
  // fetch all PDI's
  pdiList: PropTypes.array,
  fetchAllPdis: PropTypes.func.isRequired,
  fetchAllPdisError: PropTypes.object,
  busyFetchingAllPdis: PropTypes.bool.isRequired,
  // request reset code
  newResetCode: PropTypes.string,
  requestResetCode: PropTypes.func.isRequired,
  requestResetCodeError: PropTypes.object,
  resetRequestResetCodeError: PropTypes.func.isRequired,
  busyRequestingResetCode: PropTypes.bool.isRequired,
}

PatientContainer.defaultProps = {
  study: null,
  site: null,
  activatedPatient: null,
  // fetch patient
  patient: null,
  fetchPatientError: null,
  // change device
  patientWithChangedDevice: null,
  changeDeviceError: null,
  // change language
  newPrimaryLanguage: null,
  changeLanguageError: null,
  // change patient id
  newPatientId: null,
  changePatientIdError: null,
  // available patient ids
  availablePatientIds: [],
  fetchAvailablePatientIdsError: null,
  // all PDI's
  pdiList: [],
  fetchAllPdisError: null,
  // request reset code
  newResetCode: null,
  requestResetCodeError: null,
}

const mapStateToProps = state => ({
  study: state.set.study,
  site: state.set.site,
  activatedPatient: state.patients.activatedPatient,
  // loading objects
  busyFetchingVisits: state.visits.busyFetchingVisits,
  busyFetchingEvents: state.events.busyFetchingEvents,
  busyFetchingQuestionnaires: state.questionnaires.busyFetchingQuestionnaires,
  // fetch patient
  patient: state.patients.patient,
  fetchPatientError: state.patients.fetchPatientError,
  busyFetchingPatient: state.patients.busyFetchingPatient,
  // change device
  changeDeviceError: state.patients.changeDeviceError,
  patientWithChangedDevice: state.patients.patientWithChangedDevice,
  busyChangingDevice: state.patients.busyChangingDevice,
  // change language
  newPrimaryLanguage: state.patients.newPrimaryLanguage,
  changeLanguageError: state.patients.changeLanguageError,
  busyChangingLanguage: state.patients.busyChangingLanguage,
  // change patient id
  changePatientIdError: state.patients.changePatientIdError,
  busyChangingPatientId: state.patients.busyChangingPatientId,
  newPatientId: state.patients.newPatientId,
  // available patient ids
  availablePatientIds: state.patients.availablePatientIds,
  fetchAvailablePatientIdsError: state.patients.fetchAvailablePatientIdsError,
  busyFetchingAvailablePatientIds: state.patients.busyFetchingAvailablePatientIds,
  // fetch all PDI's
  pdiList: state.pdis.pdiList,
  busyFetchingAllPdis: state.pdis.busyFetchingAllPdis,
  fetchAllPdisError: state.pdis.fetchAllPdisError,
  // request reset code
  newResetCode: state.patients.newResetCode,
  requestResetCodeError: state.patients.requestResetCodeError,
  busyRequestingResetCode: state.patients.busyRequestingResetCode,
})

const mapDispatchToProps = dispatch => ({
  fetchAvailablePatientIds: (studyId, countryId, siteId) => dispatch(PatientActions.fetchAvailablePatientIds(studyId, countryId, siteId)),
  changeDevice: patientWithChangedDevice => dispatch(PatientActions.changeDevice(patientWithChangedDevice)),
  resetChangeDeviceError: () => dispatch(PatientActions.resetChangeDeviceError()),
  changeLanguage: (studyId, patientId, newPrimaryLanguage) => dispatch(PatientActions.changeLanguage(studyId, patientId, newPrimaryLanguage)),
  resetChangeLanguageError: () => dispatch(PatientActions.resetChangeLanguageError()),
  changePatientId: (studyId, siteId, countryId, newPatientId, patientId, credentials, reason) => dispatch(PatientActions.changePatientId(studyId, siteId, countryId, newPatientId, patientId, credentials, reason)),
  requestResetCode: (studyId, patientId, credentials) => dispatch(PatientActions.requestResetCode(studyId, patientId, credentials)),
  resetRequestResetCodeError: () => dispatch(PatientActions.resetRequestResetCodeError()),
  resetChangePatientIdError: () => dispatch(PatientActions.resetChangePatientIdError()),
  fetchAllPdis: (studyId, patientId) => dispatch(PdiActions.fetchAllPdis(studyId, patientId)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientContainer))

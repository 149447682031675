export const NOT_ACKNOWLEDGED = "Not Acknowledged"
export const DONE = "Done"
export const NOT_DONE = "Not Done"

export const TELEVISITS_COMPATIBILITY_ERROR_MESSAGE = "Televisits are not compatible with your browser. Try connecting via another browser (for example: Chrome, Firefox or Safari). For further information please contact the myUCB Helpdesk."

export const ICON_LOCATION_RIGHT = "icon right"
export const ICON_LOCATION_LEFT = "icon left"

export const TOGGLE_ON_COLOR = "#56C596"
export const TOGGLE_OFF_COLOR = "#E4483A"

export const ICON_DOWNLOAD = "download"
export const ICON_UPLOAD = "upload"
export const ICON_LOGOUT = "logout"
export const ICON_PLUS = "plus"
export const ICON_SETTINGS = "settings"
export const ICON_LANGUAGE = "language"
export const ICON_DELETE = "delete"
export const ICON_EDIT_MD = "edit_md"
export const ICON_EDIT_FA = "edit_fa"
export const ICON_HOME = "home"
export const ICON_LIST = "list"
export const ICON_CALENDAR = "calendar"
export const ICON_CALENDAR_ALT = "calendar-alt"
export const ICON_CIRCLE_CROSS = "circle-cross"
export const ICON_ARROW_LEFT = "arrow-left"
export const ICON_ARROW_DOWN = "arrow-down"
export const ICON_ARROW_RIGHT = "arrow-right"
export const ICON_FLASH = "flash"
export const ICON_STEP_FORWARD = "step-forward"
export const ICON_STEP_BACKWARD = "step-backward"
export const ICON_CHECK_CIRCLE = "check-circle"
export const ICON_CHECK_MARK = "check-mark"
export const ICON_DANGER = "danger"
export const ICON_INFO = "info"
export const ICON_START = "start"
export const ICON_STOP = "stop"
export const ICON_ADD_LIST = "add-list"
export const ICON_BAN = "ban"
export const ICON_ALLOW = "allow"
export const ICON_H_SQUARE = "h-square"
export const ICON_VIDEO = "video"

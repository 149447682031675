import React from 'react'
import Icon from '../Icon'
import { ICON_H_SQUARE } from '../../config/constants'

function OnSiteLabel() {
  return (
    <div className="toggle-on-site-visit-label">
      <Icon
        name={ ICON_H_SQUARE }
        size={ 20 }
      />
      <div>
        On Site
      </div>
    </div>
  )
}

export default OnSiteLabel

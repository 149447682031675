const queries = {
  availablePatientIds: 'availablePatientIds',
  allPdr: 'allPDR',
  changeId: 'changeId',
  assign: 'assign',
  cancel: 'cancel',
  context: 'context',
  countries: 'countries',
  dailytasks: 'daily-tasks',
  engage: 'engage',
  events: 'events',
  pdiStatuses: 'pdi-statuses',
  togglePdi: 'toggle-pdi',
  fasttrack: 'fasttrack',
  patients: 'patients',
  pin: 'pin',
  questionnaires: 'questionnaires',
  resetCode: 'resetcode',
  sites: 'sites',
  studies: 'studies',
  surfacing: 'surfacing',
  visits: 'visits',
  unplannedVisits: 'unplannedVisits',
  visitTemplates: 'visittemplates',
  availableForms: 'availableForms',
  toggleVirtualVisit: 'toggle-virtual-visit',
  Context() {
    return `${this.context}`
  },
  Clock() {
    return `Clock/time/now`
  },
  AvailablePatientIds(_studyId, _countryId, _siteId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}/${this.availablePatientIds}`
  },
  Patients(_studyId, _countryId, _siteId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}/${this.patients}`
  },
  AddPatient(_studyId, _countryId, _siteId, _patientId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}/${this.patients}/${_patientId}`
  },
  AddPatientToEngageStudy(_studyId, _countryId, _siteId, _patientId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}/${this.engage}/${this.patients}/${_patientId}`
  },
  AddUnscheduledVisitWithForms(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.unplannedVisits}`
  },
  AddUnscheduledVisitWithTemplate(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.visitTemplates}`
  },
  ReactivatePatient(_studyId, _patientId, _applicationId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.assign}/${_applicationId}`
  },
  Patient(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}`
  },
  DailyTasks(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.dailytasks}`
  },
  ChangePatientId(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.changeId}`
  },
  // engage endpoint
  RequestResetCode(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.pin}/${this.resetCode}`
  },
  Visits(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.visits}`
  },
  Visit(_studyId, _patientId, _visitId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.visits}/${_visitId}`
  },
  CancelVisit(_studyId, _patientId, _visitId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.visits}/${_visitId}/${this.cancel}`
  },
  ExportVisit(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.surfacing}`
  },
  ExportAllVisits(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.surfacing}`
  },
  ExportAllPdrs(_studyId, _siteId) {
    return `${this.studies}/${_studyId}/${this.sites}/${_siteId}/${this.allPdr}`
  },
  FastTrackVisit(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.fasttrack}`
  },
  CancelFastTrack(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.fasttrack}/${this.cancel}`
  },
  Events(_studyId, _patientId, _visitId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.surfacing}/${this.visits}/${_visitId}/${this.events}`
  },
  Questionnaires(_studyId, _patientId, _visitId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.surfacing}/${this.visits}/${_visitId}/${this.questionnaires}`
  },
  PdiStatuses(_studyId, _patientId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.pdiStatuses}`
  },
  TogglePdi(_studyId, _patientId, reference) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.togglePdi}/${reference}`
  },
  ToggleVirtualVisit(_studyId, _patientId, _visitId) {
    return `${this.studies}/${_studyId}/${this.patients}/${_patientId}/${this.visits}/${_visitId}/${this.toggleVirtualVisit}`
  },
  // engage endpoint
  VisitTemplates(_studyId) {
    return `${this.studies}/${_studyId}/${this.visitTemplates}`
  },
  // engage endpoint
  VisitForms(_studyId) {
    return `${this.studies}/${_studyId}/${this.availableForms}`
  },
}

export default queries

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // events
  fetchEvents: ['studyId', 'patientId', 'visitId'],
  fetchEventsSuccess: ['events', 'dummyVisit'],
  fetchEventsFailure: ['error'],
})

export const EventsTypes = Types

export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // events
  eventList: [],
  busyFetchingEvents: false,
  fetchEventsError: null,
})

/* ------------- Reducers ------------- */

// fetchEvents
export const fetchEvents = state => state.merge({
  busyFetchingEvents: true,
  eventList: [],
  fetchEventsError: null,
})
export const fetchEventsSuccess = (state, { events, dummyVisit }) => state.merge({
  busyFetchingEvents: false,
  dummyVisit,
  eventList: events,
})
export const fetchEventsFailure = (state, { error }) => state.merge({
  busyFetchingEvents: false,
  fetchEventsError: error,
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_EVENTS]: fetchEvents,
  [Types.FETCH_EVENTS_SUCCESS]: fetchEventsSuccess,
  [Types.FETCH_EVENTS_FAILURE]: fetchEventsFailure,
})

export function isInvestigator() {
  const { sessionHandler } = window
  const { userRoles } = sessionHandler
  return userRoles && userRoles.some(userRole => userRole === "investigator")
}

export function isSiteUser() {
  const { sessionHandler } = window
  const { userRoles } = sessionHandler
  return userRoles && userRoles.some(userRole => userRole === "site_user")
}

import { call, put } from 'redux-saga/effects'
import queries from '../config/ApiConfig'

import QuestionnairesActions from '../redux/QuestionnairesRedux'

import addTitleToErrorObject from '../utils/ErrorHelper'
import { extractQuestionnairesFromResponse } from '../utils/DataHelper'

// eslint-disable-next-line import/prefer-default-export
export function* fetchQuestionnaires(api, action) {
  const { studyId, patientId, visitId } = action
  const { ok, data } = yield call(api.get, queries.Questionnaires(studyId, patientId, visitId))
  const embeddedData = extractQuestionnairesFromResponse(data)
  const dummyVisit = data && data.dummyVisit
  if (ok) {
    yield put(QuestionnairesActions.fetchQuestionnairesSuccess(embeddedData, dummyVisit))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading questionnaires failed`)
    yield put(QuestionnairesActions.fetchQuestionnairesFailure(errorObject))
  }
}

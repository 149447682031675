import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { isNilOrEmpty } from 'ramdasauce'
import withRouter from '../../components/wrapperReactRouterDom'

import HintLabel from '../../components/HintLabel'

import TabContainer from '../layout/TabContainer'

class StudyContainer extends Component {
  render() {
    const { router, study } = this.props
    // eslint-disable-next-line camelcase
    const { study_id } = router.params

    const hasStudy = !isNilOrEmpty(study)
    const hasSites = hasStudy && !isNilOrEmpty(study.sites)
    return (
      <TabContainer>
        <Helmet>
          <title>
            {
              // eslint-disable-next-line camelcase
              `Study ${study_id}`
            }
          </title>
        </Helmet>
        { hasStudy && !hasSites && this._renderNoSitesScreen() }
        { hasSites && this._renderSelectSiteScreen() }
      </TabContainer>
    )
  }

  _renderNoSitesScreen = () => (
    <HintLabel>
      No sites available
    </HintLabel>
  )

  _renderSelectSiteScreen = () => (
    <HintLabel>
      Please select your site from the list of available sites.
    </HintLabel>
  )
}

StudyContainer.propTypes = { 
  router: PropTypes.object.isRequired,
  study: PropTypes.object, 
}

StudyContainer.defaultProps = { study: null }

const mapStateToProps = state => ({ study: state.set.study })

export default withRouter(connect(mapStateToProps, null)(StudyContainer))

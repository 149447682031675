import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { is, isEmpty } from 'ramda'
import cc from 'classcat'

import { frontendFormat } from '../../utils/MomentHelper'
import VirtualLabel from '../labels/VirtualLabel'
import OnSiteLabel from '../labels/OnSiteLabel'
import visibleForRole from '../../hocs/visibleForRole'
import Icon from '../Icon'
import { ICON_VIDEO } from '../../config/constants'

class VisitOverview extends Component {
  render() {
    const { label, patientsVisits, isCompletedTable, openVirtualVisit, isArchivedStudy, showVirtualVisitLabel } = this.props

    const hasDataToShow = is(Array, patientsVisits) && !isEmpty(patientsVisits)

    return (
      <div className="visit-overview">
        <h2>
          { label }
        </h2>
        { hasDataToShow && this._renderVisitsTable(isCompletedTable, patientsVisits, openVirtualVisit, isArchivedStudy, showVirtualVisitLabel) }
        { !hasDataToShow && this._renderNoResults() }
      </div>
    )
  }

  _renderVisitsTable = (isCompletedTable, patientsVisits, openVirtualVisit, isArchivedStudy, showVirtualVisitLabel) => (
    <div className="o-table site-patients-overview" id="site-patients-overview">
      <table>
        <thead>
          <tr>
            <th>
              Patient
            </th>
            <th>
              { !isCompletedTable ? "Upcoming visit" : "" }
            </th>
            <th />
            <th>
              { !isCompletedTable ? "Next visit" : "" }
            </th>
            <th />
            <th>
              Last Sync
            </th>
            <th>
              { !isCompletedTable ? "Up. Visit Type" : "" }
            </th>
          </tr>
        </thead>
        <tbody>
          { patientsVisits.map((patient) => {
            const patientHasUpcomingVisit = is(String, patient.upcomingVisit) && !isEmpty(patient.upcomingVisit) && is(String, patient.upcomingVisitDate) && !isEmpty(patient.upcomingVisitDate)
            const patientUpcomingVisitMoment = patientHasUpcomingVisit && moment.parseZone(patient.upcomingVisitDate)

            const patientHasNextUpcomingVisit = is(String, patient.upcomingVisitNext) && !isEmpty(patient.upcomingVisitNext) && is(String, patient.upcomingVisitDateNext) && !isEmpty(patient.upcomingVisitDateNext)
            const patientNextUpcomingVisitMoment = patientHasNextUpcomingVisit && moment.parseZone(patient.upcomingVisitDateNext)

            const patientHasLastSync = is(String, patient.lastSync) && !isEmpty(patient.lastSync)
            const patientLastSyncMoment = patientHasLastSync && moment.parseZone(patient.lastSync)

            const patientHasVirtualVisit = !!patient.upcomingVisitIsVirtual

            return (
              <tr key={ patient.id }>
                <td className="patient-id-column">
                  { patient.id }
                </td>
                <td>
                  { patientHasUpcomingVisit && `${patient.upcomingVisit}` }
                </td>
                <td>
                  { patientHasUpcomingVisit && patientUpcomingVisitMoment.isValid() && patientUpcomingVisitMoment.format(frontendFormat) }
                </td>
                <td>
                  { patientHasNextUpcomingVisit && `${patient.upcomingVisitNext}` }
                </td>
                <td>
                  { patientHasNextUpcomingVisit && patientNextUpcomingVisitMoment.isValid() && patientNextUpcomingVisitMoment.format(frontendFormat) }
                </td>
                <td>
                  { patientHasLastSync ? patientLastSyncMoment.format(frontendFormat) : '' }
                </td>
                { this._renderVirtualVisitLabel(patientHasVirtualVisit, isCompletedTable, openVirtualVisit, isArchivedStudy, patient.upcomingVisitVirtualId, showVirtualVisitLabel) }
              </tr>
            )
          }) }
        </tbody>
      </table>
    </div>
  )

  _renderVirtualVisitLabel = (patientHasVirtualVisit, isCompletedTable, openVirtualVisit, isArchivedStudy, virtualVisitId, showVirtualVisitLabel) => (
    <td>
      { !isCompletedTable ? (patientHasVirtualVisit ? this._renderVirtualLabelOrButton(openVirtualVisit, isArchivedStudy, virtualVisitId, showVirtualVisitLabel) : <OnSiteLabel />) : "" }
    </td>
  )

  _renderVirtualLabelOrButton = (openVirtualVisit, isArchivedStudy, virtualVisitId, showVirtualVisitLabel) => {
    const VirtualVisitButton = visibleForRole(['investigator', 'site_user'], this._renderVirtualVisitButton(openVirtualVisit, isArchivedStudy, virtualVisitId), <VirtualLabel />)
    return (
      !showVirtualVisitLabel ? <VirtualVisitButton /> : <VirtualLabel />
    )
  }

  _renderVirtualVisitButton = (openVirtualVisit, isArchivedStudy, virtualVisitId) => (
    <div className={ cc([{ "virtual-visit-button--archived": isArchivedStudy }]) }>
      <div
        className={ cc(["virtual-visit-button", { disabled: isArchivedStudy }]) }
        onClick={ () => openVirtualVisit(virtualVisitId) }
        role="button"
        tabIndex={ 0 }
        onKeyDown={ null }
        id="button-virtual-visit">
        <Icon
          name={ ICON_VIDEO }
          size={ 20 }
        />
        <div
          className={ cc(["virtual-visit-button-text", { disabled: isArchivedStudy }]) }
        >
          Join Virtual Visit
        </div>
      </div>
    </div>
  )

  _renderNoResults = () => (
    <p>
      No results
    </p>
  )
}

VisitOverview.propTypes = {
  label: PropTypes.string.isRequired,
  patientsVisits: PropTypes.array,
  openVirtualVisit: PropTypes.func,
  isArchivedStudy: PropTypes.bool,
  isCompletedTable: PropTypes.bool,
  showVirtualVisitLabel: PropTypes.bool,
}

VisitOverview.defaultProps = {
  patientsVisits: [],
  isCompletedTable: false,
  showVirtualVisitLabel: false,
  isArchivedStudy: false,
  openVirtualVisit: () => {},
}

export default VisitOverview

import { has, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import { isToday, isSameOrBeforeToday, isBeforeToday, isAfterToday } from './MomentHelper'

export function isVisitUnlocked(visit) {
  return equals(visit.status, 0)
}

export function isVisitCanceled(visit) {
  return equals(visit.status, 2)
}

export function isVisitUnscheduled(visit) {
  return !has('visit', visit) || has('isUnscheduled', visit)
}

export function isVisitSkipped(visit) {
  return equals(visit.status, 1)
}

export function isVirtualVisit(visit) {
  return !!visit.isVirtualVisit
}

export function fetchVisitData(studyId, patientId, visitId, callback) {
  callback(studyId, patientId, visitId)
}

export function isScreeningVisit(visit) {
  return visit && visit.visit && !isNilOrEmpty(visit.visit.dayNumber) && equals(visit.visit.dayNumber, -1)
}

export function hasSkippedVisits(visits) {
  return visits.some(visit => !isNilOrEmpty(visit) && equals(visit.status, 1))
}

export function isFirstVisit(visits, visit) {
  const [firstVisit] = visits
  return !isNilOrEmpty(visits) && equals(firstVisit, visit)
}

export function firstVisitCompleted(visits, serverTime) {
  let counter = 0
  let firstVisit = visits[counter]
  if ((isScreeningVisit(firstVisit) || isVisitCanceled(firstVisit) || isVisitSkipped(firstVisit)) && visits.length > 1) {
    firstVisit = visits[++counter]
  }
  return isSameOrBeforeToday(firstVisit.plannedOn, serverTime)
}

export function hasReachedFastTrackVisit(visits, upcomingVisit, visitFastTrackId, serverTime) {
  if (!isNilOrEmpty(visitFastTrackId)) {
    const visitFastTrack = visits.find(visit => !isNilOrEmpty(visit) && equals(visit.id, visitFastTrackId))
    return (!isNilOrEmpty(visitFastTrack) && isSameOrBeforeToday(visitFastTrack.plannedOn, serverTime)) || equals(upcomingVisit, visitFastTrackId)
  }
  return true
}

export function isCancelFastTrackEnabled(visits, visitFastTrackId, serverTime) {
  const firstSkippedVisit = visits.find(visit => isVisitSkipped(visit))
  const fastTrackVisit = visits.find(visit => !isNilOrEmpty(visit) && equals(visit.id, visitFastTrackId))
  return firstSkippedVisit && isAfterToday(firstSkippedVisit.plannedOn, serverTime) && (!isNilOrEmpty(fastTrackVisit) && isAfterToday(fastTrackVisit.plannedOn, serverTime))
}

export function hasVisitToday(visits, serverTime) {
  return visits.some(visit => !isNilOrEmpty(visit) && isToday(visit.plannedOn, serverTime) && !isVisitCanceled(visit) && !isVisitSkipped(visit))
}

export function isVisitCancelable(visitIsCanceled, visitIsSkipped, visitIsUnscheduled, isAPureEngageStudy, visitHasDataAttached, visitDateTime, serverTime) {
  return !visitIsCanceled && !visitIsSkipped && visitIsUnscheduled && (isAPureEngageStudy ? (!visitHasDataAttached && !isBeforeToday(visitDateTime, serverTime)) : true)
}

export function isVisitEditable(visitIsCanceled, visitIsSkipped, visitIsUnscheduled, isAPureEngageStudy, visitHasDataAttached, visitDateTime, serverTime) {
  return !visitIsCanceled && !visitIsSkipped && ((visitIsUnscheduled && isAPureEngageStudy) ? !visitHasDataAttached : true) && !isBeforeToday(visitDateTime, serverTime)
}

export function isVisitExportable(visitId, firstVisitId, visitIsSkipped, visitIsUnscheduled, visitDateTime, upcomingVisit, serverTime) {
  return !visitIsSkipped && !visitIsUnscheduled && !equals(firstVisitId, visitId) && (isBeforeToday(visitDateTime, serverTime) || equals(upcomingVisit && upcomingVisit.id, visitId))
}


/**
 * Method to open files.
 *
 * @export
 * @param {*} fileUri Location of the file to be opened.
 * @param {string} [target='_blank'] Location of the file to be opened to, default in a new blank tab of the browser.
 */
export default function openFile(fileUri, target = '_blank') {
  if (fileUri) {
    window.open(fileUri, target)
  }
}

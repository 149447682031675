import { Component } from 'react'
import { isNilOrEmpty } from 'ramdasauce'

export default function visibleForRole(roles, WrappedComponent, FallBackComponent) {
  return class UserRole extends Component {
    render() {
      // eslint-disable-next-line prefer-destructuring
      const userRoles = window.sessionHandler.userRoles
      if (this._hasRole(userRoles, roles)) {
        return WrappedComponent
      }
      return isNilOrEmpty(FallBackComponent) ? null : FallBackComponent
    }

    // check if acceptedRoles is an Array and loop over all items if it is, otherwise just check value
    _hasRole = (userRoles, acceptedRoles) => (Array.isArray(acceptedRoles)
      ? userRoles.some(userRole => acceptedRoles.some(acceptedRole => userRole === acceptedRole)) : userRoles.some(userRole => acceptedRoles === userRole))
  }
}

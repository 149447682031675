import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'
import MainInput from '../inputs/MainInput'

class ChangeDeviceForm extends Component {
  constructor(props) {
    super(props)
    this.state = { inputApplicationId: '' }
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { inputApplicationId } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <MainInput
          value={ inputApplicationId }
          inputClass="u-text--uppercased"
          label="Application ID"
          onInputChanged={ this._applicationIdChanged }
          maxLength={ 6 }
          minLength={ 6 }
          inputType="id" />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, inputApplicationId) }
          confirmLabel="Generate access key"
          cancelDisabled={ loading }
          confirmDisabled={ inputApplicationId.length !== 6 || loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _applicationIdChanged = (inputApplicationId) => {
    this.setState({ inputApplicationId })
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, inputApplicationId) => () => {
    try {
      callback({ applicationId: inputApplicationId })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

ChangeDeviceForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

ChangeDeviceForm.defaultProps = { error: null }

export default ChangeDeviceForm

import React, { Component } from 'react'
import Rodal from 'rodal'
import PropTypes from 'prop-types'

class BaseModal extends Component {
  render() {
    const { title, children, handleClose, forceInteraction } = this.props

    return (
      <Rodal
        visible={ true }
        onClose={ this._hideModal(handleClose) }
        closeMaskOnClick={ !forceInteraction }
        showCloseButton={ !forceInteraction }
        className="modal-container">
        <div className="modal-title">
          { title }
        </div>
        <div className="modal-content">
          { children }
        </div>
      </Rodal>
    )
  }

  _hideModal = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

BaseModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  forceInteraction: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
}
BaseModal.defaultProps = {
  title: '',
  forceInteraction: false,
}

export default BaseModal
